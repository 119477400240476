import { useParams } from "react-router-dom";
import { Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Select from "../../../components/form/Select.jsx";
import DebouncedSelect from "../../../components/form/DebouncedSelect.jsx";
import {
  useGetSubjectsQuery,
  useGetTopicsQuery,
  useLazyGetUsersQuery,
} from "../../../redux/service.js";

const { Title, Text } = Typography;

const SearchFields = ({ form, includeTopics }) => {
  const { t } = useTranslation();
  const { topic } = useParams();

  const { data: subjectAreas, isSuccess: subjectAreasIsSuccess } =
    useGetSubjectsQuery({ pagination: "off" });
  const [getUsers] = useLazyGetUsersQuery();
  const { data: topicsData, isSuccess: topicsIsSuccess } = useGetTopicsQuery({
    pagination: "off",
  });

  const resetFilters = () =>
    form.reset({
      topicName: topic,
      title: null,
      time: null,
      subjects: null,
      topics: null,
      authors: null,
      ordering: "-published",
    });

  return (
    <div>
      <Row>
        <Col span={18}>
          <Title level={4}>{t("filterBy")}:</Title>
        </Col>
        <Col span={6}>
          <Text
            onClick={resetFilters}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            {t("reset")}
          </Text>
        </Col>
      </Row>
      {includeTopics && topicsIsSuccess ? (
        <div className="mt-2 mb-3">
          <Title level={5} className="mb-2">
            {t("topic")}
          </Title>
          <Select
            name="topics"
            options={topicsData.map((i) => ({
              value: i.id.toString(),
              label: i.title,
            }))}
            placeholder={t("select")}
            control={form.control}
          />
        </div>
      ) : null}
      {subjectAreasIsSuccess && (
        <div className="mt-2 mb-3">
          <Title level={5} className="mb-2">
            {t("subjectArea")}
          </Title>
          <Select
            name="subjects"
            placeholder={t("select")}
            control={form.control}
            options={subjectAreas.map((i) => ({
              value: i.id.toString(),
              label: i.name,
            }))}
          />
        </div>
      )}
      <div className="mt-2 mb-3">
        <Title level={5} className="mb-2">
          {t("time")}
        </Title>
        <Select
          name="time"
          options={[
            { value: "day", label: t("today") },
            { value: "week", label: t("week") },
            { value: "month", label: t("month") },
            { value: "year", label: t("year") },
            { value: "all", label: t("allTime") },
          ]}
          placeholder={t("select")}
          control={form.control}
        />
      </div>
      <div className="mt-2 mb-3">
        <Title level={5} className="mb-2">
          {t("author")}
        </Title>
        <DebouncedSelect
          control={form.control}
          trigger={getUsers}
          filterName="fullName"
          labelName="fullName"
          name="authors"
          placeholder={t("startTypingToSearch")}
          mode="multiple"
        />
      </div>
    </div>
  );
};

SearchFields.propTypes = {
  form: PropTypes.object.isRequired,
  includeTopics: PropTypes.bool,
};

SearchFields.defaultProps = {
  includeTopics: false,
};

export default SearchFields;
