import { useEffect, useState } from "react";

import { theme, Timeline, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { CheckCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  useGetCurrentUserQuery,
  useLazyGetSurveyInvitesQuery,
  useLazyPaginateFilterSurveysQuery,
} from "../../../redux/service.js";
import { Roles } from "../../../utilities/constants.jsx";

const { Title, Text } = Typography;

const TimelineLabel = ({ survey, isStart }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const navigate = useNavigate();

  return (
    <div>
      <Text
        style={{
          display: "block",
          color: "rgba(139, 141, 152, 1)",
          textTransform: "uppercase",
          fontWeight: 500,
        }}
      >
        {dayjs(isStart ? survey.startDate : survey.hardDeadline).format(
          "DD MMM YYYY",
        )}
      </Text>
      <a onClick={() => navigate(`/reports/${survey.id}`)}>
        <Title
          level={4}
          style={{
            fontSize: "18px",
            color: survey.isSubmitted
              ? "rgba(139, 141, 152, 1)"
              : token.colorTextThird,
          }}
        >
          {survey.title}
        </Title>
      </a>
      <Text style={{ color: "rgba(139, 141, 152, 1)" }}>
        {t(isStart ? (survey.isAdmin ? "starts" : "assigned") : "ends")}
      </Text>
    </div>
  );
};

TimelineLabel.propTypes = {
  survey: PropTypes.object.isRequired,
  isStart: PropTypes.bool,
};

TimelineLabel.defaultProps = {
  isStart: false,
};

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const SurveyTimeline = () => {
  const [getSurveyInvites] = useLazyGetSurveyInvitesQuery();
  const [getSurveys] = useLazyPaginateFilterSurveysQuery();
  const { data: currentUser, isSuccess: isCurrentUserSuccess } =
    useGetCurrentUserQuery();

  const [surveys, setSurveys] = useState(null);

  useEffect(() => {
    if (isCurrentUserSuccess) {
      if (currentUser.role === Roles.ADMIN.value) {
        getSurveys({ pagination: "off" })
          .unwrap()
          .then((data) => {
            const items = data.map((i) => ({ ...i, color: getRandomColor() }));
            const sortedByStartDate = items
              .slice()
              .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
              .map((i) => ({ ...i, isStart: true }));
            const sortedByEndDate = items
              .slice()
              .sort(
                (a, b) => new Date(a.hardDeadline) - new Date(b.hardDeadline),
              );
            const result = sortedByStartDate
              .concat(sortedByEndDate)
              .map((i) => ({
                isAdmin: true,
                id: i.id,
                title: i.title,
                color: i.color,
                isSubmitted: false,
                startDate: i.startDate,
                hardDeadline: i.hardDeadline,
                isStart: i?.isStart,
              }));
            setSurveys(result);
          });
      } else {
        getSurveyInvites({ pagination: "off" })
          .unwrap()
          .then((data) => {
            const items = data.map((i) => ({ ...i, color: getRandomColor() }));
            const sortedByStartDate = items
              .slice()
              .sort(
                (a, b) =>
                  new Date(a.survey.startDate) - new Date(b.survey.startDate),
              )
              .map((i) => ({ ...i, isStart: true }));
            const sortedByEndDate = items
              .slice()
              .sort(
                (a, b) =>
                  new Date(a.survey.hardDeadline) -
                  new Date(b.survey.hardDeadline),
              );
            const result = sortedByStartDate
              .concat(sortedByEndDate)
              .map((i) => ({
                isAdmin: false,
                id: i.survey.id,
                title: i.survey.title,
                color: i.color,
                isSubmitted: i.isSubmitted,
                startDate: i.survey.startDate,
                hardDeadline: i.survey.hardDeadline,
                isStart: i?.isStart,
              }));
            setSurveys(result);
          });
      }
    }
  }, [isCurrentUserSuccess]);

  return (
    surveys && (
      <Timeline
        mode="alternate"
        className="survey-timeline"
        items={surveys.map((survey) => ({
          children: (
            <TimelineLabel
              key={survey.id}
              survey={survey}
              isStart={survey.isStart}
            />
          ),
          dot: survey.isSubmitted ? (
            <CheckCircleFilled style={{ color: "rgba(139, 141, 152, 1)" }} />
          ) : (
            <svg
              style={{ backgroundColor: "rgba(243, 243, 247, 1)" }}
              width="11"
              height="11"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="4.5" cy="4.5" r="4" style={{ fill: survey.color }} />
            </svg>
          ),
          position: survey.isStart ? "right" : "left",
        }))}
      />
    )
  );
};

export default SurveyTimeline;
