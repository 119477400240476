import { useEffect } from "react";

import { App, Col, Row, Spin, theme } from "antd";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Input from "../../../components/form/Input.jsx";
import Checkbox from "../../../components/form/Checkbox.jsx";
import Select from "../../../components/form/Select.jsx";
import { OrganizationRoles, Roles } from "../../../utilities/constants.jsx";
import FormField from "../../../components/form/FormField.jsx";
import PrimaryButton from "../../../components/PrimaryButton.jsx";
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from "../../../redux/service.js";
import Upload from "../../../components/form/Upload.jsx";
import {handleErrors} from "../../../utilities/index.js";

const supportedFileFormats = [
  ".jpeg",
  ".png",
  ".gif",
  ".bmp",
  ".webp",
  ".svg+xml",
];

const defaultValues = {
  firstName: "",
  lastName: "",
  emailNotificationsEnabled: null,
  email: "",
  position: "",
  organization: {
    name: "",
    role: "",
  },
};

const UserForm = ({ userId }) => {
  const { notification } = App.useApp();
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const { data: user, isSuccess, userIsLoading } = useGetUserByIdQuery(userId);
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const profileSchema = yup.object().shape({
    firstName: yup
      .string()
      .typeError(t("form.validation.firstNameNotValid"))
      .required(t("form.validation.firstNameRequired")),
    lastName: yup
      .string()
      .typeError(t("form.validation.lastNameNotValid"))
      .required(t("form.validation.lastNameRequired")),
    profilePicture: yup
      .mixed()
      .transform((value) => (value instanceof File ? value : null))
      .test("fileType", t("form.validation.fileFormatInvalid"), (value) => {
        if (!value) {
          return true;
        }
        const extension = value.name.split(".").pop();
        return supportedFileFormats.includes(`.${extension}`);
      })
      .nullable(),
    isEmailHidden: yup
        .bool(),
  });

  const form = useForm({
    resolver: yupResolver(profileSchema),
    mode: "onChange",
    defaultValues,
  });

  const fields = [
    {
      key: "1",
      label: t("form.labels.firstName"),
      required: true,
      field: <Input name="firstName" control={form.control} />,
    },
    {
      key: "2",
      label: t("form.labels.lastName"),
      required: true,
      field: <Input name="lastName" control={form.control} />,
    },
    {
      key: "3",
      label: t("form.labels.position"),
      required: true,
      field: (
        <Input name="position" placeholder="Dean" control={form.control} />
      ),
    },
    {
      key: "4",
      field: (
        <Checkbox
          name="emailNotificationsEnabled"
          label={t("form.labels.emailNotifications")}
          control={form.control}
        />
      ),
    },
    {
      key: "5",
      field: (
        <Checkbox
          name="isEmailHidden"
          label={t("form.labels.isEmailHidden")}
          control={form.control}
        />
      ),
    },
    {
      key: "6",
      label: t("form.labels.email"),
      field: <Input name="email" disabled={true} control={form.control} />,
    },
    ...(user?.role !== Roles.ADMIN.value
      ? [
          {
            key: "7",
            label: t("form.labels.organizationName"),
            field: (
              <Input name="organization.name" disabled control={form.control} />
            ),
          },
          {
            key: "8",
            label: t("form.labels.role"),
            field: (
              <Select
                options={OrganizationRoles.asList()}
                name="organizationRole"
                disabled
                control={form.control}
              />
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (isSuccess) {
      setInitialValues();
    }
  }, [isSuccess]);

  const setInitialValues = () => {
    form.reset(user);
  };

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.set("firstName", values.firstName);
    formData.set("lastName", values.lastName);
    formData.set("emailNotificationsEnabled", values.emailNotificationsEnabled);
    formData.set("position", values.position);
    formData.set("isEmailHidden", values.isEmailHidden)
    if (values.profilePicture instanceof File) {
      formData.set("profilePicture", values.profilePicture);
    }
    updateUser({ id: user.id, data: formData })
      .unwrap()
      .then(() =>
        notification.success({ message: t("messages.profileUpdated") }),
      ).catch((errors) =>
          handleErrors(errors, form.setError, notification),
        );
  };

  if (userIsLoading || isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  return (
    isSuccess && (
      <div>
        <div className="mt-4 mb-3">
          <Upload
            name="profilePicture"
            buttonTitle="Upload profile picture"
            control={form.control}
            imageUrl={user.profilePicture}
            supportedFormats={supportedFileFormats}
            listType="picture-circle"
          />
        </div>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Row>
            <Col span={6} className="me-5">
              {fields
                .slice(0, user.role === Roles.ADMIN.value ? 4 : 5)
                .map((i) => (
                  <FormField key={i.key} label={i.label} field={i.field} />
                ))}
            </Col>
            <Col span={6}>
              {fields
                .slice(user.role === Roles.ADMIN.value ? 4 : 5)
                .map((i) => (
                  <FormField key={i.key} label={i.label} field={i.field} />
                ))}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="me-3">
              <PrimaryButton
                htmlType="submit"
                onClick={() => {}}
                disabled={isLoading || !form.formState.isDirty}
              >
                {t("buttons.save")}
              </PrimaryButton>
            </Col>
            <PrimaryButton
              style={{ background: token.colorBackgroundSecondary }}
              onClick={setInitialValues}
            >
              {t("buttons.cancel")}
            </PrimaryButton>
          </Row>
        </form>
      </div>
    )
  );
};

UserForm.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserForm;
