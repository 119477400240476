export const canUserPerformActionOnSurvey = (
  surveyPermissions,
  role,
  action = null,
) => {
  if (surveyPermissions.length === 0) {
    return true;
  }
  const permission = surveyPermissions.find(
    (i) => i.role === role && (!action || i.permission === action),
  );
  return !!permission;
};
