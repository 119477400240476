import { useEffect, useState } from "react";

import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { App, Layout, Menu, theme, Typography } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { MenuItems, Roles } from "../../utilities/constants.jsx";
import {
  useGetCurrentUserQuery,
  useLazyGetUserByIdQuery,
} from "../../redux/service.js";
import { PrivateRoutes } from "../../router/Routes.js";
import Header from "../header/Header.jsx";
import { useWindowSize } from "../../utilities/hooks.js";
import MobileRedirect from "../../views/MobileRedirect.jsx";

const { Title } = Typography;
const { Footer, Content, Sider } = Layout;
const AppResponsivenessDisabledWidth = 800;

const AuthenticatedLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const { notification } = App.useApp();

  const { data: currentUser, error } = useGetCurrentUserQuery();
  const [getUserById, userResponse] = useLazyGetUserByIdQuery();
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);
  const showGreyBackground = useSelector(
    (state) => state.utils.showGreyBackground,
  );
  const [isUserAllowedToView, setIsUserAllowedToView] = useState(false);
  const { width } = useWindowSize();

  const handleMenuItemClick = (itemKey) => {
    setSelectedKeys([itemKey]);
    navigate(itemKey);
  };

  useEffect(() => {
    if (error) {
      if (
        error.data?.errors &&
        "permission_denied" === error.data?.errors[0].code
      ) {
        notification.error({
          message: t("errors.permission_denied"),
        });
      }
    }
  }, [error]);

  useEffect(() => {
    if (currentUser) {
      getUserById(currentUser.id);
      checkIsCurrentUserAllowedToView();
    }
  }, [currentUser]);

  const checkIsCurrentUserAllowedToView = () => {
    if (!currentUser) false;

    const currentRoute = PrivateRoutes.find((i) => {
      const pattern = i.path.replace(/:\w+/g, "[^/]+");
      const regex = new RegExp(`^${pattern}$`);
      return regex.test(location.pathname);
    });

    if (currentRoute) {
      if (
        currentRoute?.roles &&
        !currentRoute.roles.includes(currentUser.role) &&
        !currentRoute?.organizationRoles
      ) {
        navigate("/");
      }

      if (
        currentRoute?.organizationRoles &&
        !currentRoute.organizationRoles.includes(currentUser.organizationRole)
      ) {
        navigate("/");
      }
    }
    setIsUserAllowedToView(true);
  };

  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect_to=${location.pathname}`} />;
  }

  if (width < AppResponsivenessDisabledWidth) {
    return <MobileRedirect />;
  }

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        breakpoint="sm"
        collapsedWidth="70"
        style={{
          borderRight: "1px solid #C6CEDC",
        }}
      >
        <div
          style={{
            position: "fixed",
            width: "200px",
            borderRight: "1px solid rgb(198, 206, 220)",
          }}
        >
          <div>
            <img
              className="img-fluid mt-4 ms-3 w-75"
              src="/assets/prme-logo.png"
              alt={t("alts.logo")}
            />
          </div>
          {currentUser ? (
            <div>
              {currentUser.role === Roles.ADMIN.value && (
                <div
                  className="mt-4 py-2 text-center"
                  style={{ backgroundColor: token.colorTextThird }}
                >
                  <Title
                    level={5}
                    style={{
                      fontSize: "17px",
                      color: "white",
                      fontWeight: "300",
                    }}
                  >
                    {t("prmeSecretariat")}
                  </Title>
                </div>
              )}
              <Menu
                defaultSelectedKeys={selectedKeys}
                selectedKeys={selectedKeys}
                className={
                  currentUser.role === Roles.ADMIN.value ? "mt-2" : "mt-4"
                }
                onClick={(e) => handleMenuItemClick(e.key)}
                items={MenuItems(currentUser)
                  .filter((i) => {
                    const hasUserRole =
                      !i?.roles || i.roles.includes(currentUser.role);
                    const hasOrganizationRole =
                      hasUserRole &&
                      i?.organizationRoles &&
                      i.organizationRoles.includes(
                        currentUser?.organizationRole,
                      );

                    return hasUserRole || hasOrganizationRole;
                  })}
              />
            </div>
          ) : null}
        </div>
      </Sider>
      <Layout>
        {userResponse.isSuccess && <Header user={userResponse.data} />}
        <Content
          className={location.pathname === "/" ? "" : "px-4 pb-2 pt-4"}
          style={
            showGreyBackground
              ? { backgroundColor: "rgb(243, 243, 247)" }
              : {}
          }
        >
          <div className={location.pathname === "/" ? "h-100" : "px-5 mt-2"}>
            {isUserAllowedToView && <Outlet />}
          </div>
        </Content>
        <Footer
            id="footer"
            style={{
              padding: "7px 0",
              textAlign: "center",
              borderTop: "1px solid rgba(198, 206, 220, 1)",
            }}
        >
          <a
              onClick={() => navigate("/privacy-policy")}
              style={{color: "black", textDecoration: "underline"}}
          >
            {t("privacyPolicy")}
          </a>
          <span className="mx-3">|</span>
          <a
              onClick={() => navigate("/faqs")}
              style={{color: "black", textDecoration: "underline"}}
          >
            {t("faqs")}
          </a>
          <span className="mx-3">|</span>
          <a
              href="https://prme.storylane.io/share/iuag9sbcz72f"
              target="_blank"
              rel="noreferrer"
              style={{color: "black", textDecoration: "underline"}}
          >
            {t("demoTutorial")}
          </a>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AuthenticatedLayout;
