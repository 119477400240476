import { useEffect, useMemo, useState } from "react";

import { Alert, Col, Row, Spin, theme, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  useGetCurrentUserQuery,
  useGetPrincipleByIdQuery,
  useGetSurveyByIdQuery,
  useGetSurveyStatisticsQuery,
  useLazyGetSurveyStatisticsQuery,
} from "../../../../redux/service.js";
import {
  setShowGreyBackground,
} from "../../../../redux/utils/reducer.js";
import PrincipleStatistics from "./PrincipleStatistics.jsx";
import PrincipleProgressBar from "./PrincipleProgressBar.jsx";
import Question from "./Question.jsx";
import {
  Roles,
  SurveyActions,
  SurveyStatus,
} from "../../../../utilities/constants.jsx";
import PrimaryButton from "../../../../components/PrimaryButton.jsx";
import SubmissionConfirmationModal from "./SubmissionConfirmationModal.jsx";
import { canUserPerformActionOnSurvey } from "../../../../utilities/permissions.js";
import PrincipleIcon from "../../../../components/PrincipleIcon.jsx";

const { Title, Text } = Typography;


const PrincipleNavigationButton = ({ principle, isPrevious }) => {
  const navigate = useNavigate();

  const navigateToPrinciple = useMemo(() => {
    if (isPrevious) {
      return principle.previousPrinciple;
    }
    return principle.nextPrinciple;
  }, [principle, isPrevious]);

  const icon = useMemo(() => navigateToPrinciple.icon &&
      <PrincipleIcon color={navigateToPrinciple.color} src={navigateToPrinciple.icon} />
  , [navigateToPrinciple]);

  return (
      <a
          onClick={() =>
              navigate(
                  `/reports/${principle.survey}/principle/${navigateToPrinciple.id}`,
              )
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: isPrevious ? "flex-start" : "flex-end",
          }}
      >
        {!isPrevious && icon}
        <Title level={4}>
          {isPrevious ? (
              <>
                <ArrowLeftOutlined/> {navigateToPrinciple.title}
              </>
          ) : (
              <>
                {principle.nextPrinciple.title} <ArrowRightOutlined/>
              </>
          )}
        </Title>
        {isPrevious && <div style={{marginLeft: '10px'}}>{icon}</div>}
      </a>
  )
};

PrincipleNavigationButton.propTypes = {
  principle: PropTypes.object.isRequired,
  isPrevious: PropTypes.bool.isRequired,
};

const SurveyPrincipleForm = ({activeSurveyId}) => {
  const {principleId} = useParams();
  const {token} = theme.useToken();

  const {
    data: surveyStatisticsData,
    isSuccess: surveyStatisticsResponseSuccess,
  } = useGetSurveyStatisticsQuery({survey: activeSurveyId});
  const currentPrincipleStatistics = surveyStatisticsResponseSuccess
      ? surveyStatisticsData.find((i) => i.id == principleId)
      : null;
  const {data: principle, isSuccess} = useGetPrincipleByIdQuery(principleId);

  return (
      isSuccess && (
          <div>
            <div>
              <Row
                  style={{
                    backgroundColor: "white",
                    boxShadow: "0px 8px 40px 0px rgba(0, 0, 61, 0.051)",
            }}
          >
            <Col
              span={6}
              style={{ textAlign: "center", backgroundColor: principle.color }}
              className="py-5"
            >
              {principle.icon && (
                <img
                  height="90px"
                  src={principle.icon}
                  alt="icon for principle"
                />
              )}
            </Col>
            <Col span={18}>
              <Row>
                <Col span={12} className="ps-4 pe-3 py-5">
                  <Title
                    level={3}
                    style={{ fontWeight: "bold", color: token.colorTextThird }}
                  >
                    {principle.title}
                  </Title>
                  <Text style={{ color: token.colorTextThird }}>
                    {principle.description}
                  </Text>
                </Col>
                <Col span={12} className="px-3 py-4">
                  {currentPrincipleStatistics && (
                    <PrincipleStatistics
                      principle={currentPrincipleStatistics}
                      showTooltips={true}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="mt-4">
            <Title level={4} style={{ color: token.colorTextThird }}>
              {principle.label}
            </Title>
            {principle.questions
              .slice()
              .sort((a, b) => a.index - b.index)
              .map((i) => (
                <Question key={i} questionId={i} principle={principle} />
              ))}
          </div>
        </div>
        <Row className="pt-5 pb-3">
          <Col xl={12} lg={12} md={12}>
            {principle.previousPrinciple && <PrincipleNavigationButton principle={principle} isPrevious />}
          </Col>
          <Col xl={12} lg={12} md={12} style={{ textAlign: "right" }}>
            {principle.nextPrinciple && <PrincipleNavigationButton principle={principle} isPrevious={false} />}
          </Col>
        </Row>
      </div>
    )
  );
};

SurveyPrincipleForm.propTypes = {
  activeSurveyId: PropTypes.number.isRequired,
};

const SurveyOrganizationForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { surveyId } = useParams();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [hasPermissions, setHasPermissions] = useState(false);

  const {
    data: survey,
    isSuccess: isSurveySuccess,
    isLoading,
  } = useGetSurveyByIdQuery({ surveyId });
  const [getSurveyStatistics, surveyStatisticsResponse] =
    useLazyGetSurveyStatisticsQuery();
  const {
    data: currentUser,
    isSuccess: currentUserQuerySuccess,
    isLoading: isCurrentUserLoading,
  } = useGetCurrentUserQuery();

  const isSurveyFinished = useMemo(() => {
    if (surveyStatisticsResponse.isSuccess) {
      return surveyStatisticsResponse.data.every(
        (i) => i.answeredQuestionsCount >= i.requiredQuestionsCount,
      );
    }
    return false;
  }, [surveyStatisticsResponse]);

  useEffect(() => {
    dispatch(setShowGreyBackground(true));

    return () => dispatch(setShowGreyBackground(false));
  }, []);

  useEffect(() => {
    if (isSurveySuccess) {
      if (
        survey.status === SurveyStatus.ENDED.value ||
        survey.details?.isSubmitted
      ) {
        navigate(`/reports/${survey.id}/report`);
      } else {
        getSurveyStatistics({ survey: survey.id });
      }
    }

    if (currentUserQuerySuccess) {
      if (currentUser.role === Roles.ADMIN.value) {
        navigate("/");
      }
    }

    if (currentUserQuerySuccess && isSurveySuccess) {
      const hasPermissions =
        canUserPerformActionOnSurvey(
          survey.permissions,
          currentUser.organizationRole,
        ) || currentUser.role === Roles.ADMIN.value;
      if (!hasPermissions) {
        navigate("/");
      }
      setHasPermissions(hasPermissions);
    }
  }, [isSurveySuccess, currentUserQuerySuccess]);

  if (
    isLoading ||
    surveyStatisticsResponse.isLoading ||
    isCurrentUserLoading ||
    !hasPermissions
  ) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  return isSurveySuccess ? (
    <div
      className="px-4"
      style={{ margin: "auto", backgroundColor: "rgba(243, 243, 247, 1)" }}
    >
      {surveyStatisticsResponse.isSuccess && (
        <PrincipleProgressBar principles={surveyStatisticsResponse.data} />
      )}
      {isSurveyFinished &&
      canUserPerformActionOnSurvey(
        survey.permissions,
        currentUser.organizationRole,
        SurveyActions.SUBMIT.value,
      ) ? (
        <Alert
          className="mt-4 pt-3 pb-3 px-4"
          style={{ borderRadius: "0" }}
          message={
            <Row className="">
              <Col span={20}>
                <Text>{t("surveyFinishedNotSubmitted")}</Text>
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                <PrimaryButton onClick={() => setOpenConfirmationModal(true)}>
                  {t("submitSurvey")}
                </PrimaryButton>
              </Col>
            </Row>
          }
          type="success"
        />
      ) : null}
      <div className={isSurveyFinished ? "mt-3" : "mt-5"}>
        <SurveyPrincipleForm activeSurveyId={survey.id} />
      </div>
      <SubmissionConfirmationModal
        onCancel={() => setOpenConfirmationModal(false)}
        survey={survey}
        open={openConfirmationModal}
      />
    </div>
  ) : (
    <div className="px-5" style={{ backgroundColor: "rgba(243, 243, 247, 1)" }}>
      <Title level={4}>{t("noActiveSurvey")}</Title>
    </div>
  );
};

export default SurveyOrganizationForm;
