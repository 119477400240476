import { useTranslation } from "react-i18next";
import { Typography } from "antd";

const { Title } = Typography;

const NoResults = () => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: "center" }} className="mt-5">
      <Title level={4} style={{ fontWeight: 400 }}>
        {t("form.labels.noResults")}
      </Title>
    </div>
  );
};

export default NoResults;
