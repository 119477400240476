import { useMemo, useState } from "react";

import { Col, Collapse, Row, Typography } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import {
  commonsApi,
  useCreateAnswerMutation,
  useGetCurrentUserQuery,
  useUpdateAnswerMutation,
} from "../../../../redux/service.js";
import PrimaryButton from "../../../../components/PrimaryButton.jsx";
import ObjectDetails from "../../../objects/ObjectDetails.jsx";
import ObjectSelectModal from "../../../objects/components/ObjectSelectModal.jsx";
import NarrativesSelectModal from "../../../narratives/components/NarrativesSelectModal.jsx";
import ObjectModalForm from "../../../objects/ObjectModalForm.jsx";
import { ObjectTypes, OptionType } from "../../../../utilities/constants.jsx";

const { Title, Text } = Typography;

const TitleLabel = ({ icon, title }) => (
  <div style={{ display: "flex" }}>
    <div>
      <img src={icon} style={{ width: "25px" }} />
    </div>
    <div className="ms-2">
      <Title
        level={5}
        style={{
          fontWeight: "600",
          color: "rgba(96, 100, 108, 1)",
        }}
      >
        {title}
      </Title>
    </div>
  </div>
);

TitleLabel.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
};

export const OptionEntities = ({ option, answer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [updateAnswer] = useUpdateAnswerMutation();
  const [createAnswer] = useCreateAnswerMutation();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [openFormObjectModal, setOpenFormObjectModal] = useState({
    open: false,
    object: null,
  });
  const [objectsModalOpen, setObjectsModalOpen] = useState({
    open: false,
    object: null,
  });
  const [narrativeModalOpen, setNarrativeModalOpen] = useState(false);

  const requiredObjects = useMemo(
    () =>
      (option?.optionEntities || []).filter(
        (i) => i.type === OptionType.OBJECT.value,
      ),
    [option],
  );
  const requiredNarratives = useMemo(
    () =>
      (option?.optionEntities || []).filter(
        (i) => i.type === OptionType.NARRATIVE.value,
      ),
    [option],
  );

  const onSuccessAnswer = (data) => {
    if (data) {
      dispatch(
        commonsApi.util.updateQueryData(
          "getQuestionById",
          { questionId: option.question },
          (question) => ({
            ...question,
            options: question.options.map((i) => {
              if (i.id === option.id) {
                return { ...i, answer: data.id };
              }
              return i;
            }),
          }),
        ),
      );
    }
    setNarrativeModalOpen(false);
    setObjectsModalOpen({
      open: false,
      object: null,
    });
  };

  const addNarrativesAndObjectsToAnswer = (objects, narratives) => {
    if (answer) {
      updateAnswer({
        id: answer.id,
        data: {
          ...answer,
          attachedObjectIds: objects.map((i) => i.id),
          attachedNarrativeIds: narratives.map((i) => i.id),
        },
      })
        .unwrap()
        .then(() => onSuccessAnswer(null));
    } else {
      createAnswer({
        option: option.id,
        question: option.question,
        organization: currentUser.organization.id,
        attachedObjectIds: objects.map((i) => i.id),
        attachedNarrativeIds: narratives.map((i) => i.id),
      })
        .unwrap()
        .then((data) => onSuccessAnswer(data));
    }
  };

  const removeNarrative = (narrativeId) => {
    updateAnswer({
      id: answer.id,
      data: {
        option: answer.option,
        question: answer.question,
        organization: answer.organization,
        attachedObjectIds: answer.attachedObjects.map((i) => i.id),
        attachedNarrativeIds: [
          ...answer.attachedNarratives
            .map((i) => i.id)
            .filter((i) => i !== narrativeId),
        ],
      },
    });
  };

  const removeObject = (objectId) => {
    updateAnswer({
      id: answer.id,
      data: {
        option: answer.option,
        question: answer.question,
        organization: answer.organization,
        attachedObjectIds: [
          ...answer.attachedObjects
            .filter((i) => i.id !== objectId)
            .map((i) => i.id),
        ],
        attachedNarrativeIds: answer.attachedNarratives.map((i) => i.id),
      },
    });
  };

  const shouldDisableObjectTypeField = useMemo(() => {
    if (requiredObjects.length > 0) return false;

    return requiredObjects.filter((i) => i.objectType !== "").length > 0;
  }, [requiredObjects]);

  return (
    <div>
      {answer &&
      (answer.attachedObjects.length > 0 ||
        answer.attachedNarratives.length > 0) ? (
        <div className="py-1 px-3 mt-3">
          <div>
            {answer.attachedObjects.map((object) => (
              <div key={object.id} className="mt-3">
                <Collapse
                  style={{
                    width: "90%",
                  }}
                  expandIconPosition="end"
                  expandIcon={({ isActive }) =>
                    isActive ? <DownOutlined /> : <UpOutlined />
                  }
                  className="mt-3 mb-4 object-details-collapse"
                  items={[
                    {
                      key: "1",
                      label: (
                        <Row
                          style={{
                            justifyContent: "space-between",
                          }}
                        >
                          <Col lg={16} md={16} sm={24}>
                            <TitleLabel
                              title={object.title}
                              icon="/assets/object-repository.svg"
                            />
                          </Col>
                          <Col lg={8} md={8} sm={24}>
                            <div style={{ display: "flex" }}>
                              <div>
                                <PrimaryButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenFormObjectModal({
                                      open: true,
                                      object,
                                    });
                                  }}
                                >
                                  {t("buttons.edit")}
                                </PrimaryButton>
                              </div>
                              <div className="ms-3">
                                <PrimaryButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeObject(object.id);
                                  }}
                                >
                                  {t("labels.remove")}
                                </PrimaryButton>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ),
                      children: <ObjectDetails initialId={object.id} />,
                    },
                  ]}
                />
              </div>
            ))}
            {answer.attachedNarratives.map((narrative) => (
              <div
                key={narrative.id}
                className="py-3 px-3 mb-2"
                style={{
                  width: "90%",
                  borderRadius: 0,
                  backgroundColor: "rgba(243, 243, 247, 1)",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TitleLabel title={narrative.title} icon="/assets/object.svg" />
                <div style={{ display: "flex" }}>
                  <div>
                    <PrimaryButton
                      type="secondary"
                      className="ms-5"
                      onClick={() => {
                        navigate(`/narratives/${narrative.id}`);
                      }}
                    >
                      {t("labels.view")}
                    </PrimaryButton>
                  </div>
                  <div>
                    <PrimaryButton
                      type="secondary"
                      className="ms-3"
                      onClick={() => removeNarrative(narrative.id)}
                    >
                      {t("labels.remove")}
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <RequiredEntityButtons
        option={
          answer
            ? {
                ...option,
                requiredObjectsCount: answer.requiredObjectsCount,
                requiredNarrativesCount: answer.requiredNarrativesCount,
              }
            : option
        }
        setObjectsModalOpen={setObjectsModalOpen}
        setNarrativeModalOpen={setNarrativeModalOpen}
      />
      <ObjectSelectModal
        onCancel={() =>
          setObjectsModalOpen({
            object: null,
            open: false,
          })
        }
        open={objectsModalOpen.open}
        multiple
        required={false}
        filters={{
          type: requiredObjects.map((i) => i.objectType),
          organizations: currentUser.organization.id,
        }}
        initialSelectedObjects={answer?.attachedObjects || []}
        onSelect={(objects) =>
          addNarrativesAndObjectsToAnswer(
            objects,
            answer?.attachedNarratives || [],
          )
        }
        onCreateObject={() => {
          setOpenFormObjectModal({
            open: true,
            object:
              requiredObjects.length > 0
                ? {
                    type: requiredObjects[0].objectType,
                    ...(requiredObjects[0]?.suggestedContent || {}),
                  }
                : null,
          });
          setObjectsModalOpen({
            open: false,
            object: null,
          });
        }}
      />
      <NarrativesSelectModal
        onCancel={() => setNarrativeModalOpen(false)}
        open={narrativeModalOpen}
        multiple
        required={false}
        filters={{
          organizations: currentUser.organization.id,
        }}
        initialSelectedNarratives={answer?.attachedNarratives || []}
        onSelect={(narratives) =>
          addNarrativesAndObjectsToAnswer(
            answer?.attachedObjects || [],
            narratives,
          )
        }
        onCreateNarrative={() => {
          const narrativeInitialData =
            requiredNarratives.length > 0
              ? requiredNarratives[0].suggestedContent
              : null;
          navigate("/narratives/form", {
            state: {
              initialData: narrativeInitialData,
              previousLocation: location.pathname,
              answer: {
                ...answer,
                option: option.id,
                question: option.question,
                organization: currentUser.organization.id,
              },
            },
          });
        }}
      />
      <ObjectModalForm
        open={openFormObjectModal.open}
        initialData={openFormObjectModal.object}
        disabledFields={shouldDisableObjectTypeField ? ["type"] : []}
        onCancel={() => setOpenFormObjectModal({ open: false, object: null })}
        submitCallback={(data) => {
          // If this is not an edit object action, set the object
          if (
            (answer?.attachedObjects || []).filter((i) => i.id === data.id)
              .length === 0
          ) {
            addNarrativesAndObjectsToAnswer(
              [...(answer?.attachedObjects || []), data],
              answer?.attachedNarratives || [],
            );
          } else {
            dispatch(
              commonsApi.util.invalidateTags([
                { type: "Question", id: option.question },
              ]),
            );
          }
        }}
      />
    </div>
  );
};

OptionEntities.propTypes = {
  option: PropTypes.object.isRequired,
  answer: PropTypes.object,
};

OptionEntities.defaultProps = {
  answer: null,
};

export const RequiredEntityButtons = ({
  option,
  setObjectsModalOpen,
  setNarrativeModalOpen,
}) => {
  const { t } = useTranslation();

  const generateRequiredObjectsSentence = (requiredTypes) => {
    let sentenceParts = requiredTypes.map((type) => {
      if (type.objectType) {
        return `${type.count} ${t("labels.object").toLowerCase()}${type.count > 1 ? "s" : ""} ${t("ofType")} ${type.objectType}`;
      } else {
        return `${type.count} ${t("labels.object").toLowerCase()}${type.count > 1 ? "s" : ""} ${t("ofAnyType")}`;
      }
    });

    if (sentenceParts.length === 1) {
      return `${t("youNeedToAttach")} ${sentenceParts[0]} ${t("toThisAnswerForValid")}.`;
    } else {
      const lastPart = sentenceParts.pop();
      return `${t("youNeedToAttach")} ${sentenceParts.join(", ")} ${t("and")} ${lastPart} ${t("toThisAnswerForValid")}.`;
    }
  };

  const requiredObjectSentence = useMemo(() => {
    if (option?.optionEntities) {
      const requiredObjectTypes = option.optionEntities
        .filter((i) => i.type === OptionType.OBJECT.value)
        .reduce((acc, proof) => {
          const type = proof.objectType;
          if (acc[type]) {
            acc[type]++;
          } else {
            acc[type] = 1;
          }
          return acc;
        }, {});
      return generateRequiredObjectsSentence(
        Object.keys(requiredObjectTypes).map((type) => ({
          objectType: ObjectTypes.getItemByValue(type)?.label,
          count: requiredObjectTypes[type],
        })),
      );
    }
    return null;
  }, [option]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div className="me-4">
          <div style={{ textAlign: "right" }} className="mt-3">
            <a
              style={{ color: "rgba(74, 105, 156, 1)" }}
              onClick={() => {
                setObjectsModalOpen((oldVal) => ({
                  ...oldVal,
                  open: true,
                }));
              }}
            >
              <Text>+ {t("addObject")}</Text>
            </a>
          </div>
        </div>
        <div className="me-4">
          <div style={{ textAlign: "right" }} className="ms-3 mt-3">
            <a
              style={{ color: "rgba(74, 105, 156, 1)" }}
              onClick={() => setNarrativeModalOpen(true)}
            >
              <Text>+ {t("addNarrative")}</Text>
            </a>
          </div>
        </div>
      </div>
      {option.requiredNarrativesCount > 0 && (
        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img src="/assets/warning-icon.svg" alt="warning icon" />
          <Text className="ms-2" style={{ color: "red" }}>
            {t("surveyRequiredNarrativesAttached", {
              narrativesCount: option.requiredNarrativesCount,
              plural: option.requiredNarrativesCount > 1 ? "s" : "",
            })}
          </Text>
        </div>
      )}
      {option.requiredObjectsCount > 0 && (
        <div
          className="mt-1"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img src="/assets/warning-icon.svg" alt="warning icon" />
          <Text className="ms-2" style={{ color: "red" }}>
            {requiredObjectSentence}
          </Text>
        </div>
      )}
    </div>
  );
};

RequiredEntityButtons.propTypes = {
  setObjectsModalOpen: PropTypes.func.isRequired,
  setNarrativeModalOpen: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
};
