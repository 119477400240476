import { theme, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const MobileRedirect = () => {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  return (
    <div style={{ textAlign: "center" }} className="py-3 px-5">
      <img
        src="/assets/prme-logo.png"
        alt="prme logo"
        className="mt-4 mb-5"
        style={{ width: "60%" }}
      />
      <div className="mt-5">
        <img
          src="/assets/desktop.svg"
          alt="desktop icon"
          className="mb-3"
          style={{ width: "60px" }}
        />
        <Title level={4} className="mb-4">
          {t("switchToDesktop")}
        </Title>
        <div className="mb-3">
          <Text style={{ color: token.colorBackgroundSecondary }}>
            {t("desktopResponsivenessRedirectText1")}
          </Text>
        </div>
        <Text style={{ color: token.colorBackgroundSecondary }}>
          {t("desktopResponsivenessRedirectText2")}
        </Text>
      </div>
    </div>
  );
};

export default MobileRedirect;
