import { Input, Typography } from "antd";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

import FieldError from "./FieldError.jsx";

const { Text } = Typography;

const NumericInput = ({ name, control, isEdit, placeholder }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  const handleChange = (e) => {
    let { value: inputValue } = e.target;
    const reg = /^\d*(\.\d*)?$/;
    if (inputValue.includes(".")) {
      inputValue = inputValue.replace(".", "");
    }
    if (reg.test(inputValue) || inputValue === "") {
      field.onChange(inputValue);
    }
  };

  return isEdit ? (
    <div>
      <Input
        {...field}
        onChange={handleChange}
        style={{
          borderRadius: 0,
          height: "35px",
        }}
        placeholder={placeholder}
        maxLength={16}
      />
      {error && <FieldError errors={error} />}
    </div>
  ) : (
    <Text>{field.value}</Text>
  );
};

NumericInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  placeholder: PropTypes.string,
};

NumericInput.defaultProps = {
  isEdit: true,
  placeholder: null,
};

export default NumericInput;
