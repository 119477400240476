import { useMemo } from "react";

import { Col, Layout, Row, Typography, Image } from "antd";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import PrimaryButton from "../PrimaryButton.jsx";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const { Title } = Typography;

const DefaultLayout = () => {
  const { Content, Footer } = Layout;
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const link = useMemo(() => {
    if (location.pathname === "/login") {
      return {
        title: t("links.loginPage"),
        to: "/register",
        buttonContent: t("buttons.register"),
      };
    }
    return {
      title: t("links.registerPage"),
      to: "/login",
      buttonContent: t("buttons.signIn"),
    };
  }, [location.pathname]);

  if (isAuthenticated) {
    if (location.pathname.includes("/public")) {
      return (
        <Navigate
          to={location.pathname.replace("/public", "") + location.search}
        />
      );
    }
    return (
      <Navigate
        to={
          searchParams.has("redirect_to") ? searchParams.get("redirect_to") : ""
        }
      />
    );
  }

  return (
    <Layout>
      <div className="mt-3 me-4" style={{ right: 0, position: "absolute" }}>
        <Row>
          <Col className="me-3">
            <Title level={5}>{link.title}</Title>
          </Col>
          <Col>
            <PrimaryButton onClick={() => navigate(link.to)}>
              {link.buttonContent}
            </PrimaryButton>
          </Col>
        </Row>
      </div>
      {!location.pathname.includes("/report") && (
        <div className="text-center mt-5">
          <Row justify="center">
            <Col>
              <div className="image-container">
                <Image
                  className="img-fluid"
                  src="/assets/prme-logo.png"
                  width={330}
                  alt={t("alts.logo")}
                  preview={false}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
      <Content className="w-100">
        <Outlet />
      </Content>
      <Footer>
        <Row>
          <Col />
          <Col />
        </Row>
      </Footer>
    </Layout>
  );
};

export default DefaultLayout;
