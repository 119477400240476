import { Avatar as AntdAvatar, theme } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Avatar = ({ user, shape, shouldRedirect, ...props }) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getInitials = () => {
    if (user.firstName && user.lastName) {
      return (user.firstName.charAt(0) + user.lastName.charAt(0)).toUpperCase();
    }
    return user.fullName.slice(0, 2).toUpperCase();
  };

  const onClick = () =>
    user?.id && shouldRedirect ? () => navigate(`/profile/${user.id}`) : null;

  return user.profilePicture ? (
    <img
      src={user.profilePicture}
      style={{ width: "2.5vw" }}
      alt={t("alts.userProfilePicture")}
      onClick={onClick}
      {...props}
    />
  ) : (
    <AntdAvatar
      shape={shape}
      size="large"
      style={{ backgroundColor: token.colorPrimaryText, cursor: "pointer" }}
      onClick={onClick}
      {...props}
    >
      {getInitials()}
    </AntdAvatar>
  );
};

Avatar.propTypes = {
  user: PropTypes.object.isRequired,
  shape: PropTypes.string,
  shouldRedirect: PropTypes.bool,
};

Avatar.defaultProps = {
  shape: "square",
  shouldRedirect: true,
};

export default Avatar;
