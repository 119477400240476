import { useController } from "react-hook-form";
import { Select } from "antd";
import PropTypes from "prop-types";
import { DownOutlined } from "@ant-design/icons";

import FieldError from "./FieldError.jsx";
import Tag from "../Tag.jsx";

const SelectField = ({
  name,
  control,
  open,
  options,
  mode,
  defaultValue,
  placeholder,
  disabled,
  dropdownRender,
  singleValue,
  isEdit,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  const renderValue = () => {
    if (options.length === 0) {
      if (Array.isArray(field.value)) {
        return field.value.join(", ");
      }
      return field.value;
    } else {
      if (Array.isArray(field.value)) {
        return options
          .filter((i) => field.value.includes(i.value))
          .map((i) => i.label)
          .join(", ");
      }
      return options.find((i) => i.value === field.value)?.label;
    }
  };

  const handleSearch = (input, option) => {
    return option.label.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <div>
      {isEdit ? (
        <Select
          defaultValue={defaultValue}
          options={options}
          open={open}
          status={error && "error"}
          style={{ borderRadius: 0, width: "100%" }}
          mode={mode}
          suffixIcon={mode === "tags" ? null : <DownOutlined />}
          tagRender={Tag}
          showSearch={mode !== "tags"}
          {...field}
          onChange={(value) => {
            if (singleValue) {
              return field.onChange(value[value.length - 1]);
            } else {
              return field.onChange(value);
            }
          }}
          value={field.value === "" ? null : field.value}
          disabled={disabled}
          placeholder={placeholder}
          dropdownRender={dropdownRender}
          filterOption={handleSearch}
        />
      ) : (
        renderValue()
      )}
      {error && <FieldError errors={error} />}
    </div>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  open: PropTypes.bool,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  mode: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownRender: PropTypes.func,
  isEdit: PropTypes.bool,
  singleValue: PropTypes.bool,
};

SelectField.defaultProps = {
  defaultValue: undefined,
  mode: null,
  open: undefined,
  options: [],
  placeholder: null,
  disabled: false,
  dropdownRender: null,
  isEdit: true,
  singleValue: false,
};

export default SelectField;
