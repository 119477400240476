import { useEffect, useState } from "react";

import { App, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import PrimaryButton from "../../../../components/PrimaryButton.jsx";
import SurveyViewsTable from "./SurveyViewsTable.jsx";

const { Title } = Typography;

const SurveySelectModal = ({
  open,
  onCancel,
  onSelect,
  multiple,
  required,
  initialSelectedSurveys,
  filters,
}) => {
  const { t } = useTranslation();
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const { notification } = App.useApp();

  useEffect(() => {
    setSelectedSurveys(initialSelectedSurveys);
  }, [initialSelectedSurveys]);

  const columns = [
    {
      title: t("table.title"),
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    {
      title: t("table.owner"),
      dataIndex: "createdBy.fullName",
      addSearch: true,
      ellipsis: true,
      key: "organization",
      render: (_, record) => {
        if (record.organization) {
          return record.organization.name;
        }
        return record.createdBy.fullName;
      },
    },
  ];

  const handleConfirm = () => {
    if (
      ((Array.isArray(selectedSurveys) && selectedSurveys.length === 0) ||
        selectedSurveys === null) &&
      required
    ) {
      notification.error({ message: "You must select a report view." });
      return;
    }

    if (multiple) {
      onSelect(selectedSurveys);
    } else {
      onSelect([selectedSurveys[0]]);
    }
  };

  return (
    <Modal
      open={open}
      width={900}
      title={
        <div style={{ display: "flex" }}>
          <div>
            <Title level={3}>{t("labels.selectSurvey")}</Title>
          </div>
        </div>
      }
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <PrimaryButton key="cancel" onClick={onCancel}>
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton key="confirm" onClick={handleConfirm}>
          {t("buttons.confirm")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <SurveyViewsTable
        columns={columns}
        rowSelection={{
          type: multiple ? "checkbox" : "radio",
          onSelect: multiple
            ? (record, selected) => {
                if (!selected) {
                  setSelectedSurveys((oldVal) => {
                    oldVal.filter((i) => i.uuid !== record.uuid);
                  });
                } else {
                  setSelectedSurveys((oldval) => [...oldval, record]);
                }
              }
            : (record) => setSelectedSurveys([record]),
          hideSelectAll: true,
          selectedRowKeys: selectedSurveys.map((i) => i.uuid),
        }}
        filters={filters}
      />
    </Modal>
  );
};

SurveySelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  initialSelectedSurveys: PropTypes.array,
  filters: PropTypes.object,
};

SurveySelectModal.defaultProps = {
  multiple: false,
  required: false,
  initialSelectedSurveys: [],
  filters: {},
};

export default SurveySelectModal;
