import { useState } from "react";

import { Col, Row, Typography } from "antd";
import PropTypes from "prop-types";
import createDOMPurify from "dompurify";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import PrimaryButton from "../../../components/PrimaryButton.jsx";
import ObjectSelectModal from "../../objects/components/ObjectSelectModal.jsx";
import ObjectModalForm from "../../objects/ObjectModalForm.jsx";

const { Title } = Typography;

const ObjectBox = ({ object, onRemove }) => (
  <div
    style={{ backgroundColor: "rgba(237, 237, 237, 1)" }}
    className="p-2 ps-3"
  >
    <Row>
      <Col span={20}>
        <Row>
          <Col>
            <img src="/assets/object.svg" alt="object icon" className="me-3" />
          </Col>
          <Col span={22}>
            <Title level={5}>{object.title}</Title>
          </Col>
        </Row>
      </Col>
      <Col style={{ marginLeft: "auto" }} className="me-3">
        <PrimaryButton onClick={onRemove} type="secondary">
          Remove
        </PrimaryButton>
      </Col>
    </Row>
  </div>
);

ObjectBox.propTypes = {
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }),
  onRemove: PropTypes.func.isRequired,
};

const ChapterBox = ({
  data,
  existingChapters,
  formSetValue,
  chapterIndex,
  setChapterFormModalOpen,
}) => {
  const DOMPurify = createDOMPurify(window);
  const { t } = useTranslation();

  const [objectSelectModalOpen, setObjectSelectModalOpen] = useState(false);
  const [createObjectModalOpen, setCreateObjectModalOpen] = useState(false);

  const handleEdit = () => {
    setChapterFormModalOpen({
      open: true,
      chapter: existingChapters[chapterIndex],
    });
  };

  const deleteChapter = () => {
    const newChapters = existingChapters.filter(
      (i, index) => index !== chapterIndex,
    );
    formSetValue("chapters", newChapters);
  };

  const addObjectsToChapter = (objects) => {
    const newChapters = existingChapters.map((i, index) => {
      if (index === chapterIndex) {
        return {
          ...i,
          attachedObjects: [...(i?.attachedObjects || []), ...objects],
        };
      }
      return i;
    });
    formSetValue("chapters", newChapters);
    setObjectSelectModalOpen(false);
  };

  const handleObjectRemove = (objectId) => {
    const newChapters = existingChapters.map((i, index) => {
      if (index === chapterIndex) {
        return {
          ...i,
          attachedObjects: i.attachedObjects.filter(
            (object) => object.id !== objectId,
          ),
        };
      }
      return i;
    });
    formSetValue("chapters", newChapters);
  };

  return (
    <div>
      <Row>
        <Col xl={4} md={6} className="me-4">
          <PrimaryButton type="dashed" onClick={handleEdit}>
            <EditOutlined style={{ verticalAlign: "initial" }} />
            {t("editChapter")}
          </PrimaryButton>
        </Col>
        <Col xl={4} md={6} className="me-4">
          <PrimaryButton type="dashed" onClick={deleteChapter}>
            <DeleteOutlined style={{ verticalAlign: "initial" }} />
            {t("deleteChapter")}
          </PrimaryButton>
        </Col>
        <Col xl={4} md={6} className="me-4">
          <PrimaryButton
            type="dashed"
            onClick={() => setObjectSelectModalOpen(true)}
          >
            <SelectOutlined style={{ verticalAlign: "initial" }} />
            {t("selectObject")}
          </PrimaryButton>
        </Col>
        <Col xl={4} md={6} className="me-4">
          <PrimaryButton
            type="dashed"
            onClick={() => setCreateObjectModalOpen(true)}
          >
            <PlusOutlined style={{ verticalAlign: "initial" }} />
            {t("addObject")}
          </PrimaryButton>
        </Col>
      </Row>
      <Title level={5} className="mt-3">
        {data.title}
      </Title>
      <div
        className="mt-4"
        style={{ wordWrap: "break-word" }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(data.text, {
            USE_PROFILES: { html: true },
          }),
        }}
      />
      <div className="mt-2">
        {(data?.attachedObjects || []).map((i) => (
          <div key={i.id} className="mt-3">
            <ObjectBox object={i} onRemove={() => handleObjectRemove(i.id)} />
          </div>
        ))}
      </div>
      <ObjectSelectModal
        open={objectSelectModalOpen}
        multiple
        onCancel={() => setObjectSelectModalOpen(false)}
        onSelect={addObjectsToChapter}
      />
      <ObjectModalForm
        open={createObjectModalOpen}
        onCancel={() => setCreateObjectModalOpen(false)}
        initialData={{
          title: data.title,
        }}
        submitCallback={(data) => addObjectsToChapter([data])}
      />
    </div>
  );
};

ChapterBox.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    attachedObjects: PropTypes.array,
  }).isRequired,
  chapterIndex: PropTypes.number.isRequired,
  existingChapters: PropTypes.array.isRequired,
  formSetValue: PropTypes.func.isRequired,
  setChapterFormModalOpen: PropTypes.func.isRequired,
};

export default ChapterBox;
