import { useCallback, useEffect, useState } from "react";

import { App, Modal } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PrimaryButton from "../../components/PrimaryButton.jsx";
import {
  useCreateObjectMutation,
  useEditObjectMutation,
  useGetCurrentUserQuery,
} from "../../redux/service.js";
import { handleErrors } from "../../utilities/index.js";
import { Roles } from "../../utilities/constants.jsx";
import { objectSchema } from "./components/ObjectFormHelpers.jsx";
import ObjectDetailsFormFields from "./components/ObjectDetailsFormFields.jsx";
import ObjectTypeFormFields from "./components/ObjectTypeFormFields.jsx";

const defaultValues = {
  type: null,
  contributors: [],
  title: null,
  date: null,
  description: "",
  language: null,
  sharingRights: null,
  fields: null,
  evidence: null,
};

const ObjectModelForm = ({
  open,
  onCancel,
  submitCallback,
  initialData,
  disabledFields,
}) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const [editObject] = useEditObjectMutation();
  const [createObject, createObjectResponse] = useCreateObjectMutation();
  const [step, setStep] = useState(0);
  const [schema, setSchema] = useState(objectSchema(t));
  const { data: currentUser, isSuccess: isCurrentUserSuccess } =
    useGetCurrentUserQuery();

  const form = useForm({
    resolver: yupResolver(yup.object().shape(schema)),
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    if (currentUser && currentUser.role === Roles.USER.value) {
      form.setValue("language", currentUser.organization.language);
    }
  }, [initialData, currentUser]);

  useEffect(() => {
    if (initialData) {
      form.reset({
        title: initialData.title,
        description: initialData.description,
        date: initialData.date,
        type: initialData.type,
        contributors: initialData.contributors,
        language: initialData.language,
        sharingRights: initialData.sharingRights,
        fields: initialData.fields,
        evidence: initialData.evidence && { file: initialData.evidence.file && { name: initialData.evidence.name }, urls: initialData.evidence.urls },
      });
    }
  }, [initialData]);

  const handleNext = async () => {
    const validationFields =
      step === 0
        ? [
            "date",
            "title",
            "contributors",
            "description",
            "language",
            "sharingRights",
          ]
        : undefined;
    const isValid = await form.trigger(validationFields);
    if (isValid) {
      if (step === 0) {
        setStep(1);
      } else {
        const formValues = {
          ...form.getValues(),
          organization: currentUser.organization.id,
        };

        let promise;
        if (initialData?.id) {
          promise = editObject({ id: initialData.id, data: formValues });
          if (!(formValues.evidence.file instanceof File)) {
            formValues.evidence.file = null;
          }
        } else {
          promise = createObject(formValues);
        }

        promise
          .unwrap()
          .then((data) => {
            notification.success({
              message: t(initialData?.id ? "objectEdited" : "objectCreated"),
            });
            handleCancel();
            submitCallback(data);
          })
          .catch((errors) => handleErrors(errors, form.setError, notification));
      }
    }
  };

  const ObjectFormFields = useCallback(() => {
    if (!isCurrentUserSuccess) return null;

    if (step === 0) {
      return (
        <ObjectDetailsFormFields
          form={form}
          organizationId={
            initialData ? initialData.organization : currentUser.organization.id
          }
        />
      );
    }
    return (
      <ObjectTypeFormFields
        form={form}
        setSchema={setSchema}
        disabledFields={disabledFields}
        initialData={initialData}
      />
    );
  }, [step]);

  const handleCancel = () => {
    setStep(0);
    form.reset(undefined);
    onCancel();
  };

  return (
    <Modal
      open={open}
      title={t("createNewObject")}
      onCancel={handleCancel}
      destroyOnClose
      footer={[
        <PrimaryButton key="cancel" onClick={handleCancel}>
          {t("buttons.cancel")}
        </PrimaryButton>,
        ...(step === 0
          ? []
          : [
              <PrimaryButton key="back" onClick={() => setStep(0)}>
                {t("buttons.back")}
              </PrimaryButton>,
            ]),
        <PrimaryButton
          key="confirm"
          disabled={step === 1 && createObjectResponse.isLoading}
          onClick={() => handleNext()}
        >
          {step === 0 ? t("buttons.next") : t("buttons.confirm")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <ObjectFormFields />
    </Modal>
  );
};

ObjectModelForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  disabledFields: PropTypes.array,
  submitCallback: PropTypes.func,
};

ObjectModelForm.defaultProps = {
  initialData: null,
  disabledFields: [],
  submitCallback: () => {},
};

export default ObjectModelForm;
