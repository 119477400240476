import { useEffect } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import { DateTime } from "luxon";
import _ from "lodash";

import {
  useGetLanguagesQuery,
  useLazyGetMembershipsQuery,
} from "../../../redux/service.js";
import { ObjectTypes } from "../../../utilities/constants.jsx";

const { Title, Text } = Typography;

const Evidence = ({ evidence }) => {
  // Handle PDF files
  if (evidence.contentType === "application/pdf" || evidence.pdfFile) {
    return (
      <embed
        src={
          evidence.contentType === "application/pdf"
            ? evidence.file
            : evidence.pdfFile
        }
        width="100%"
        height="600px"
      />
    );
  }

  if (evidence.contentType === "audio/mpeg") {
    return (
      <audio controls>
        <source src={evidence.file} type="audio/mpeg" />
        Your browser does not support the audio element.{" "}
        <a href={evidence.file} rel="noreferrer" target="_blank">
          Click here
        </a>{" "}
        to open it.
      </audio>
    );
  }

  if (evidence.contentType === "video/mp4") {
    return (
      <video width="640" height="360" controls>
        <source src={evidence.file} type="video/mp4" />
        Your browser does not support the video tag.{" "}
        <a href={evidence.file} rel="noreferrer" target="_blank">
          Click here
        </a>{" "}
        to open it.
      </video>
    );
  }

  // Handle images
  return <img src={evidence.file} style={{ width: "100%" }} />;
};

Evidence.propTypes = {
  evidence: PropTypes.object.isRequired,
};

export const ObjectSnippet = ({ object, showTitle }) => {
  const { t } = useTranslation();
  const { data: languages, isSuccess: isLanguagesSuccess } =
    useGetLanguagesQuery();
  const [getMemberships, membershipsResult] = useLazyGetMembershipsQuery();

  useEffect(() => {
    if (object?.organization) {
      getMemberships({ organization: object.organization, pagination: "off" });
    }
  }, [object]);

  return (
    <div>
      <Row>
        <Col xl={17} md={17} sm={24} className="pe-4">
          {showTitle && (
            <Title level={5}>
              <strong>{ObjectTypes.getItemByValue(object.type).label}</strong>
            </Title>
          )}
          <div className="mt-2">
            <Title level={5}>
              {t("labels.title")}:
              <Text style={{ fontWeight: "400" }} className="ms-2">
                {object.title}
              </Text>
            </Title>
          </div>
          <div className="mt-2">
            <Title level={5}>
              {t("labels.description")}:
              <Text style={{ fontWeight: "400" }} className="ms-2">
                {object.description}
              </Text>
            </Title>
          </div>
        </Col>
        <Col xl={7} md={7} sm={24} className="px-2">
          <div className="mt-2">
            <Title level={5}>
              {t("labels.date")}:
              <Text style={{ fontWeight: "400" }} className="ms-2">
                {DateTime.fromISO(object.date).toFormat("dd MMM yyyy")}
              </Text>
            </Title>
          </div>
          {isLanguagesSuccess && (
            <div className="mt-2">
              <Title level={5}>
                {t("labels.language")}:
                <Text style={{ fontWeight: "400" }} className="ms-2">
                  {languages.find((i) => i.value === object.language).label}
                </Text>
              </Title>
            </div>
          )}
          {object.contributors.length > 0 && (
            <div className="mt-2">
              <Title level={5}>
                {t("labels.contributors")}:
                {membershipsResult.isSuccess && (
                  <Text style={{ fontWeight: "400" }} className="ms-2">
                    {object.contributors
                      .map(
                        (contributor) =>
                          membershipsResult.data.find(
                            (member) => member.user.id === contributor,
                          )?.user.fullName,
                      )
                      .join(", ")}
                  </Text>
                )}
              </Title>
            </div>
          )}
          {Object.keys(object?.fields || {}).map((fieldName) => (
            <div key={fieldName} className="mt-2">
              <Title level={5}>
                {_.startCase(fieldName)}:
                <Text style={{ fontWeight: "400" }} className="ms-2">
                  {object.fields[fieldName]}
                </Text>
              </Title>
            </div>
          ))}
        </Col>
      </Row>
      <div>
        {object.evidence && (
          <div className="mt-4" style={{ margin: "auto", textAlign: "center" }}>
            <Evidence evidence={object.evidence} />
          </div>
        )}
      </div>
    </div>
  );
};

ObjectSnippet.propTypes = {
  object: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
};

ObjectSnippet.defaultProps = {
  showTitle: true,
};

export default ObjectSnippet;
