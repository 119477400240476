import { useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import PropTypes from "prop-types";
import createDOMPurify from "dompurify";

import PrimaryButton from "../../../components/PrimaryButton.jsx";
import FormField from "../../../components/form/FormField.jsx";
import Input from "../../../components/form/Input.jsx";

const chapterSchema = (t) =>
  yup.object().shape({
    title: yup
      .string()
      .typeError(t("form.validation.titleNotValid"))
      .required(t("form.validation.titleRequired")),
    text: yup
      .string()
      .typeError(t("form.validation.textNotValid"))
      .required(t("form.validation.textRequired")),
    id: yup.number().nullable(),
  });

const defaultValues = {
  id: null,
  title: null,
  text: null,
  object: null,
};

const ChapterFormModal = ({ open, onCancel, initialData, onSubmit }) => {
  const { t } = useTranslation();
  const isLoading = false;
  const DOMPurify = createDOMPurify(window);

  const form = useForm({
    resolver: yupResolver(chapterSchema(t)),
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    form.reset(initialData);
  }, [initialData]);

  const handleCancel = () => {
    onCancel();
    form.reset(defaultValues);
  };

  const handleSubmit = async (values) => {
    onSubmit({
      ...values,
      text: DOMPurify.sanitize(values.text, { USE_PROFILES: { html: true } }),
    });
    form.reset(defaultValues);
    handleCancel();
  };

  return (
    <Modal
      open={open}
      destroyOnClose
      title={initialData ? t("editChapter") : t("createChapter")}
      onCancel={handleCancel}
      width={1000}
      footer={[
        <PrimaryButton key="cancel" onClick={handleCancel}>
          Cancel
        </PrimaryButton>,
        <PrimaryButton
          key="confirm"
          disabled={isLoading}
          onClick={form.handleSubmit(handleSubmit)}
        >
          Confirm
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <FormField
        label={t("form.labels.title")}
        required
        field={
          <Input
            name="title"
            placeholder={t("form.placeholders.chapterTitle")}
            control={form.control}
          />
        }
      />
      <FormField
        label={t("form.labels.text")}
        required
        field={
          <Input
            name="text"
            type="richtexteditor"
            placeholder={t("form.placeholders.chapterText")}
            control={form.control}
          />
        }
      />
    </Modal>
  );
};

ChapterFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
};

ChapterFormModal.defaultProps = {
  currentChapter: null,
};

export default ChapterFormModal;
