import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Spin, theme, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";

import StatisticsBox from "./StatisticsBox.jsx";
import {
  useGetCommentsQuery,
  useLazyGetUsersQuery,
  useLazyPaginateFilterDiscussionsQuery,
} from "../../../redux/service.js";
import Table from "../../../components/Table.jsx";
import Select from "../../../components/form/Select.jsx";
import DiscussionBox from "../../community/components/DiscussionBox.jsx";
import Pagination from "../../../components/Pagination.jsx";

const { Title } = Typography;

const CommunityStatistics = () => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const navigate = useNavigate();

  const [
    paginateFilterDiscussions,
    {
      data: discussionsData,
      isSuccess: discussionsIsSuccess,
      isLoading: discussionsLoading,
    },
  ] = useLazyPaginateFilterDiscussionsQuery();
  const { data: commentsData, isSuccess: isCommentsSuccess } =
    useGetCommentsQuery();
  const [
    getUsers,
    { data: usersData, isSuccess, isUninitialized, isFetching },
  ] = useLazyGetUsersQuery();
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      timeUsers: "all",
      timeDiscussions: "all",
    },
  });
  const fields = useWatch({
    control: form.control,
  });

  useEffect(() => {
    handleTableChange({
      current: 1,
      pageSize: 10,
      ordering: "-engagement",
      time: fields.timeUsers,
    });
    paginateFilterDiscussions({
      page: 1,
      pageSize: 10,
      ordering: "-popular",
      time: fields.timeDiscussions,
    });
  }, [fields]);

  useEffect(() => {
    paginateFilterDiscussions({
      ...pagination,
      ordering: "-popular",
      time: fields.timeDiscussions,
    });
  }, [pagination]);

  const handleTableChange = (pagination, filters) => {
    getUsers({
      page: pagination.current,
      pageSize: pagination.pageSize,
      time: fields.timeUsers,
      ordering: "-engagement",
      ...filters,
    });
  };

  const columns = [
    {
      title: t("table.fullName"),
      dataIndex: "fullName",
      key: "fullName",
      width: 800,
      ellipsis: true,
      render: (_, record) => (
        <a
          style={{ color: token.linkColor }}
          onClick={() => navigate(`/profile/${record.id}`)}
        >
          {record.fullName}
        </a>
      ),
    },
    {
      title: t("table.discussionPosts"),
      dataIndex: "discussionsCount",
      key: "discussionsCount",
      ellipsis: true,
    },
    {
      title: t("table.comments"),
      dataIndex: "commentsCount",
      key: "commentsCount",
      ellipsis: true,
    },
  ];

  return (
    <div>
      <div style={{ display: "flex" }} className="mb-4">
        {discussionsIsSuccess && (
          <StatisticsBox
            label={t("discussionPosts")}
            statistic={discussionsData.count}
            image="/assets/discussion-posts.svg"
          />
        )}
        {isCommentsSuccess && (
          <StatisticsBox
            label={t("comments")}
            statistic={commentsData.count}
            className="ms-3"
            image="/assets/comments.svg"
          />
        )}
      </div>
      <div style={{ display: "flex" }} className="mb-2">
        <Title
          className="mt-3 mb-2 me-3"
          style={{ color: token.colorTextThird, alignContent: "center" }}
          level={3}
        >
          {t("mostEngagedUsers")}
        </Title>
        <div style={{ width: "130px", alignContent: "center" }}>
          <Select
            name="timeUsers"
            options={[
              { value: "day", label: t("today") },
              { value: "week", label: t("week") },
              { value: "month", label: t("month") },
              { value: "year", label: t("year") },
              { value: "all", label: t("allTime") },
            ]}
            placeholder={t("select")}
            control={form.control}
          />
        </div>
      </div>
      {isSuccess && (
        <Table
          data={usersData.results}
          totalCount={usersData.count}
          columns={columns}
          isLoading={isUninitialized || isFetching}
          handleTableChange={handleTableChange}
        />
      )}
      <div>
        <div style={{ display: "flex" }} className="mb-2">
          <Title
            className="mt-3 mb-2 me-3"
            style={{ color: token.colorTextThird, alignContent: "center" }}
            level={3}
          >
            {t("topDiscussions")}
          </Title>
          <div style={{ width: "130px", alignContent: "center" }}>
            <Select
              name="timeDiscussions"
              options={[
                { value: "day", label: t("today") },
                { value: "week", label: t("week") },
                { value: "month", label: t("month") },
                { value: "year", label: t("year") },
                { value: "all", label: t("allTime") },
              ]}
              placeholder={t("select")}
              control={form.control}
            />
          </div>
        </div>
        {discussionsIsSuccess && (
          <div>
            {discussionsData.results.map((i) => (
              <DiscussionBox discussion={i} key={i.id} />
            ))}
            {(discussionsData.next || discussionsData.previous) && (
              <Pagination
                data={discussionsData}
                pagination={pagination}
                onChange={(page, pageSize) => setPagination({ page, pageSize })}
              />
            )}
          </div>
        )}
        {discussionsLoading && (
          <div className="mt-3 d-flex justify-content-center align-items-center h-100">
            <Spin size="large" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunityStatistics;
