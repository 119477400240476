import Upload from "../../../components/form/Upload.jsx";
import { evidenceSupportedFileFormats } from "./ObjectFormHelpers.jsx";
import Input from "../../../components/form/Input.jsx";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const { Text } = Typography;

const EvidenceInputField = ({ form }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Upload
        name="evidence.file"
        type="dropzone"
        buttonTitle={t("buttons.uploadEvidence")}
        supportedFormats={evidenceSupportedFileFormats}
        control={form.control}
      />
      <div style={{ textAlign: "center", fontWeight: "bold" }} className="my-2">
        <Text style={{ color: "rgba(126, 128, 138, 1)" }}>OR</Text>
      </div>
      <Text style={{ color: "rgba(126, 128, 138, 1)" }}>
        If you want to use multiple urls, separate them with a comma
      </Text>
      <Input
        name="evidence.urls"
        control={form.control}
        placeholder="Enter URL"
      />
    </div>
  );
};

EvidenceInputField.propTypes = {
  form: PropTypes.object.isRequired,
};

export default EvidenceInputField;
