import { Breadcrumb, theme, Typography } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import PropTypes from "prop-types";

import Notifications from "./Notifications.jsx";
import Profile from "./Profile.jsx";

const { Title } = Typography;

const CustomHeader = ({ user }) => {
  const navigate = useNavigate();
  const breadcrumbs = useSelector((state) => state.utils.breadcrumbs);
  const { token } = theme.useToken();

  return (
    <div className="pt-4">
      <div className="px-5 mb-3 d-flex justify-content-end">
        <div
          className="me-5"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Notifications />
        </div>
        <div>{user && <Profile currentUser={user} />}</div>
      </div>
      <div
        style={{ backgroundColor: "rgba(198, 206, 220, 1)", height: "1px" }}
      />
      {breadcrumbs && (
        <Breadcrumb
          style={{
            padding: "11px 50px",
            color: token.colorTextThird,
            backgroundColor: "rgba(244, 244, 244, 1)",
          }}
          separator=">"
          items={breadcrumbs.map((i) => {
            const props = {
              href: i?.to ? "#" : null,
              onClick: i?.to ? () => navigate(i.to) : null,
            };

            return i?.to
              ? {
                  title: (
                    <Title level={5} style={{ color: token.colorTextThird }}>
                      {_.truncate(i.title, { length: 60 })}
                    </Title>
                  ),
                  ...props,
                }
              : {
                  ...i,
                  title: _.truncate(i.title, { length: 60 }),
                };
          })}
        />
      )}
    </div>
  );
};

CustomHeader.propTypes = {
  user: PropTypes.object.isRequired,
};

export default CustomHeader;
