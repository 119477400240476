import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Spin, Typography } from "antd";

import {
  useLazyGetNarrativeByIdQuery,
  useLazyGetObjectByIdQuery,
} from "../../../../redux/service.js";
import { OptionType } from "../../../../utilities/constants.jsx";
import createDOMPurify from "dompurify";

const { Text } = Typography;

const EntityDetailsTooltip = ({ entityId, entityType }) => {
  const { t } = useTranslation();
  const DOMPurify = createDOMPurify(window);

  const [getObjectById, { isLoading: isObjectLoading }] =
    useLazyGetObjectByIdQuery();
  const [getNarrativeById, { isLoading: isNarrativeLoading }] =
    useLazyGetNarrativeByIdQuery();
  const [entityData, setEntityData] = useState(null);

  useEffect(() => {
    if (entityType === OptionType.NARRATIVE.value) {
      getNarrativeById(entityId)
        .unwrap()
        .then((data) => setEntityData(data));
    } else {
      getObjectById(entityId)
        .unwrap()
        .then((data) => setEntityData(data));
    }
  }, [entityId]);

  const EntityComponent = useCallback(() => {
    if (!entityData) return null;

    if (entityType === OptionType.OBJECT.value) {
      return (
        <>
          <div>
            <Text style={{ fontWeight: "bold" }}>
              {t("labels.description")}:{" "}
            </Text>
            <Text>{entityData.description}</Text>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="mt-2">
            <Text style={{ fontWeight: "bold" }}>
              {t("labels.impactPurpose")}:{" "}
            </Text>
            <Text>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(entityData.impactPurpose, {
                    USE_PROFILES: { html: true },
                  }),
                }}
              />
            </Text>
          </div>
          <div className="mt-2">
            <Text style={{ fontWeight: "bold" }}>
              {t("labels.impactStatement")}:{" "}
            </Text>
            <Text>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(entityData.impactStatement, {
                    USE_PROFILES: { html: true },
                  }),
                }}
              />
            </Text>
          </div>
        </>
      );
    }
  }, [entityData]);

  if (isObjectLoading || isNarrativeLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 p-4">
        <Spin size="large" />
      </div>
    );
  }

  return (
    entityData && (
      <div
        style={{ border: "2px solid #7795C5", backgroundColor: "#E9F0FC" }}
        className="p-3"
      >
        <div>
          <Text style={{ fontWeight: "bold" }}>{t("labels.title")}: </Text>
          <Text>{entityData.title}</Text>
        </div>
        <EntityComponent />
      </div>
    )
  );
};

EntityDetailsTooltip.propTypes = {
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.string.isRequired,
};

export default EntityDetailsTooltip;
