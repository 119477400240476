import { useEffect, useMemo, useState } from "react";

import { Col, Divider, Pagination, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { DateTime } from "luxon";
import createDOMPurify from "dompurify";

import {
  useGetCountriesQuery,
  useGetCurrentUserQuery,
  useGetLanguagesQuery,
  useGetSubjectsQuery,
  useLazyGetOrganizationsQuery,
  useLazyPaginateFilterNarrativesQuery,
} from "../../redux/service.js";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import Select from "../../components/form/Select.jsx";
import { Roles } from "../../utilities/constants.jsx";
import NoResults from "../../components/NoResults.jsx";
import Tabs from "../../components/Tabs.jsx";
import DebouncedSelect from "../../components/form/DebouncedSelect.jsx";

const { Title, Text } = Typography;

const NarrativeFilters = ({ form, selectedTab }) => {
  const { t } = useTranslation();

  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();
  const { data: languages, isSuccess: isLanguagesSuccess } =
    useGetLanguagesQuery();
  const { data: subjectsResponse, isSuccess: subjectsIsSuccess } =
    useGetSubjectsQuery({ pagination: "off" });
  const [getOrganizations] = useLazyGetOrganizationsQuery();

  return (
    <div>
      <div>
        <Title
          level={4}
          style={{ color: "rgba(30, 50, 80, 1)", fontWeight: "bold" }}
        >
          {t("filterBy")}
        </Title>
        <Divider />
      </div>
      {selectedTab === "explore" && (
        <div className="mb-4">
          <Title style={{ fontWeight: "bold" }} className="mb-2" level={5}>
            {t("labels.organizations")}
          </Title>
          <DebouncedSelect
            name="organizations"
            mode="multiple"
            placeholder={t("form.placeholders.selectInstitutions", {
              plural: "s",
            })}
            filterName="name"
            labelName="name"
            trigger={getOrganizations}
            control={form.control}
          />
        </div>
      )}
      <div className="mb-4">
        <Title style={{ fontWeight: "bold" }} className="mb-2" level={5}>
          {t("labels.country")}
        </Title>
        <Select
          name="country"
          options={isCountriesSuccess ? countries : []}
          placeholder={t("form.placeholders.countrySelect")}
          control={form.control}
        />
      </div>
      <div className="mb-4">
        <Title style={{ fontWeight: "bold" }} className="mb-2" level={5}>
          {t("labels.language")}
        </Title>
        <Select
          name="language"
          options={isLanguagesSuccess ? languages : []}
          placeholder={t("form.placeholders.language")}
          control={form.control}
        />
      </div>
      <div>
        <Title level={5}>{t("labels.subjects")}</Title>
        <Select
          mode="tags"
          name="subjects"
          options={
            subjectsIsSuccess
              ? subjectsResponse.map((i) => ({
                  value: i.name,
                  label: i.name,
                }))
              : []
          }
          placeholder={t("form.placeholders.subjectSelect")}
          control={form.control}
        />
      </div>
      <div className="mt-4">
        <PrimaryButton onClick={() => form.reset()}>
          Reset filters
        </PrimaryButton>
      </div>
    </div>
  );
};

NarrativeFilters.propTypes = {
  form: PropTypes.object.isRequired,
  selectedTab: PropTypes.string.isRequired,
};

const NarrativeBox = ({ narrative }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const DOMPurify = createDOMPurify(window);

  return (
    <div>
      <Divider />
      <div className="mt-3">
        <a
          onClick={() => navigate(`/narratives/${narrative.id}`)}
          style={{ display: "inline-block" }}
        >
          <Title
            style={{
              fontWeight: "bold",
              color: "rgba(30, 50, 80, 1)",
              textDecoration: "underline",
            }}
            level={5}
          >
            {narrative.title}
          </Title>
        </a>
        <div className="mt-2">
          <Text style={{ color: "rgba(96, 100, 108, 1)" }}>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(narrative.impactPurpose, {
                  USE_PROFILES: { html: true },
                }),
              }}
            />
          </Text>
        </div>
      </div>
      <div className="mt-2">
        <Row>
          <Col xl={8} md={8} sm={4}>
            <Text className="narrative-details-label">
              <span style={{ fontWeight: "bold" }}>
                {t("form.labels.institution")}
              </span>
              : {narrative.organization.name}
            </Text>
          </Col>
          {narrative.isPublished && (
            <Col xl={8} md={8} sm={4}>
              <Text className="narrative-details-label">
                <span style={{ fontWeight: "bold" }}>
                  {t("form.labels.datePublished")}
                </span>
                :{" "}
                {DateTime.fromISO(narrative.publishedDate).toFormat(
                  "dd/MM/yyyy",
                )}
              </Text>
            </Col>
          )}
        </Row>
        <Row>
          <Col xl={8} md={8} sm={4}>
            <Text className="narrative-details-label">
              <span style={{ fontWeight: "bold" }}>
                {t("form.labels.authors")}
              </span>
              : {narrative.authors.map((author) => author.fullName).join(", ")}
            </Text>
          </Col>
          <Col xl={8} md={8} sm={4}>
            <Text className="narrative-details-label">
              <span style={{ fontWeight: "bold" }}>
                {t("form.labels.objects")}
              </span>
              : {narrative.objectsCount}
            </Text>
          </Col>
        </Row>
      </div>
    </div>
  );
};

NarrativeBox.propTypes = {
  narrative: PropTypes.object.isRequired,
};

const ListNarratives = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(
    searchParams.has("tab") ? searchParams.get("tab") : "published",
  );
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const { data: currentUser, isSuccess: isCurrentUserSuccess } =
    useGetCurrentUserQuery();
  const [paginateAndFilterNarratives, narrativesResponse] =
    useLazyPaginateFilterNarrativesQuery();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      ordering: "-published_date",
      subjects: [],
      country: null,
      language: null,
      is_published: null,
      organizations: null,
      exclude_organizations: null,
    },
  });

  const handleFiltersChange = (data) => {
    const filters = {};
    Object.keys(data).forEach((key) => {
      if (![null, undefined].includes(data[key])) {
        filters[key] = data[key];
      }
    });

    paginateAndFilterNarratives({ ...pagination, ...filters });
  };
  const filtersWatch = form.watch(handleFiltersChange);

  const getFiltersForTab = (tabName) => {
    let data = {};
    if (currentUser.role === Roles.USER.value) {
      data = {
        is_published: ["published", "explore"].includes(tabName),
        exclude_organizations: ["draft", "published"].includes(tabName)
          ? null
          : currentUser.organization.id,
        organizations: ["draft", "published"].includes(tabName)
          ? [currentUser.organization.id]
          : null,
      };
    }
    return data;
  };

  useEffect(() => {
    if (isCurrentUserSuccess) {
      if (currentUser.role === Roles.ADMIN.value) {
        setSelectedTab("explore");
      }

      const filters = getFiltersForTab(selectedTab);
      form.reset(
        (prevValues) =>
          pagination.page === 0
            ? { ...filters, ...prevValues }
            : { ...prevValues, ...filters },
        { keepDefaultValues: true },
      );
    }

    return () => filtersWatch.unsubscribe();
  }, [pagination, selectedTab, isCurrentUserSuccess]);

  const narrativesData = useMemo(() => {
    if (narrativesResponse.isFetching) {
      return (
        <div className="mt-3 d-flex justify-content-center align-items-center h-100">
          <Spin size="large" />
        </div>
      );
    }
    if (narrativesResponse.isSuccess) {
      if (narrativesResponse.data.results.length === 0) {
        return <NoResults />;
      } else {
        return (
          <div className="narratives-list">
            {narrativesResponse.data.results.map((narrative) => (
              <NarrativeBox key={narrative.id} narrative={narrative} />
            ))}
            <Pagination
              rootClassName="mt-4 mb-3"
              style={{ justifyContent: "center" }}
              defaultCurrent={1}
              total={narrativesResponse.data.count}
              pageSize={pagination.pageSize}
              page={pagination.page}
              onChange={(page, pageSize) => setPagination({ page, pageSize })}
            />
          </div>
        );
      }
    }
    return null;
  }, [narrativesResponse]);

  return (
    <div>
      <Row>
        <Col xl={4} md={8} sm={12}>
          <Title
            level={2}
            style={{ fontWeight: "bold", color: "rgba(30, 50, 80, 1)" }}
          >
            {t("form.labels.narratives")}
          </Title>
        </Col>
        <Col xl={17} md={13} sm={12}>
          <Tabs
            type="card"
            onChangeCallback={(tabName) => {
              if (tabName === "create") return;
              setSelectedTab(tabName);
              setPagination((oldValue) => ({ ...oldValue, page: 1 }));
            }}
            items={[
              ...(isCurrentUserSuccess && currentUser.role === Roles.USER.value
                ? [
                    {
                      label: (
                        <PrimaryButton
                          onClick={() => navigate("/narratives/form")}
                        >
                          {t("labels.createNarrative")}
                        </PrimaryButton>
                      ),
                      key: "create",
                      skip: true,
                    },
                    {
                      label: t("form.labels.published"),
                      key: "published",
                    },
                    {
                      label: t("form.labels.draft"),
                      key: "draft",
                    },
                  ]
                : []),
              {
                label: t("form.labels.exploreOthers"),
                key: "explore",
              },
            ]}
          />
        </Col>
      </Row>
      <div className="mt-4">
        <Row>
          <Col xl={17} md={17} sm={12} className="pe-5">
            <Row>
              <Col xl={12} md={12} sm={12} />
              <Col xl={12} md={12} sm={12}>
                <div style={{ width: "30%" }} className="ms-auto">
                  <Select
                    name="ordering"
                    control={form.control}
                    options={[
                      { label: t("newest"), value: "-published_date" },
                      { label: t("oldest"), value: "published_date" },
                      { label: t("mostRead"), value: "popular" },
                    ]}
                  />
                </div>
              </Col>
            </Row>
            {narrativesData}
          </Col>
          <Col
            xl={7}
            md={7}
            sm={12}
            className="ps-4 pe-5 pt-3"
            style={{ borderLeft: "1px solid silver" }}
          >
            <NarrativeFilters form={form} selectedTab={selectedTab} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ListNarratives;
