import { useParams } from "react-router-dom";

import NarrativeForm from "./components/NarrativeForm.jsx";
import {
  useGetNarrativeByIdQuery,
  useUpdateNarrativeMutation,
} from "../../redux/service.js";

const UpdateNarrativeForm = () => {
  const { narrativeId } = useParams();
  const { data: narrative, isSuccess } = useGetNarrativeByIdQuery(narrativeId);
  const [updateNarrative] = useUpdateNarrativeMutation();

  const saveNarrative = (values) =>
    updateNarrative({ narrative: narrative.id, data: values }).unwrap();

  return (
    isSuccess && (
      <NarrativeForm
        narrative={{
          ...narrative,
          applicableDateRange: [narrative.startDate, narrative.endDate],
        }}
        onSubmit={saveNarrative}
      />
    )
  );
};

export default UpdateNarrativeForm;
