import { createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import { isUserAuthenticated } from "../../utilities/index.js";

const initialState = {
  loading: false,
  isAuthenticated: isUserAuthenticated(),
  user: null,
  isFirstLogin: false,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setIsAuthenticated: (state, { payload }) => {
      Sentry.setUser(payload.isAuthenticated ? payload.user : null);
      state.isAuthenticated = payload.isAuthenticated;
      state.user = payload?.user;
      state.isFirstLogin = payload?.isFirstLogin;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    },
    setIsFirstLogin: (state, { payload }) => {
      state.isFirstLogin = payload;
    }
  },
});

export const { setIsAuthenticated, logout, setIsFirstLogin } = authenticationSlice.actions;
export default authenticationSlice.reducer;
