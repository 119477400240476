import { useEffect, useMemo } from "react";

import { theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Select from "../../../components/form/Select.jsx";
import { ObjectTypes } from "../../../utilities/constants.jsx";
import EvidenceInputField from "./EvidenceInputField.jsx";
import FormField from "../../../components/form/FormField.jsx";
import { getFieldsForAllTypes } from "./ObjectFormHelpers.jsx";

const { Title, Text } = Typography;

const ObjectTypeFormFields = ({
  form,
  setSchema,
  disabledFields,
  initialData,
}) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const type = form.watch("type");
  const fieldsPerType = getFieldsForAllTypes(form, t);

  useEffect(() => {
    if (type) {
      setSchema((oldVal) => ({
        ...oldVal,
        fields: fieldsPerType[type].schema,
      }));
      if (initialData?.id && type === initialData?.type) {
        form.setValue("fields", initialData?.fields);
      } else {
        form.setValue("fields", {});
      }
    }
  }, [type]);

  const objectTypeFields = useMemo(() => {
    const fields = [
      {
        label: t("form.labels.type"),
        required: true,
        tooltipContent: (
          <div className="p-2">
            <Title
              className="mb-2"
              style={{ color: token.colorTextThird }}
              level={4}
            >
              {t("labels.typesOfObjects")}
            </Title>
            {[
              "statement",
              "policy",
              "learningObject",
              "event",
              "publicMedia",
            ].map((i) => (
              <div key={i} className="mb-2">
                <Title
                  className="mb-1"
                  style={{ color: token.colorTextThird }}
                  level={5}
                >
                  {t(`labels.${i}`)}
                </Title>
                <Text>{t(`${i}HelpText`)}</Text>
              </div>
            ))}
          </div>
        ),
        field: (
          <Select
            name="type"
            disabled={(disabledFields || []).includes("type")}
            placeholder={t("form.placeholders.type")}
            control={form.control}
            options={ObjectTypes.asList()}
          />
        ),
      },
    ];

    if (type) {
      fields.push(...fieldsPerType[type].fields);
    }

    fields.push({
      label: t("form.labels.evidence"),
      field: <EvidenceInputField form={form} />,
    });

    return fields;
  }, [type]);

  return objectTypeFields.map((i) => <FormField key={i.label} {...i} />);
};

ObjectTypeFormFields.propTypes = {
  form: PropTypes.object.isRequired,
  setSchema: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  disabledFields: PropTypes.array,
};

ObjectTypeFormFields.defaultProps = {
  initialData: null,
  disabledFields: [],
};

export default ObjectTypeFormFields;
