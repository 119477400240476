import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import createDOMPurify from "dompurify";

import Tabs from "../../../../components/Tabs.jsx";
import SurveyForm from "../../components/active-survey-form/SurveyForm.jsx";
import { SurveyStatus } from "../../../../utilities/constants.jsx";
import SurveySharingRights from "../survey-views/SurveySharingRights.jsx";
import SurveyView from "../survey-views/SurveyView.jsx";

const SingleSurveyUser = ({ survey }) => {
  const { t } = useTranslation();
  const DOMPurify = createDOMPurify(window);

  return (
    <div>
      <Tabs
        type="card"
        items={[
          ...(survey.status === SurveyStatus.ACTIVE.value &&
          !survey.details?.isSubmitted
            ? [
                {
                  label: t("reportSubmission"),
                  key: "report",
                  children: <SurveyForm survey={survey} />,
                },
              ]
            : [
                {
                  label: t("reportView"),
                  key: "report",
                  children: <SurveyView />,
                },
              ]),
          {
            label: t("privacySettings"),
            key: "privacy",
            children: <SurveySharingRights survey={survey} />,
          },
          {
            label: t("about"),
            key: "about",
            children: (
              <div
                className="w-75"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(survey.information, {
                    USE_PROFILES: { html: true },
                  }),
                }}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

SingleSurveyUser.propTypes = {
  survey: PropTypes.object.isRequired,
};

export default SingleSurveyUser;
