import { createLogger } from "redux-logger/src";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./users/reducer";
import utilsReducer from "./utils/reducer";
import { commonsApi } from "./service.js";

const logger = createLogger();

const combinedReducer = combineReducers({
  auth: authReducer,
  utils: utilsReducer,
  [commonsApi.reducerPath]: commonsApi.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "authentication/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    let middlewares = getDefaultMiddleware();
    middlewares = middlewares.concat(commonsApi.middleware);
    if (import.meta.env.DEV) {
      middlewares = middlewares.concat(logger);
    }
    return middlewares;
  },
});

export default store;
