import { useEffect, useMemo } from "react";

import { App, Col, Divider, Modal, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

import PrimaryButton from "../../components/PrimaryButton.jsx";
import { ObjectTypes, OrganizationRoles } from "../../utilities/constants.jsx";
import {
  useDeleteObjectMutation,
  useGetCurrentUserQuery,
  useGetLanguagesQuery,
  useLazyGetMembershipsQuery,
} from "../../redux/service.js";

const { Title, Text } = Typography;

const ObjectOverviewModal = ({ object, open, onCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [deleteObjectById] = useDeleteObjectMutation();
  const { notification } = App.useApp();
  const { data: languages, isSuccess: isLanguagesSuccess } =
    useGetLanguagesQuery();
  const [getMemberships, membershipsResult] = useLazyGetMembershipsQuery();

  useEffect(() => {
    if (object) {
      getMemberships({ organization: object.organization, pagination: "off" });
    }
  }, [object]);

  const handleDelete = () => {
    deleteObjectById(object.id)
      .unwrap()
      .then(() => {
        notification.success({ message: t("deleteObject") });
        onCancel();
      });
  };

  const isUserAdminOrSupervisorOrCreator = () => {
    return (
      [
        OrganizationRoles.ADMIN.value,
        OrganizationRoles.SUPERVISOR.value,
      ].includes(currentUser?.organizationRole) ||
      currentUser.id === object.createdBy.id
    );
  };

  const canEditObject = useMemo(() => {
    if (currentUser && object) {
      return (
        (isUserAdminOrSupervisorOrCreator() ||
          object.contributors.includes(currentUser.id)) &&
        object.organization === currentUser.organization.id
      );
    }
    return false;
  }, [currentUser, object]);

  const canDeleteObject = useMemo(() => {
    if (currentUser && object) {
      return (
        isUserAdminOrSupervisorOrCreator() &&
        object.organization === currentUser.organization.id
      );
    }
    return false;
  }, [currentUser, object]);

  return object && currentUser ? (
    <Modal
      open={open}
      onCancel={onCancel}
      title={t("objectDetails")}
      footer={[
        ...(canDeleteObject
          ? [
              <PrimaryButton
                key="delete"
                onClick={handleDelete}
                type="secondary"
              >
                {t("buttons.delete")}
              </PrimaryButton>,
            ]
          : []),
        ...(canEditObject
          ? [
              <PrimaryButton
                key="edit"
                onClick={() => {
                  navigate(`/objects/${object.id}`, {
                    state: { isEdit: true },
                  });
                }}
                type="secondary"
              >
                {t("buttons.edit")}
              </PrimaryButton>,
            ]
          : []),
        <PrimaryButton
          key="confirm"
          onClick={() => {
            navigate(`/objects/${object.id}`);
          }}
        >
          {t("buttons.expand")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <div className="mb-2">
        <Title level={5} style={{ fontWeight: "bold" }} className="mb-1">
          {t("form.labels.title")}
        </Title>
        <Text>{object.title}</Text>
      </div>
      <div className="mb-2">
        <Title level={5} style={{ fontWeight: "bold" }} className="mb-1">
          {t("form.labels.description")}
        </Title>
        <Text>{object.description}</Text>
      </div>
      <Row>
        <Col span={12} className="mb-2">
          <div>
            <Title level={5} style={{ fontWeight: "bold" }} className="mb-1">
              {t("form.labels.typeObject")}
            </Title>
            <Text>{ObjectTypes.getItemByValue(object.type).label}</Text>
          </div>
        </Col>
        <Col span={12}>
          <div>
            <Title level={5} style={{ fontWeight: "bold" }} className="mb-1">
              {t("form.labels.contributors")}
            </Title>
            {membershipsResult.isSuccess && (
              <Text>
                {membershipsResult.data
                  .filter((i) => object.contributors.includes(i.user.id))
                  .map((i) => i.user.fullName)
                  .join(", ")}
              </Text>
            )}
          </div>
        </Col>
        <Col span={12}>
          <div>
            <Title level={5} style={{ fontWeight: "bold" }} className="mb-1">
              {t("form.labels.language")}
            </Title>
            <Text>
              {isLanguagesSuccess &&
                languages.filter((i) => i.value === object.language)[0]?.label}
            </Text>
          </div>
        </Col>
        <Col span={12}>
          <div>
            <Title level={5} style={{ fontWeight: "bold" }} className="mb-1">
              {t("form.labels.type")}
            </Title>
            <Text>{ObjectTypes.getLabel(object.type)}</Text>
          </div>
        </Col>
      </Row>
      <div>
        <Title level={5} style={{ fontWeight: "bold" }} className="mb-1">
          {t("form.labels.date")}
        </Title>
        <Text>{DateTime.fromISO(object.date).toFormat("dd MMM yyyy")}</Text>
      </div>
      {object.evidence && (
        <div>
          <Divider className="mt-3 mb-2" />
          <Title level={5} className="mb-2">
            {t("form.labels.evidence")}
          </Title>
          {object.evidence.file && (
            <div>
              <Row>
                <Col span={18}>
                  <Title level={5}>
                    <a
                      href={object.evidence.file}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {object.evidence.name}
                    </a>
                  </Title>
                </Col>
                <Col span={6}>
                  <PrimaryButton
                    file={{
                      url: object.evidence.fileDownloadUrl,
                      name: object.evidence.name,
                    }}
                  >
                    {t("buttons.download")}
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          )}
          {object.evidence.urls && (
            <div>
              <Text>{object.evidence.urls}</Text>
            </div>
          )}
        </div>
      )}
    </Modal>
  ) : null;
};

ObjectOverviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    contributors: PropTypes.array.isRequired,
    date: PropTypes.string.isRequired,
    organization: PropTypes.number.isRequired,
    createdBy: PropTypes.object.isRequired,
    evidence: PropTypes.shape({
      file: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      fileDownloadUrl: PropTypes.string.isRequired,
      urls: PropTypes.string.isRequired,
    }),
    language: PropTypes.string.isRequired,
  }),
};

ObjectOverviewModal.defaultProps = {
  object: {
    evidence: null,
  },
};

export default ObjectOverviewModal;
