import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import createDOMPurify from "dompurify";
import { Col, Divider, Row, theme, Typography } from "antd";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

import { SharingRights } from "../../../utilities/constants.jsx";
import {
  useGetCountriesQuery,
  useGetLanguagesQuery,
  useLazyGetMembershipsQuery,
} from "../../../redux/service.js";
import ObjectSnippet from "../../objects/components/ObjectSnippet.jsx";

const { Title, Text } = Typography;

const NarrativeSnippet = ({ narrative, showTitle }) => {
  const { t } = useTranslation();
  const DOMPurify = createDOMPurify(window);
  const { token } = theme.useToken();

  const { data: languages, isSuccess: isLanguagesSuccess } =
    useGetLanguagesQuery();
  const [getMemberships, membershipsResult] = useLazyGetMembershipsQuery();
  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();

  useEffect(() => {
    if (narrative?.organization) {
      getMemberships({
        organization: narrative.organization,
        pagination: "off",
      });
    }
  }, [narrative]);

  return (
    <Row className="py-4 px-4 narrative-snippet">
      <Col xl={16} md={16} sm={24} className="pe-3">
        {showTitle && (
          <Title level={4} style={{ color: token.colorTextThird }}>
            {narrative.title}
          </Title>
        )}
        <div className="mt-2">
          <Title level={5}>{t("labels.impactPurpose")}</Title>
          <Text>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(narrative.impactPurpose, {
                  USE_PROFILES: { html: true },
                }),
              }}
            />
          </Text>
        </div>
        <div className="mt-2">
          <Title level={5}>{t("labels.impactStatement")}</Title>
          <Text>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(narrative.impactStatement, {
                  USE_PROFILES: { html: true },
                }),
              }}
            />
          </Text>
        </div>
        {narrative.chapters.map((chapter, index) => (
          <div key={chapter.id} className="mt-2">
            <Divider />
            <Title level={5} style={{ textTransform: "uppercase" }}>
              {t("labels.chapter")} {index}
            </Title>
            <div className="mt-2">
              <Title level={4}>{chapter.title}</Title>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(chapter.text, {
                    USE_PROFILES: { html: true },
                  }),
                }}
              />
            </div>
            <div className="mt-2">
              {(chapter?.attachedObjects || []).map((object) => (
                <div
                  key={object.id}
                  className="mt-4 px-4 py-4"
                  style={{
                    backgroundColor: "rgba(242, 242, 245, 1)",
                    borderTop: "2px solid rgba(185, 187, 198, 1)",
                  }}
                >
                  <ObjectSnippet key={object.id} object={object} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </Col>
      <Col xl={8} md={8} sm={24} className="px-4">
        <div className="mt-2">
          <Title level={5}>
            {t("labels.applicableDateRange")}:
            <Text style={{ fontWeight: "400" }} className="ms-2">
              {DateTime.fromISO(narrative.startDate).toFormat("dd MMM yyyy")} -{" "}
              {DateTime.fromISO(narrative.endDate).toFormat("dd MMM yyyy")}
            </Text>
          </Title>
        </div>
        {isLanguagesSuccess && (
          <div className="mt-2">
            <Title level={5}>
              {t("labels.language")}:
              <Text style={{ fontWeight: "400" }} className="ms-2">
                {languages.find((i) => i.value === narrative.language).label}
              </Text>
            </Title>
          </div>
        )}
        {isCountriesSuccess && (
          <div className="mt-2">
            <Title level={5}>
              {t("labels.country")}:
              <Text style={{ fontWeight: "400" }} className="ms-2">
                {countries.find((i) => i.value === narrative.country).label}
              </Text>
            </Title>
          </div>
        )}
        <div className="mt-2">
          <Title level={5}>
            {t("labels.sharingRights")}:
            <Text style={{ fontWeight: "400" }} className="ms-2">
              {SharingRights.getItemByValue(narrative.sharingRights)?.label}
            </Text>
          </Title>
        </div>
        <div className="mt-2">
          <Title level={5}>
            {t("labels.subjects")}:
            <Text style={{ fontWeight: "400" }} className="ms-2">
              {narrative.subjects.map((i) => i.name).join(", ")}
            </Text>
          </Title>
        </div>
        <div className="mt-2">
          <Title level={5}>
            {t("labels.owner")}:
            <Text style={{ fontWeight: "400" }} className="ms-2">
              {narrative.createdBy.fullName}
            </Text>
          </Title>
        </div>
        {narrative.contributors.length > 0 && (
          <div className="mt-2">
            <Title level={5}>
              {t("labels.contributors")}:
              {membershipsResult.isSuccess && (
                <Text style={{ fontWeight: "400" }} className="ms-2">
                  {narrative.contributors
                    .map(
                      (contributor) =>
                        membershipsResult.data.find(
                          (member) => member.user.id === contributor,
                        )?.user.fullName,
                    )
                    .join(", ")}
                </Text>
              )}
            </Title>
          </div>
        )}
      </Col>
    </Row>
  );
};

NarrativeSnippet.propTypes = {
  narrative: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
};

NarrativeSnippet.defaultProps = {
  showTitle: false,
};

export default NarrativeSnippet;
