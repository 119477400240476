import { useEffect, useMemo, useState } from "react";

import { Card, Col, Row, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import dayjs from "dayjs";

import {
  useGetCurrentUserQuery,
  useLazyGetSurveyStatisticsQuery,
} from "../../../../redux/service.js";
import PrimaryButton from "../../../../components/PrimaryButton.jsx";
import PrincipleStatistics from "./PrincipleStatistics.jsx";
import {
  PrincipleStatus,
  Roles,
  SurveyActions,
  SurveyStatus,
} from "../../../../utilities/constants.jsx";
import Tag from "../../../../components/Tag.jsx";
import SubmissionConfirmationModal from "./SubmissionConfirmationModal.jsx";
import { canUserPerformActionOnSurvey } from "../../../../utilities/permissions.js";

const { Title, Text } = Typography;

const PrincipleCard = ({ principle }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();

  const principleStatus = useMemo(() => {
    if (principle.answeredQuestionsCount === 0) {
      return PrincipleStatus.NOT_STARTED.label;
    } else if (
      principle.answeredQuestionsCount >= principle.requiredQuestionsCount
    ) {
      return PrincipleStatus.FINISHED.label;
    } else {
      return PrincipleStatus.IN_PROGRESS.label;
    }
  }, [principle]);

  return (
    <Card
      style={{
        borderRadius: 0,
        boxShadow: "0px 8px 40px 0px rgba(0, 0, 61, 0.051)",
        height: "100%",
      }}
      cover={
        <div
          style={{ textAlign: "center", backgroundColor: principle.color }}
          className="py-5"
        >
          {principle.icon ? (
            <img height="90px" src={principle.icon} alt="icon for principle" />
          ) : (
            <div
              style={{
                height: "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Title level={4}>{principle.title}</Title>
            </div>
          )}
        </div>
      }
    >
      <Title level={3} className="mb-3" style={{ color: token.colorTextThird }}>
        {principle.title}
      </Title>
      <Row>
        <PrincipleStatistics principle={principle} />
      </Row>
      <div style={{ marginTop: "45px" }}>
        <div
          style={{
            display: "flex",
            bottom: "25px",
            position: "absolute",
            right: "20px",
          }}
        >
          <PrimaryButton
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(
                `/reports/${principle.survey}/principle/${principle.id}`,
              );
            }}
          >
            {principleStatus} <ArrowRightOutlined />
          </PrimaryButton>
        </div>
      </div>
    </Card>
  );
};

PrincipleCard.propTypes = {
  principle: PropTypes.shape({
    id: PropTypes.number.isRequired,
    icon: PropTypes.string,
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    survey: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    requiredQuestionsCount: PropTypes.number.isRequired,
    answeredQuestionsCount: PropTypes.number.isRequired,
    requiredObjectsCount: PropTypes.number.isRequired,
    answeredObjectsCount: PropTypes.number.isRequired,
    requiredNarrativesCount: PropTypes.number.isRequired,
    answeredNarrativesCount: PropTypes.number.isRequired,
  }).isRequired,
};

const SurveyForm = ({ survey }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const navigate = useNavigate();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [getSurveyStatics, surveyStatisticsResponse] =
    useLazyGetSurveyStatisticsQuery();
  const { data: currentUser, isSuccess: currentUserQuerySuccess } =
    useGetCurrentUserQuery();
  const surveyStatus = SurveyStatus.getItemByValue(survey.status);

  useEffect(() => {
    if (currentUserQuerySuccess && currentUser.role === Roles.ADMIN.value) {
      navigate("/");
    }
  }, [currentUserQuerySuccess]);

  useEffect(() => {
    if (
      survey.status === SurveyStatus.ENDED.value ||
      survey.details?.isSubmitted
    ) {
      navigate(`/reports/${survey.id}/report/`);
    }

    getSurveyStatics({ survey: survey.id });
  }, [survey]);

  const daysLeft = useMemo(() => {
    const date = survey?.softDeadline || survey?.hardDeadline;
    return Math.floor(DateTime.fromISO(date).diff(DateTime.now(), "days").days);
  }, [survey]);

  const handlePreview = () => {
    navigate(`/reports/${survey.id}/report`);
  };

  return (
    surveyStatisticsResponse.isSuccess && (
      <div
        style={{ backgroundColor: "rgba(243, 243, 247, 1)", margin: "auto" }}
        className="py-2 px-3"
      >
        <Row>
          <Col xl={12} md={12} sm={24} style={{ display: "flex" }}>
            <Title level={3} style={{ color: token.colorTextThird }}>
              {survey.title}
            </Title>
            <div className="ms-3">
              <Tag
                label={surveyStatus.label}
                color={surveyStatus.background}
                className="px-3"
                style={{ color: surveyStatus.color, fontWeight: "bold" }}
              />
            </div>
          </Col>
          <Col xl={12} md={12} sm={24} style={{ textAlign: "right" }}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Title
                level={5}
                style={{ color: token.colorTextThird }}
                className="me-4"
              >
                {t("reportingPeriod")}
              </Title>
              {daysLeft !== null && daysLeft >= 0 ? (
                <Text
                  style={{
                    fontWeight: "500",
                    color: "#7E808A",
                    textTransform: "uppercase",
                  }}
                >
                  {daysLeft === 0
                    ? t("lastDayForSurvey")
                    : `${daysLeft} ${t("daysLeft", { plural: daysLeft === 1 ? "" : "s" })}`}
                </Text>
              ) : null}
            </div>
            <Title level={5} style={{ color: "#7E808A", fontWeight: 400 }}>
              <span className="me-2">
                {dayjs(survey.startDate).format("DD MMM YYYY HH:mm")}
              </span>
              -
              <span className="ms-2">
                {dayjs(survey?.softDeadline || survey.hardDeadline).format(
                  "DD MMM YYYY HH:mm",
                )}
              </span>
            </Title>
          </Col>
        </Row>
        <div style={{ display: "flex" }}>
          <PrimaryButton
            className="me-3"
            type="secondary"
            onClick={handlePreview}
          >
            {t("previewSurvey")}
          </PrimaryButton>
          {canUserPerformActionOnSurvey(
            survey.permissions,
            currentUser.organizationRole,
            SurveyActions.SUBMIT.value,
          ) && (
            <PrimaryButton onClick={() => setOpenConfirmationModal(true)}>
              {t("submitSurvey")}
            </PrimaryButton>
          )}
        </div>
        <Row className="mt-4" gutter={40}>
          {surveyStatisticsResponse.data
            .slice()
            .sort((a, b) => a.index - b.index)
            .map((principle) => (
              <Col
                key={principle.title}
                xl={8}
                lg={8}
                md={8}
                sm={24}
                className="mb-4"
              >
                <PrincipleCard principle={principle} />
              </Col>
            ))}
        </Row>
        <SubmissionConfirmationModal
          onCancel={() => setOpenConfirmationModal(false)}
          survey={survey}
          open={openConfirmationModal}
        />
      </div>
    )
  );
};

SurveyForm.propTypes = {
  survey: PropTypes.object.isRequired,
};

export default SurveyForm;
