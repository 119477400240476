import PropTypes from "prop-types";

import Tag from "../../../components/Tag.jsx";

const CommunicatingStatus = ({ status }) =>
  status && (
    <div style={{ alignContent: "center" }}>
      <Tag
        label={status.label}
        color={status.backgroundColor}
        style={{ fontWeight: "bold", color: status.color }}
      />
    </div>
  );

CommunicatingStatus.propTypes = {
  status: PropTypes.string,
};

CommunicatingStatus.defaultProps = {
  status: null,
};

export default CommunicatingStatus;
