import { useEffect } from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { App, Modal, theme, Typography } from "antd";
import PropTypes from "prop-types";

import Input from "../../../components/form/Input.jsx";
import Select from "../../../components/form/Select.jsx";
import { handleErrors } from "../../../utilities/index.js";
import {
  useDeleteInviteByIdMutation,
  useInviteUserMutation,
  usePatchInviteByIdMutation,
} from "../../../redux/service.js";
import PrimaryButton from "../../../components/PrimaryButton.jsx";
import FormField from "../../../components/form/FormField.jsx";
import { OrganizationRoles, Roles } from "../../../utilities/constants.jsx";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const InviteFormModal = ({
  open,
  currentUser,
  isDeleteOperation,
  inviteForEdit,
  onCancel,
  organizationId,
}) => {
  const [inviteUser, { isLoading }] = useInviteUserMutation();
  const { notification } = App.useApp();
  const [deleteInviteById] = useDeleteInviteByIdMutation();
  const [patchInviteById] = usePatchInviteByIdMutation();
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const inviteSchema = yup.object().shape({
    email: yup
      .string()
      .typeError(t("form.validation.emailNotValid"))
      .required(t("form.validation.enterValidEmail")),
    role: yup
      .string()
      .typeError(t("form.validation.roleNotValid"))
      .required(t("form.validation.roleRequired")),
  });

  const form = useForm({
    resolver: yupResolver(inviteSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
      role: "",
    },
  });

  const fields = [
    {
      label: "Email",
      required: true,
      field: (
        <Input
          name="email"
          disabled={isDeleteOperation || inviteForEdit}
          placeholder={t("form.labels.email")}
          control={form.control}
        />
      ),
    },
    {
      label: "Role",
      required: true,
      field: (
        <Select
          name="role"
          disabled={isDeleteOperation}
          placeholder={t("form.placeholders.selectRole")}
          options={
            currentUser.role === Roles.ADMIN.value
              ? OrganizationRoles.asList()
              : OrganizationRoles.asList().filter(
                  (i) => i.value !== OrganizationRoles.ADMIN.value,
                )
          }
          control={form.control}
        />
      ),
    },
  ];

  useEffect(() => {
    if (inviteForEdit) {
      form.reset(
        {
          email: inviteForEdit.email,
          role: inviteForEdit.role,
        },
        { keepDefaultValues: true },
      );
    }
  }, [inviteForEdit]);

  const onClose = () => {
    form.reset();
    onCancel();
  };

  const onSubmit = async () => {
    if (isDeleteOperation) {
      deleteInviteById(inviteForEdit.id)
        .unwrap()
        .then(() => {
          notification.success({
            message: t("messages.inviteDeleted"),
          });
          onClose();
        })
        .catch((errors) => handleErrors(errors, null, notification));
    } else {
      const isValid = await form.trigger();
      if (isValid) {
        if (inviteForEdit) {
          patchInviteById({ id: inviteForEdit.id, data: form.getValues() })
            .unwrap()
            .then(() => {
              notification.success({
                message: t("messages.inviteUpdated"),
              });
              onClose();
            })
            .catch((errors) => handleErrors(errors, null, notification));
        } else {
          inviteUser({ ...form.getValues(), organization: organizationId })
            .unwrap()
            .then(() => {
              notification.success({
                message: t("messages.inviteSent"),
              });
              onClose();
            })
            .catch((errors) =>
              handleErrors(errors, form.setError, notification),
            );
        }
      }
    }
  };

  return (
    <Modal
      open={open}
      title={
        isDeleteOperation
          ? t("titles.removeInvite")
          : inviteForEdit
            ? t("titles.editInvite")
            : t("titles.sendInvite")
      }
      onCancel={onClose}
      footer={[
        <PrimaryButton
          key="cancel"
          onClick={onClose}
          style={{ background: token.colorBackgroundSecondary }}
        >
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton key="confirm" disabled={isLoading} onClick={onSubmit}>
          {isDeleteOperation ? t("buttons.confirm") : t("buttons.submit")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      {isDeleteOperation && (
        <div>
          <Title level={3}>{t("confirm.deleteUserConfirmation")}</Title>
        </div>
      )}
      {fields.map((i) => (
        <FormField key={i.label} {...i} />
      ))}
    </Modal>
  );
};

InviteFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  inviteForEdit: PropTypes.object,
  isDeleteOperation: PropTypes.bool,
  organizationId: PropTypes.number,
};

InviteFormModal.defaultProps = {
  isDeleteOperation: false,
  inviteForEdit: null,
  organizationId: null,
};

export default InviteFormModal;
