import { useEffect, useState } from "react";

import { Tabs } from "antd";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

const CustomTabs = ({
  defaultActiveTab,
  items,
  onChangeCallback,
  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    const tabParam = searchParams.get("tab");
    setActiveKey(
      tabParam || defaultActiveTab || items.filter((i) => !i?.skip)[0].key,
    );
  }, [searchParams]);

  useEffect(() => {
    if (activeKey) {
      searchParams.set("tab", activeKey);
      setSearchParams(searchParams, { replace: true });
    }
  }, [activeKey]);

  return (
    <Tabs
      items={items}
      activeKey={activeKey}
      onChange={(value) => {
        onChangeCallback(value);
        setActiveKey(value);
      }}
      defaultActiveTab={defaultActiveTab}
      {...props}
    />
  );
};

CustomTabs.propTypes = {
  items: PropTypes.array.isRequired,
  defaultActiveTab: PropTypes.string,
  onChangeCallback: PropTypes.func,
};

CustomTabs.defaultProps = {
  defaultActiveTab: null,
  onChangeCallback: () => {},
};

export default CustomTabs;
