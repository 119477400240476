import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { App, Col, Row, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import {
  useLazyGetAdminSurveyStatisticsQuery,
  useUpdateSurveyDetailsMutation,
} from "../../../../redux/service.js";
import {
  SurveyAudience,
  SurveyStatus,
  SurveyType,
} from "../../../../utilities/constants.jsx";
import Tag from "../../../../components/Tag.jsx";
import PrimaryButton from "../../../../components/PrimaryButton.jsx";
import Table from "../../../../components/Table.jsx";
import DownloadSurvey from "../DownloadSurvey.jsx";
import dayjs from "dayjs";

const { Title, Text } = Typography;

const SingleSurveyAdmin = ({ survey }) => {
  const { surveyId } = useParams();
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { notification } = App.useApp();

  const [paginateFilterSurveyStatistics, surveyStatisticsResponse] =
    useLazyGetAdminSurveyStatisticsQuery();
  const [updateSurveyDetails] = useUpdateSurveyDetailsMutation();

  useEffect(() => {
    paginateFilterSurveyStatistics({
      survey: survey.id,
      page: 1,
      pageSize: 10,
      ordering: "organization_name",
    });
  }, []);

  const surveyStatus = SurveyStatus.getItemByValue(survey.status);

  const handleChangeSubmissionRequired = (detailsId, newValue) => {
    updateSurveyDetails({
      id: detailsId,
      data: { isSubmissionRequired: newValue },
    })
      .unwrap()
      .then(() => notification.success({ message: t("surveyDetailsUpdated") }));
  };

  const columns = [
    {
      title: t("table.name"),
      dataIndex: "organization_name",
      key: "organization_name",
      sorter: true,
      addSearch: true,
      width: 400,
      ellipsis: true,
      render: (_, record) => (
        <a
          style={{ color: token.linkColor, textDecoration: "underline" }}
          onClick={() => {
            let navigateTo;
            if (record?.organization) {
              navigateTo = `/reports/${surveyId}/report?organization=${record.organization.id}`;
            } else {
              navigateTo = `/reports/${surveyId}/report?user=${record.user.id}`;
            }

            navigate(navigateTo);
          }}
        >
          {record?.organization
            ? record.organization.name
            : record.user.fullName}
        </a>
      ),
    },
    {
      title: t("table.questions"),
      dataIndex: "answeredQuestionsCount",
      key: "answeredQuestionsCount",
    },
    {
      title: t("table.narratives"),
      dataIndex: "answeredNarrativesCount",
      key: "answeredNarrativesCount",
    },
    {
      title: t("table.objects"),
      dataIndex: "answeredObjectsCount",
      key: "answeredObjectsCount",
    },
    {
      title: t("table.isSubmissionRequired"),
      dataIndex: "isSubmissionRequired",
      key: "isSubmissionRequired",
      addSearch: true,
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      filterMultiple: false,
      render: (_, record) => {
        const handleClick = () =>
          handleChangeSubmissionRequired(
            record.id,
            !record.isSubmissionRequired,
          );
        return record.isSubmissionRequired ? (
          <CheckCircleOutlined
            onClick={handleClick}
            style={{ color: "green" }}
          />
        ) : (
          <CloseCircleOutlined onClick={handleClick} style={{ color: "red" }} />
        );
      },
    },
    {
      title: t("table.isSubmitted"),
      dataIndex: "isSubmitted",
      key: "isSubmitted",
      addSearch: true,
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      filterMultiple: false,
      render: (_, record) =>
        record.isSubmitted ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: t("table.downloadReport"),
      key: "report",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          <DownloadSurvey
            survey={survey}
            filters={
              record?.organization
                ? { organization: record.organization.id }
                : { user: record.user.id }
            }
          >
            <DownloadOutlined />
          </DownloadSurvey>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filterObj, sorter) => {
    paginateFilterSurveyStatistics({
      survey: surveyId,
      page: pagination.current,
      pageSize: pagination.pageSize,
      ...filterObj,
      ordering: sorter?.field
        ? `${sorter.order === "ascend" ? "-" : ""}${sorter.field[0]}`
        : "organization_name",
    });
  };

  return (
    <div className="mt-4">
      <div style={{ display: "flex" }}>
        <div>
          <Tag
            label={surveyStatus.label}
            color={surveyStatus.background}
            className="px-3"
            style={{ color: surveyStatus.color, fontWeight: "bold" }}
          />
        </div>
        <Title level={2} className="ms-3">
          {survey.title}
        </Title>
        {survey.status === SurveyStatus.ACTIVE.value && (
          <PrimaryButton
            className="ms-4"
            onClick={() => navigate(`/reports/${surveyId}/invites`)}
          >
            {survey.audience === SurveyAudience.ORGANIZATION.value
              ? t("labels.inviteOrganizations")
              : t("labels.inviteUsers")}
          </PrimaryButton>
        )}
      </div>
      <Row className="mt-5">
        <Col span={12} style={{ display: "flex" }}>
          <div>
            <Text style={{ color: token.colorTextThird }}>
              {t("labels.startDate")}
            </Text>
            <div
              className="px-3 py-2"
              style={{
                backgroundColor: "rgba(231, 231, 231, 1)",
                border: "1px solid rgba(1, 1, 46, 0.134)",
              }}
            >
              {dayjs(survey.startDate).format("DD/MM/YYYY HH:mm")}
            </div>
          </div>
          <div className="ms-3">
            <Text style={{ color: token.colorTextThird }}>
              {t("labels.softDeadline")}
            </Text>
            <div
              className="px-3 py-2"
              style={{
                backgroundColor: "rgba(231, 231, 231, 1)",
                border: "1px solid rgba(1, 1, 46, 0.134)",
              }}
            >
              {survey?.softDeadline
                ? dayjs(survey.softDeadline).format("DD/MM/YYYY HH:mm")
                : t("noSoftDeadline")}
            </div>
          </div>
          <div className="ms-3">
            <Text style={{ color: token.colorTextThird }}>
              {t("labels.hardDeadline")}
            </Text>
            <div
              className="px-3 py-2"
              style={{
                backgroundColor: "rgba(231, 231, 231, 1)",
                border: "1px solid rgba(1, 1, 46, 0.134)",
              }}
            >
              {dayjs(survey?.softDeadline || survey.hardDeadline).format(
                "DD/MM/YYYY HH:mm",
              )}
            </div>
          </div>
        </Col>
        <Col span={12} style={{ display: "flex" }}>
          <div className="ms-3">
            <div>
              <Text style={{ color: token.colorTextThird, fontWeight: "bold" }}>
                {t("labels.surveyType")}
              </Text>
            </div>
            <Text>{SurveyType.getItemByValue(survey.type).label}</Text>
          </div>
          <div className="ms-5">
            <div>
              <Text style={{ color: token.colorTextThird, fontWeight: "bold" }}>
                {t("labels.invited")}
              </Text>
            </div>
            <Text>{survey.invitesCount}</Text>
          </div>
          <div className="ms-5">
            <div>
              <Text style={{ color: token.colorTextThird, fontWeight: "bold" }}>
                {t("labels.responses")}
              </Text>
            </div>
            <Text>{survey.responsesCount}</Text>
          </div>
          <div className="ms-5">
            <div>
              <Text style={{ color: token.colorTextThird, fontWeight: "bold" }}>
                {t("labels.submittedSurveysCount")}
              </Text>
            </div>
            <Text>{survey.submittedSurveysCount}</Text>
          </div>
        </Col>
      </Row>
      {surveyStatisticsResponse.isSuccess && (
        <div className="mt-5">
          <Table
            data={surveyStatisticsResponse.data.results}
            columns={columns}
            handleTableChange={handleTableChange}
            totalCount={surveyStatisticsResponse.data.count}
          />
        </div>
      )}
    </div>
  );
};

SingleSurveyAdmin.propTypes = {
  survey: PropTypes.object.isRequired,
};

export default SingleSurveyAdmin;
