import Login from "../views/auth/Login.jsx";
import ResetPassword from "../views/auth/ResetPassword.jsx";
import ResetPasswordConfirm from "../views/auth/ResetPasswordConfirm.jsx";
import EmailVerificationResend from "../views/auth/EmailVerificationResend.jsx";
import EmailVerification from "../views/auth/EmailVerification.jsx";
import Home from "../views/home/index.jsx";
import Register from "../views/auth/Register.jsx";
import Settings from "../views/users/Settings.jsx";
import ListOrganizations from "../views/organizations/ListOrganizations.jsx";
import ObjectsList from "../views/objects/ObjectsList.jsx";
import ObjectDetails from "../views/objects/ObjectDetails.jsx";
import CreateNarrativeForm from "../views/narratives/CreateNarrativeForm.jsx";
import UpdateNarrativeForm from "../views/narratives/UpdateNarrativeForm.jsx";
import ListNarratives from "../views/narratives/ListNarratives.jsx";
import SingleNarrativeView from "../views/narratives/SingleNarrativeView.jsx";
import SurveyOrganizationForm from "../views/survey/components/active-survey-form/SurveyOrganizationForm.jsx";
import SurveyList from "../views/survey/SurveyList.jsx";
import SingleSurvey from "../views/survey/SingleSurvey.jsx";
import SurveyInvites from "../views/survey/SurveyInvites.jsx";
import PrivacyPolicy from "../views/PrivacyPolicy.jsx";
import SurveyForm from "../views/survey/components/create-survey-form/SurveyForm.jsx";
import { Roles } from "../utilities/constants.jsx";
import Faq from "../views/FAQ.jsx";
import Community from "../views/community/Community.jsx";
import DiscussionForm from "../views/community/DiscussionForm.jsx";
import Profile from "../views/users/profile/index.jsx";
import SingleDiscussion from "../views/community/SingleDiscussion.jsx";
import CommunityTopicSearch from "../views/community/CommunityTopicSearch.jsx";
import CommunitySearch from "../views/community/CommunitySearch.jsx";
import SingleOrganization from "../views/organizations/SingleOrganization.jsx";
import SurveyViewForm from "../views/survey/components/survey-views/SurveyViewForm.jsx";
import SurveyView from "../views/survey/components/survey-views/SurveyView.jsx";
import Dashboard from "../views/dashboard/index.jsx";

export const PublicRoutes = [
  {
    path: "/login",
    view: Login,
  },
  {
    path: "/register",
    view: Register,
  },
  {
    path: "/reset-password/confirm",
    view: ResetPasswordConfirm,
  },
  {
    path: "/reset-password",
    view: ResetPassword,
  },
  {
    path: "/email-verification",
    view: EmailVerification,
  },
  {
    path: "/email-verification/resend",
    view: EmailVerificationResend,
  },
  {
    path: "/public/reports/:surveyId/report",
    view: SurveyView,
  },
];

export const PrivateRoutes = [
  {
    path: "/",
    view: Home,
  },
  {
    path: "/faqs",
    view: Faq,
  },
  {
    path: "/privacy-policy",
    view: PrivacyPolicy,
  },
  {
    path: "/dashboard",
    view: Dashboard,
    roles: [Roles.ADMIN.value],
  },
  {
    path: "/profile/:userId",
    view: Profile,
  },
  {
    path: "/settings",
    view: Settings,
  },
  {
    path: "/organizations",
    view: ListOrganizations,
    roles: [Roles.ADMIN.value],
  },
  {
    path: "/organizations/:organizationId",
    view: SingleOrganization,
  },
  {
    path: "/objects",
    view: ObjectsList,
  },
  {
    path: "/objects/:objectId",
    view: ObjectDetails,
  },
  {
    path: "/narratives",
    view: ListNarratives,
  },
  {
    path: "/narratives/:narrativeId",
    view: SingleNarrativeView,
  },
  {
    path: "/narratives/form",
    view: CreateNarrativeForm,
  },
  {
    path: "/narratives/form/:narrativeId",
    view: UpdateNarrativeForm,
  },
  {
    path: "/narratives/preview",
    view: SingleNarrativeView,
  },
  {
    path: "/reports",
    view: SurveyList,
  },
  {
    path: "/reports/:surveyId",
    view: SingleSurvey,
  },
  {
    path: "/reports/:surveyId/principle/:principleId",
    view: SurveyOrganizationForm,
  },
  {
    path: "/reports/:surveyId/invites",
    view: SurveyInvites,
    roles: [Roles.ADMIN.value],
    organizationRoles: [],
  },
  {
    path: "/reports/:surveyId/report",
    view: SurveyView,
  },
  {
    path: "/reports/:surveyId/form",
    view: SurveyForm,
    roles: [Roles.ADMIN.value],
    organizationRoles: [],
  },
  {
    path: "/community",
    view: Community,
  },
  {
    path: "/community/search",
    view: CommunitySearch,
  },
  {
    path: "/community/topics/:topic",
    view: CommunityTopicSearch,
  },
  {
    path: "/discussions/:discussionId",
    view: SingleDiscussion,
  },
  {
    path: "/discussions/form",
    view: DiscussionForm,
  },
  {
    path: "/discussions/form/:discussionId",
    view: DiscussionForm,
  },
  {
    path: "/reports/:surveyId/views/form",
    view: SurveyViewForm,
  },
  {
    path: "/reports/:surveyId/views/:surveyViewId/form",
    view: SurveyViewForm,
  },
];
