import { useEffect, useMemo } from "react";

import { Divider, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import {
  useGetQuestionByIdQuery,
  useLazyGetAnswerByIdQuery,
} from "../../../../redux/service.js";
import { OptionType } from "../../../../utilities/constants.jsx";
import NarrativeSnippet from "../../../narratives/components/NarrativeSnippet.jsx";
import ObjectSnippet from "../../../objects/components/ObjectSnippet.jsx";

const { Title, Text } = Typography;

const Answer = ({ option, answerId, surveyViewAnswer }) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const [getAnswerById, answerResponse] = useLazyGetAnswerByIdQuery();

  useEffect(() => {
    if (answerId) {
      const narrativeIds =
        surveyViewAnswer?.narratives.map((i) => i.id) || null;
      const objectIds = surveyViewAnswer?.objects.map((i) => i.id) || null;

      getAnswerById({
        id: answerId,
        filters: { narrativeIds, objectIds, view: searchParams.get("view") },
      });
    }
  }, [answerId]);

  const children = useMemo(() => {
    if (answerResponse.isSuccess) {
      if (
        [
          OptionType.SINGLE_CHOICE.value,
          OptionType.MULTIPLE_CHOICE.value,
          OptionType.CHOICE_TEXT.value,
        ].includes(option.type)
      ) {
        return (
          <div style={{ display: "flex" }}>
            <img src="/assets/checkbox.svg" />
            <div className="ms-3">
              <Text style={{ color: "black", fontSize: "17px" }}>
                {option.type === OptionType.CHOICE_TEXT.value
                  ? t("other")
                  : option.label}
              </Text>
              {option.type === OptionType.CHOICE_TEXT.value && (
                <Text>:&nbsp;{answerResponse.data.textAnswer}</Text>
              )}
            </div>
          </div>
        );
      }

      if (option.type === OptionType.TEXT.value) {
        return (
          <Text style={{ color: "black" }}>
            {answerResponse.data.textAnswer}
          </Text>
        );
      }
    }
  }, [answerResponse, option]);

  return (
    answerResponse.isSuccess && (
      <div>
        {children}
        {answerResponse.data.attachedObjects.map((object) => (
          <div
            key={object.id}
            className="mt-4 px-4 py-4"
            style={{
              backgroundColor: "rgba(242, 242, 245, 1)",
              borderTop: "2px solid rgba(185, 187, 198, 1)",
            }}
          >
            <ObjectSnippet object={object} showTitle />
          </div>
        ))}
        {answerResponse.data.attachedObjects.length === 0 && <Divider />}
        {answerResponse.data.attachedNarratives.map((narrative) => (
          <div key={narrative.id} className="mt-3">
            <NarrativeSnippet narrative={narrative} showTitle />
          </div>
        ))}
      </div>
    )
  );
};

Answer.propTypes = {
  answerId: PropTypes.number.isRequired,
  option: PropTypes.object.isRequired,
  surveyViewAnswer: PropTypes.object,
};

Answer.defaultProps = {
  surveyViewAnswer: null,
};

export const Question = ({ questionId, questionIndex, surveyView }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { data: question, isSuccess } = useGetQuestionByIdQuery({
    questionId,
    filters: {
      organization:
        surveyView?.organization.id || searchParams.get("organization"),
      user: surveyView?.createdBy ? surveyView.createdBy.id : null,
    },
  });

  const answeredOptions = useMemo(() => {
    return question
      ? question.options.filter((option) => option.answer !== null)
      : [];
  }, [question]);

  const answersIncludedInView = useMemo(() => {
    if (!surveyView) return answeredOptions.map((option) => option.answer);
    return surveyView.answers.map((surveyAnswer) => surveyAnswer.answer);
  }, [answeredOptions, surveyView]);

  return (
    isSuccess && (
      <div
        key={question.id}
        className="py-3 px-4 mt-3 mb-4"
        style={{
          border: "2px solid rgba(198, 206, 220, 1)",
        }}
      >
        <Title
          level={5}
          className="py-2 px-3"
          style={{
            fontWeight: "bold",
            color: "rgba(28, 32, 36, 1)",
          }}
        >
          {questionIndex + 1 + "."} {question.content}
        </Title>
        <div className="py-3 px-3">
          {answeredOptions.length === 0 ? (
            <div>
              <Text>{t("noAnswerForQuestion")}</Text>
            </div>
          ) : (
            answersIncludedInView &&
            answeredOptions
              .filter((option) => answersIncludedInView.includes(option.answer))
              .map((option) => (
                <div key={option.id} className="mt-2 py-3 px-4">
                  <Answer
                    answerId={option.answer}
                    option={option}
                    surveyViewAnswer={
                      surveyView
                        ? surveyView.answers.find(
                            (surveyView) => surveyView.answer === option.answer,
                          )
                        : null
                    }
                  />
                </div>
              ))
          )}
        </div>
      </div>
    )
  );
};

Question.propTypes = {
  questionId: PropTypes.number.isRequired,
  questionIndex: PropTypes.number.isRequired,
  surveyView: PropTypes.array.isRequired,
};
