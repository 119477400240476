import axios from "axios";
import { LocalStorageName } from "./constants.jsx";

export const clientApi = axios.create({
  baseURL: import.meta.env.VITE_APP_BACKEND_URL,
  withCredentials: true,
});

export const refreshApi = axios.create({
  baseURL: import.meta.env.VITE_APP_BACKEND_URL,
  withCredentials: true,
});

clientApi.interceptors.response.use(
  (response) => response,

  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshRequest = await refreshApi.post("/auth/refresh/");
        window.localStorage.setItem(
          LocalStorageName,
          JSON.stringify({
            accessExpiration: refreshRequest.data.accessExpiration,
            refreshExpiration: refreshRequest.data.refreshExpiration,
          }),
        );
        return clientApi(originalRequest);
      } catch (err) {
        window.localStorage.removeItem(LocalStorageName);
        const url = new URL(window.location.href);
        const params = url.searchParams.get("redirect_to")
          ? `?redirect_to=${url.searchParams.get("redirect_to")}`
          : "";
        window.location.href = "/login" + params;
        throw error;
      }
    }
    throw error;
  },
);
