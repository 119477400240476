import { createUrl } from "../../utilities/index.js";

const utilsService = (builder) => ({
  getLanguages: builder.query({
    query: (filters = {}) => createUrl("/languages/", filters),
    providesTags: (result) =>
      result ? [{ type: "Languages", id: result.value }] : [],
  }),
  getCountries: builder.query({
    query: () => "/countries/",
    providesTags: (result) =>
      result ? [{ type: "Countries", id: result.value }] : [],
  }),
  getFrequentlyAskedQuestions: builder.query({
    query: () => "/faqs/",
    providesTags: (result) =>
      result ? [{ type: "FAQS", id: result.value }] : [],
  }),
});

export default utilsService;
