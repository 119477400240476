import { useState } from "react";

import { useWatch } from "react-hook-form";
import { Divider, theme, Tooltip, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ChapterBox from "./ChapterBox.jsx";
import ChapterFormModal from "./ChapterFormModal.jsx";
import PrimaryButton from "../../../components/PrimaryButton.jsx";

const { Title, Text } = Typography;

const NarrativeChapters = ({ narrativeForm, callbackOnChange }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const [chapterFormModalOpen, setChapterFormModalOpen] = useState({
    open: false,
    chapter: null,
  });
  const existingChapters = useWatch({
    name: "chapters",
    control: narrativeForm.control,
  });

  // Add a new chapter or replace an existing one - editing is based on a random integer as ID
  const addOrEditChapter = (chapter) => {
    const randomId = Math.floor(Math.random() * (9999999999 - 1) + 1);

    let filteredExistingChapters;
    if (!chapter.id) {
      chapter = { ...chapter, id: randomId };
      filteredExistingChapters = [...existingChapters, chapter];
    } else {
      filteredExistingChapters = existingChapters.map((i) => {
        if (i.id === chapter.id) {
          return chapter;
        }
        return i;
      });
    }
    narrativeForm.setValue("chapters", filteredExistingChapters);
  };

  const setValueToForm = (key, value) => {
    narrativeForm.setValue(key, value);
    callbackOnChange();
  };

  return (
    <div>
      {existingChapters?.map((chapter, index) => (
        <div key={chapter.title}>
          <ChapterBox
            data={chapter}
            existingChapters={existingChapters}
            formSetValue={setValueToForm}
            chapterIndex={index}
            setChapterFormModalOpen={setChapterFormModalOpen}
          />
          <Divider />
        </div>
      ))}
      <PrimaryButton
        onClick={() => setChapterFormModalOpen({ open: true, chapter: null })}
        type="dashed"
      >
        <PlusOutlined style={{ verticalAlign: "initial" }} />
        {t("addChapter")}
      </PrimaryButton>
      <Tooltip
        className="ms-2"
        title={
          <div className="p-2">
            <Title
              className="mb-2"
              style={{ color: token.colorTextThird }}
              level={5}
            >
              {t("labels.chapter")}
            </Title>
            <Text style={{ color: token.colorTextThird }}>
              {t("chaptersHelpText")}
            </Text>
          </div>
        }
      >
        <img
          style={{ width: "15px" }}
          src="/assets/question.svg"
          alt="question icon"
        />
      </Tooltip>
      <ChapterFormModal
        open={chapterFormModalOpen.open}
        initialData={chapterFormModalOpen.chapter}
        onCancel={() =>
          setChapterFormModalOpen({
            open: false,
            chapter: null,
          })
        }
        onSubmit={addOrEditChapter}
      />
    </div>
  );
};

NarrativeChapters.propTypes = {
  narrativeForm: PropTypes.object.isRequired,
  callbackOnChange: PropTypes.func.isRequired,
};

export default NarrativeChapters;
