import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { App, Pagination, Row, theme, Typography } from "antd";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import {
  useDeleteSurveyViewMutation,
  useGetCurrentUserQuery,
  useLazyPaginateFilterSurveyViewsQuery,
} from "../../../../redux/service.js";
import PrimaryButton from "../../../../components/PrimaryButton.jsx";
import DeleteConfirmationModel from "../../../../components/DeleteConfirmationModel.jsx";
import { Roles } from "../../../../utilities/constants.jsx";

const { Title, Text } = Typography;
const pageSize = 10;

const SurveyView = ({ surveyView, setSelectedSurveyView }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notification } = App.useApp();

  const copyShareLink = () => {
    const sharingLink = `${window.location.origin}/public${location.pathname}/report?view=${surveyView.uuid}`;
    navigator.clipboard.writeText(sharingLink).then(() => {
      notification.success({ message: t("shareLinkCopied") });
    });
  };

  return (
    <Row
      className="pt-2 ps-2 mb-2"
      style={{
        borderTop: "1px solid #B9BBC6",
        display: "flex",
      }}
    >
      <div style={{ alignContent: "center" }}>
        <Title
          level={5}
          style={{ fontWeight: surveyView.isDefault ? "bold" : "normal" }}
        >
          {surveyView.title} {surveyView.isDefault && `- ${t("publicView")}`}
          {surveyView.isDefault && (
            <Text
              className="ms-3"
              style={{ color: "#7E808A", fontWeight: "normal" }}
            >
              {t("reportPublicViewText")}
            </Text>
          )}
        </Title>
      </div>
      <div
        style={{
          display: "flex",
          marginLeft: "auto",
          alignItems: "center",
        }}
      >
        {!surveyView.isDefault && (
          <div className="ms-2">
            <Text
              style={{
                fontSize: "14px",
                color: "#7E808A",
                fontWeight: 400,
              }}
            >
              {t("createdByUser", { user: surveyView.createdBy.fullName })}
            </Text>
          </div>
        )}
        {surveyView.isDefault ? (
          <>
            <div className="ms-2">
              <PrimaryButton
                type="secondary"
                onClick={() =>
                  navigate(
                    `/reports/${surveyView.survey}/views/${surveyView.uuid}/form`,
                  )
                }
              >
                {t("buttons.editPrivacySettings")}
              </PrimaryButton>
            </div>
          </>
        ) : (
          <>
            <div className="ms-2">
              <PrimaryButton
                type="secondary"
                onClick={() => setSelectedSurveyView(surveyView)}
              >
                {t("buttons.delete")}
              </PrimaryButton>
            </div>
            <div className="ms-2">
              <PrimaryButton
                type="secondary"
                onClick={() =>
                  navigate(
                    `/reports/${surveyView.survey}/views/${surveyView.uuid}/form`,
                  )
                }
              >
                {t("buttons.edit")}
              </PrimaryButton>
            </div>
          </>
        )}
        <div>
          <PrimaryButton onClick={copyShareLink} className="ms-2">
            {t("buttons.share")}
          </PrimaryButton>
        </div>
        <div
          className="ms-2"
          onClick={() =>
            navigate(
              `/reports/${surveyView.survey}/report/?view=${surveyView.uuid}`,
            )
          }
        >
          <PrimaryButton>{t("buttons.view")}</PrimaryButton>
        </div>
      </div>
    </Row>
  );
};

SurveyView.propTypes = {
  surveyView: PropTypes.object.isRequired,
  setSelectedSurveyView: PropTypes.func.isRequired,
};

const SurveySharingRights = ({ survey }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { notification } = App.useApp();

  const { data: currentUser, isSuccess: isCurrentUserSuccess } =
    useGetCurrentUserQuery();
  const [
    getPaginatedSurveyViews,
    { data: surveyViews, isSuccess: isSurveyViewsSuccess },
  ] = useLazyPaginateFilterSurveyViewsQuery();
  const [selectedSurveyView, setSelectedSurveyView] = useState(null);
  const [deleteSurveyView, { isLoading: IsDeleteSurveyViewLoading }] =
    useDeleteSurveyViewMutation();

  useEffect(() => {
    if (isCurrentUserSuccess) {
      handlePaginateSurveyViews(1);
    }
  }, [isCurrentUserSuccess]);

  const handlePaginateSurveyViews = (page) => {
    getPaginatedSurveyViews({
      page,
      pageSize,
      survey: survey.id,
      ...(currentUser.role === Roles.ADMIN.value
        ? {}
        : { organization: currentUser.organization.id }),
      ordering: "-default",
    });
  };

  const handleDelete = () => {
    deleteSurveyView(selectedSurveyView.uuid)
      .unwrap()
      .then(() => {
        notification.success({ message: t("surveyViewDeleted") });
        setSelectedSurveyView(null);
      });
  };

  return (
    <div>
      <PrimaryButton
        onClick={() => navigate(`/reports/${survey.id}/views/form/`)}
      >
        {t("createReportView")}
      </PrimaryButton>
      <div className="mt-3">
        {isSurveyViewsSuccess && (
          <div>
            {surveyViews.results.map((view) => (
              <SurveyView
                key={view.uuid}
                surveyView={view}
                setSelectedSurveyView={setSelectedSurveyView}
              />
            ))}
            {surveyViews.count !== 0 && (
              <Pagination
                rootClassName="mt-4 text-center"
                style={{ justifyContent: "center" }}
                defaultCurrent={1}
                total={surveyViews.count}
                pageSize={pageSize}
                onChange={(page) => handlePaginateSurveyViews(page)}
              />
            )}
          </div>
        )}
      </div>
      <DeleteConfirmationModel
        isLoading={IsDeleteSurveyViewLoading}
        onSubmit={handleDelete}
        onCancel={() => setSelectedSurveyView(null)}
        title={
          <span style={{ color: token.colorPrimaryText }}>
            {t("confirmDeletion")}
          </span>
        }
        open={selectedSurveyView}
      >
        <Text>{t("deleteSurveyViewContent1")}</Text>
        {selectedSurveyView && (
          <span style={{ fontWeight: "bold" }}>{selectedSurveyView.title}</span>
        )}
        <Text>{t("deleteSurveyViewContent2")}</Text>
      </DeleteConfirmationModel>
    </div>
  );
};

SurveySharingRights.propTypes = {
  survey: PropTypes.object.isRequired,
};

export default SurveySharingRights;
