import {useEffect, useMemo, useRef} from "react";

import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import {Carousel, theme, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import PropTypes from "prop-types";

import {useLazyPaginateFilterSurveysQuery, usePaginateFilterSurveyViewsQuery} from "../../../../redux/service.js";
import {useWindowSize} from "../../../../utilities/hooks.js";

const { Title } = Typography;

const SingleSurveyCarouselItem = ({ surveyView }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();

  return (
    <div
      style={{ width: "230px", backgroundColor: "white", height: "250px" }}
      className="py-4 px-3"
    >
      <div className="mb-2">
        <Title level={5}
          style={{
            color: token.colorPrimaryText,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
            {surveyView.survey.title}
        </Title>
      </div>
      <Title
        level={4}
        style={{ color: token.colorBackgroundSecondary, cursor: "pointer" }}
        onClick={() => navigate(`/reports/${surveyView.survey.id}/report/?view=${surveyView.uuid}`)}
        className="mb-3"
      >
        {_.truncate(surveyView.organization.name, { length: 40 })}
      </Title>
    </div>
  );
};

SingleSurveyCarouselItem.propTypes = {
  surveyView: PropTypes.object.isRequired,
};


const TrendingSurveys = () => {
    const carouselRef = useRef();
      const { width } = useWindowSize();
    const { token } = theme.useToken();
    const { t } = useTranslation();

  const [getSurveys, surveysResponse] = useLazyPaginateFilterSurveysQuery();
    const { data: trendingSurveys, isSuccess: isTrendingSurveysSuccess } =
    usePaginateFilterSurveyViewsQuery({
      ordering: "popular",
      pageSize: 10,
      isDefault: true,
    });

      const slidesCount = useMemo(() => {
        if (width < 680) {
          return 1;
        } else if (width < 950) {
          return 2;
        } else if (width < 1200) {
          return 3;
        } else {
          return 4;
        }
      }, [width]);

      useEffect(() => {
        if (isTrendingSurveysSuccess) {
            const surveyIds = trendingSurveys.results.map((i) => i.survey);
            if (surveyIds.length) {
                getSurveys({ids: surveyIds, pagination: 'off'});
            }
        }

    }, [isTrendingSurveysSuccess]);

    return isTrendingSurveysSuccess && trendingSurveys.results.length > 0 && surveysResponse.isSuccess ? (
                <div className="mb-5">
                    <Title
                        level={4}
                        className="mb-4"
                        style={{color: token.colorBackgroundSecondary}}
                    >
                        {t("trendingReports")}
                    </Title>
                    <div
                        className="reports-carousel-btn carousel-btn"
                        onClick={() => carouselRef.current.prev()}
                    >
                        <ArrowLeftOutlined/>
                    </div>
                    <Carousel
                        ref={carouselRef}
                        slidesToShow={slidesCount}
                        infinite={false}
                        dots={false}

                    >
                        {trendingSurveys.results.map((i) => (
                            <SingleSurveyCarouselItem key={i.id} surveyView={{...i, survey: surveysResponse.data.find((survey) => survey.id === i.survey)}}/>
                        ))}
                    </Carousel>
                    <div
                        className="reports-carousel-btn carousel-btn"
                        style={{insetInlineEnd: "5px"}}
                        onClick={() => carouselRef.current.next()}
                    >
                        <ArrowRightOutlined/>
                    </div>
                </div>
                ) : null;
};

export default TrendingSurveys;
