import { useMemo, useRef } from "react";

import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Carousel, theme, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { usePaginateFilterObjectsQuery } from "../../../redux/service.js";
import { ObjectTypes } from "../../../utilities/constants.jsx";
import { useWindowSize } from "../../../utilities/hooks.js";

const { Title, Text } = Typography;

const SingleObjectCarousel = ({ object }) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      style={{ width: "230px", backgroundColor: "white", height: "250px" }}
      className="py-4 px-3"
    >
      <div className="mb-2">
        <Text
          style={{
            color: token.colorPrimaryText,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {ObjectTypes.getItemByValue(object.type).label}
        </Text>
      </div>
      <Title
        level={5}
        style={{ color: token.colorBackgroundSecondary, cursor: "pointer" }}
        onClick={() => navigate(`/objects/${object.id}`)}
        className="mb-3"
      >
        {_.truncate(object.title, { length: 40 })}
      </Title>
      <div className="mb-2">
        <Text>
          <span style={{ color: "#6b6d75", fontWeight: "bold" }}>
            {t("labels.description")}
          </span>
          : {_.truncate(object.description, { length: 55 })}
        </Text>
      </div>
      <div style={{ position: "absolute", bottom: "15px", width: "200px" }}>
        <Text>
          <span style={{ color: "black" }} className="me-1">
            {t("uploadedBy")}
          </span>
          <span
            style={{
              fontWeight: "bold",
              color: token.colorBackgroundSecondary,
              cursor: "pointer",
            }}
            onClick={() => navigate(`/profile/${object.createdBy.id}`)}
          >
            {object.createdBy.fullName}
          </span>
        </Text>
      </div>
    </div>
  );
};

SingleObjectCarousel.propTypes = {
  object: PropTypes.object.isRequired,
};

const TrendingObjects = () => {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const carouselRef = useRef();

  const { data: trendingObjects, isSuccess: isTrendingObjectsSuccess } =
    usePaginateFilterObjectsQuery({
      ordering: "popular",
      pageSize: 10,
      time_gte: dayjs().startOf("month").format("YYYY-MM-DD"),
      time_lte: dayjs().endOf("month").format("YYYY-MM-DD"),
    });

  const slidesCount = useMemo(() => {
    if (width < 680) {
      return 1;
    } else if (width < 950) {
      return 2;
    } else if (width < 1200) {
      return 3;
    } else {
      return 4;
    }
  }, [width]);

  return isTrendingObjectsSuccess && trendingObjects.results.length > 0 ? (
        <div className="mb-3">
          <Title
            level={4}
            className="mb-4"
            style={{ color: token.colorBackgroundSecondary }}
          >
            {t("trendingObjects")}
          </Title>
          <div
            className="carousel-btn"
            onClick={() => carouselRef.current.prev()}
          >
            <ArrowLeftOutlined />
          </div>
          <Carousel
            ref={carouselRef}
            slidesToShow={slidesCount}
            infinite={false}
            dots={false}
          >
            {trendingObjects.results.map((i) => (
              <SingleObjectCarousel key={i.id} object={i} />
            ))}
          </Carousel>
          <div
            className="carousel-btn"
            style={{ insetInlineEnd: "70px" }}
            onClick={() => carouselRef.current.next()}
          >
            <ArrowRightOutlined />
          </div>
        </div>
      ) : null
};

export default TrendingObjects;
