import { useState } from "react";

import PrincipleModalForm from "./PrincipleModalForm.jsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";

import PrimaryButton from "../../../../../components/PrimaryButton.jsx";
import {
  useDeletePrincipleMutation,
  useGetPrinciplesQuery,
} from "../../../../../redux/service.js";
import PrincipleVerticalBar from "../../../components/PrincipleVerticalBar.jsx";
import QuestionForm from "../questions/QuestionForm.jsx";

const PrincipleForm = ({ surveyId }) => {
  const { t } = useTranslation();

  const { data: principles, isSuccess } = useGetPrinciplesQuery({
    survey: surveyId,
    pagination: "off",
  });
  const [deletePrinciple] = useDeletePrincipleMutation();

  const [principleModal, setPrincipleModal] = useState({
    open: false,
    data: null,
  });

  return (
    <div>
      <PrimaryButton
        className="mt-3"
        onClick={() => setPrincipleModal({ open: true, data: null })}
      >
        Create principle
      </PrimaryButton>
      <PrincipleModalForm
        onCancel={() => setPrincipleModal({ open: false, data: null })}
        surveyId={surveyId}
        {...principleModal}
      />
      {isSuccess &&
        principles
          .slice()
          .sort((a, b) => a.index - b.index)
          .map((principle) => (
            <div key={principle.id} className="mt-4">
              <Collapse
                style={{
                  width: "90%",
                  border: "1px solid rgba(198, 206, 220, 1)",
                }}
                expandIconPosition="end"
                className="mt-3 mb-4"
                items={[
                  {
                    key: "1",
                    label: (
                      <div>
                        <PrincipleVerticalBar principle={principle} />
                        <div style={{ textAlign: "right", marginTop: "-20px" }}>
                          <PrimaryButton
                            type="secondary"
                            className="me-3"
                            onClick={() =>
                              setPrincipleModal({ open: true, data: principle })
                            }
                          >
                            {t("buttons.edit")}
                          </PrimaryButton>
                          <PrimaryButton
                            type="secondary"
                            onClick={() => deletePrinciple(principle.id)}
                          >
                            {t("buttons.delete")}
                          </PrimaryButton>
                        </div>
                      </div>
                    ),
                    children: <QuestionForm principleId={principle.id} />,
                  },
                ]}
              />
            </div>
          ))}
    </div>
  );
};

PrincipleForm.propTypes = {
  surveyId: PropTypes.string.isRequired,
};

export default PrincipleForm;
