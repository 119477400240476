import { useState } from "react";

import { Alert, App, Divider, Typography } from "antd";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { handleErrors } from "../../../utilities/index.js";
import DeleteConfirmationModel from "../../../components/DeleteConfirmationModel.jsx";
import {
  useDeleteOrganizationByIdMutation,
  useGetCurrentUserQuery,
  usePatchOrganizationByIdMutation,
} from "../../../redux/service.js";
import PrimaryButton from "../../../components/PrimaryButton.jsx";
import RetrieveData from "./RetrieveData.jsx";
import { OrganizationRoles, Roles } from "../../../utilities/constants.jsx";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../components/form/Input.jsx";
import FormField from "../../../components/form/FormField.jsx";

const { Title, Text } = Typography;

const Settings = ({ organization }) => {
  const { notification } = App.useApp();
  const { t } = useTranslation();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteOrganizationById, { isLoading: deleteOrganizationLoading }] =
    useDeleteOrganizationByIdMutation();
  const [patchOrganizationById, { isLoading: patchOrganizationLoading }] =
    usePatchOrganizationByIdMutation();
  const { data: currentUser } = useGetCurrentUserQuery();

  const deleteOrganizationSchema = yup.object().shape({
    password: yup
      .string()
      .typeError(t("form.validation.passwordNotValid"))
      .min(8, t("form.validation.passwordLengthValidation"))
      .matches(/[A-Z]/, t("form.validation.passwordCapitalLetterValidation"))
      .matches(/[0-9]/, t("form.validation.passwordNumberValidation"))
      .required(t("form.validation.passwordRequired")),
  });

  const form = useForm({
    resolver: yupResolver(deleteOrganizationSchema),
    mode: "onChange",
  });

  const handleDelete = async () => {
    if (currentUser.role === Roles.ADMIN) {
      deleteOrganizationById({ id: organization.id })
        .unwrap()
        .then(() => {
          notification.success({ message: t("organizationDeleted") });
          setOpenDeleteModal(false);
        })
        .catch((errors) => handleErrors(errors, form.setError, notification));
    } else {
      await form.trigger();
      deleteOrganizationById({ id: organization.id, data: form.getValues() })
        .unwrap()
        .then((response) => {
          notification.success({ message: response.data });
          setOpenDeleteModal(false);
        })
        .catch((errors) => handleErrors(errors, form.setError, notification));
    }
  };

  const handleStopDeletion = () => {
    patchOrganizationById({ id: organization.id, data: { deletionDate: null } })
      .unwrap()
      .then(() =>
        notification.success({ message: t("messages.deletionCancelled") }),
      )
      .catch((errors) => handleErrors(errors, null, notification));
  };

  return (
    <div className="w-50">
      <RetrieveData organizationId={organization.id} />
      <Divider />
      {currentUser?.organizationRole === OrganizationRoles.ADMIN.value ||
      currentUser.role === Roles.ADMIN.value ? (
        <div>
          <Title level={3}>{t("deleteOrganization")}</Title>
          {organization.deletionDate &&
          currentUser.role === Roles.ADMIN.value ? (
            <div className="mt-2 d-inline-block">
              <Alert
                showIcon
                message={`${t("messages.deletionTime")} ${DateTime.fromISO(organization.deletionDate).setLocale("en-GB").toLocaleString()}.`}
                type="warning"
              />
              <PrimaryButton
                className="mt-2"
                onClick={handleStopDeletion}
                disabled={patchOrganizationLoading}
              >
                {t("buttons.stopDeletion")}
              </PrimaryButton>
            </div>
          ) : (
            <div>
              <PrimaryButton
                className="mt-2"
                onClick={() => setOpenDeleteModal(true)}
              >
                {t("buttons.delete")}
              </PrimaryButton>
              <DeleteConfirmationModel
                isLoading={deleteOrganizationLoading}
                onSubmit={handleDelete}
                title={t("confirm.deleteOrganization", {
                  name: organization.name,
                })}
                onCancel={() => setOpenDeleteModal(false)}
                open={openDeleteModal}
              >
                {currentUser.role === Roles.ADMIN.value ? (
                  <div>
                    <Text>{t("confirmOrganizationDelete")}</Text>
                  </div>
                ) : (
                  <div>
                    <div className="mb-1">
                      <Text>
                        {t("confirm.deleteOrganizationConfirmationContent1")}
                      </Text>
                    </div>

                    <div className="mb-1">
                      <Text className="mb-1">
                        {t("confirm.deleteOrganizationConfirmationContent2")}
                      </Text>
                    </div>
                    <div className="mb-1">
                      <Text>
                        {t("confirm.deleteOrganizationConfirmationContent3")}
                      </Text>
                    </div>
                    <div className="mb-1">
                      <Text>
                        {t("confirm.deleteOrganizationConfirmationContent4")}
                      </Text>
                    </div>
                    <div className="mb-1">
                      <Text>
                        {t("confirm.deleteOrganizationConfirmationContent5")}
                      </Text>
                    </div>
                    <div className="mb-1">
                      <Text>
                        <strong>
                          {t("confirm.deleteOrganizationConfirmationContent6")}
                        </strong>
                      </Text>
                    </div>
                    <FormField
                      label={t("form.labels.password")}
                      field={
                        <Input
                          name="password"
                          htmlType="password"
                          control={form.control}
                        />
                      }
                    />
                  </div>
                )}
              </DeleteConfirmationModel>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

Settings.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default Settings;
