import { App, Col, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import PrimaryButton from "../../../../components/PrimaryButton.jsx";
import FormField from "../../../../components/form/FormField.jsx";
import Input from "../../../../components/form/Input.jsx";
import DatePicker from "../../../../components/form/DatePicker.jsx";
import Select from "../../../../components/form/Select.jsx";
import { SurveyType } from "../../../../utilities/constants.jsx";
import { useCreateSurveyMutation } from "../../../../redux/service.js";
import { handleErrors } from "../../../../utilities/index.js";

const surveyCreateSchema = (t) =>
  yup.object().shape({
    type: yup
      .string()
      .typeError(t("form.validation.surveyTypeNotValid"))
      .required(t("form.validation.surveyTypeRequired")),
    startDate: yup
      .date()
      .typeError(t("form.validation.startDateNotValid"))
      .required(t("form.validation.startDateRequired")),
    softDeadline: yup
      .date()
      .typeError(t("form.validation.softDeadlineNotValid"))
      .when(
        "startDate",
        (startDate, schema) =>
          startDate &&
          schema.min(
            startDate,
            t("form.validation.softDeadlineBeforeStartDate"),
          ),
      )
      .when(
        "hardDeadline",
        (hardDeadline, schema) =>
          hardDeadline &&
          schema.max(
            hardDeadline,
            t("form.validation.softDeadlineBeforeHardDeadline"),
          ),
      )
      .nullable(),
    hardDeadline: yup
      .date()
      .typeError(t("form.validation.hardDeadlineNotValid"))
      .when(
        "startDate",
        (startDate, schema) =>
          startDate &&
          schema.min(
            startDate,
            t("form.validation.hardDeadlineBeforeStartDate"),
          ),
      )
      .required(t("form.validation.hardDeadlineRequired")),
    title: yup
      .string()
      .typeError(t("form.validation.titleNotValid"))
      .required(t("form.validation.titleRequired")),
  });

const CreateSurveyModal = ({ open, onCancel }) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();
  const navigate = useNavigate();

  const [createSurvey] = useCreateSurveyMutation();

  const form = useForm({
    resolver: yupResolver(surveyCreateSchema(t)),
    mode: "onChange",
  });

  const onSubmit = (values) => {
    createSurvey(values)
      .unwrap()
      .then((data) => {
        navigate(`/reports/${data.id}/form`);
        notification.success({ message: t("surveyCreatedSuccess") });
      })
      .catch((errors) => handleErrors(errors, form.setError, notification));
  };

  return (
    <Modal
      open={open}
      destroyOnClose
      width={600}
      title={t("form.labels.createSurvey")}
      onCancel={onCancel}
      footer={[
        <PrimaryButton key="cancel" onClick={onCancel}>
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton key="next" onClick={form.handleSubmit(onSubmit)}>
          {t("buttons.next")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <FormField
        label={t("form.labels.surveyType")}
        required
        field={
          <Select
            name="type"
            options={SurveyType.asList()}
            placeholder={t("form.placeholders.surveyType")}
            control={form.control}
          />
        }
      />
      <Row>
        <Col span={8} className="pe-2">
          <FormField
            label={t("form.labels.surveyStartDate")}
            required
            field={
              <DatePicker
                name="startDate"
                placeholder={t("form.placeholders.surveyStartDate")}
                control={form.control}
              />
            }
          />
        </Col>
        <Col span={8} className="pe-2">
          <FormField
            label={t("form.labels.surveySoftDeadline")}
            field={
              <DatePicker
                name="softDeadline"
                placeholder={t("form.placeholders.surveySoftDeadline")}
                control={form.control}
              />
            }
          />
        </Col>
        <Col span={8}>
          <FormField
            label={t("form.labels.surveyHardDeadline")}
            required
            field={
              <DatePicker
                name="hardDeadline"
                placeholder={t("form.placeholders.surveyHardDeadline")}
                control={form.control}
              />
            }
          />
        </Col>
      </Row>
      <FormField
        label={t("form.labels.title")}
        required
        field={
          <Input
            name="title"
            placeholder={t("form.placeholders.title")}
            control={form.control}
          />
        }
      />
    </Modal>
  );
};

CreateSurveyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateSurveyModal;
