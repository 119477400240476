import { useMemo } from "react";

import PropTypes from "prop-types";
import { DatePicker } from "antd";
import { useController } from "react-hook-form";
import dayjs from "dayjs";

import FieldError from "./FieldError.jsx";

const CustomDatePicker = ({ name, control, placeholder, isEdit }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  const value = useMemo(() => {
    if (field.value) {
      return dayjs.isDayjs(field.value)
        ? field.value
        : dayjs(field.value, "YYYY-MM-DD");
    }
    return null;
  }, [field.value]);

  return (
    <div>
      {isEdit ? (
        <DatePicker
          status={error && "error"}
          style={{ borderRadius: 0, width: "100%" }}
          format="DD/MM/YYYY"
          placeholder={placeholder}
          {...field}
          value={value}
          onChange={(e) =>
            field.onChange(
              [undefined, null].includes(e) ? e : e.format("YYYY-MM-DD"),
            )
          }
        />
      ) : (
        field.value && (
          <div>
            {dayjs.isDayjs(field.value)
              ? field.value.format("DD/MM/YYYY")
              : dayjs(field.value).format("DD/MM/YYYY")}
          </div>
        )
      )}
      {error && <FieldError errors={error} />}
    </div>
  );
};

CustomDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  isEdit: PropTypes.bool,
};

CustomDatePicker.defaultProps = {
  placeholder: "",
  isEdit: true,
};

export default CustomDatePicker;
