import { Col, Row, theme, Tooltip, Typography } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;
const columnStyles = {
  textTransform: "uppercase",
  fontSize: "15px",
  color: "rgba(96, 100, 108, 1)",
};

const PrincipleStatistics = ({ principle, showTooltips }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  return (
    <Row className="w-100">
      {principle.requiredQuestionsCount > 0 ||
      principle.answeredQuestionsCount > 0 ? (
        <Col xl={8} lg={8} md={8} sm={24}>
          <Text style={columnStyles}>{t("labels.questions")}</Text>
          <div>
            <Title level={4} style={{ fontWeight: "bold", color: "black" }}>
              {principle.answeredQuestionsCount}/
              {principle.requiredQuestionsCount}
            </Title>
          </div>
        </Col>
      ) : null}
      {principle.requiredObjectsCount > 0 ||
      principle.answeredObjectsCount > 0 ? (
        <Col xl={8} lg={8} md={8} sm={24}>
          <div style={{ display: "flex" }}>
            <Text style={columnStyles}>
              {t("labels.objects", { plural: "s" })}
            </Text>
            {showTooltips && (
              <Tooltip
                className="ms-2"
                title={
                  <div className="p-2">
                    <Title
                      className="mb-2"
                      style={{ color: token.colorTextThird }}
                      level={5}
                    >
                      {t("labels.objects", { plural: "s" })}
                    </Title>
                    <Text style={{ color: token.colorTextThird }}>
                      {t("objectsHelpText")}
                    </Text>
                  </div>
                }
              >
                <img
                  style={{ width: "15px" }}
                  src="/assets/question.svg"
                  alt="question icon"
                />
              </Tooltip>
            )}
          </div>
          <Title level={4} style={{ fontWeight: "bold", color: "black" }}>
            {principle.answeredObjectsCount}/{principle.requiredObjectsCount}
          </Title>
        </Col>
      ) : null}
      {principle.requiredNarrativesCount > 0 ||
      principle.answeredNarrativesCount > 0 ? (
        <Col xl={8} lg={8} md={8} sm={24}>
          <div style={{ display: "flex" }}>
            <Text style={columnStyles}>
              {t("labels.narratives", { plural: "s" })}
            </Text>
            {showTooltips && (
              <Tooltip
                className="ms-2"
                title={
                  <div className="p-2">
                    <Title
                      className="mb-2"
                      style={{ color: token.colorTextThird }}
                      level={5}
                    >
                      {t("labels.narratives", { plural: "s" })}
                    </Title>
                    <Text style={{ color: token.colorTextThird }}>
                      {t("narrativesHelpText")}
                    </Text>
                  </div>
                }
              >
                <img
                  style={{ width: "15px" }}
                  src="/assets/question.svg"
                  alt="question icon"
                />
              </Tooltip>
            )}
          </div>
          <Title level={4} style={{ fontWeight: "bold", color: "black" }}>
            {principle.answeredNarrativesCount}/
            {principle.requiredNarrativesCount}
          </Title>
        </Col>
      ) : null}
    </Row>
  );
};

PrincipleStatistics.propTypes = {
  principle: PropTypes.shape({
    requiredQuestionsCount: PropTypes.number,
    requiredObjectsCount: PropTypes.number,
    requiredNarrativesCount: PropTypes.number,
    answeredQuestionsCount: PropTypes.number.isRequired,
    answeredObjectsCount: PropTypes.number.isRequired,
    answeredNarrativesCount: PropTypes.number.isRequired,
  }).isRequired,
  showTooltips: PropTypes.bool,
};

PrincipleStatistics.defaultProps = {
  showTooltips: false,
};

export default PrincipleStatistics;
