import { Typography } from "antd";
import { useNavigate } from "react-router-dom";

import PrimaryButton from "../PrimaryButton";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const ErrorBoundary = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div style={{ height: "70vh" }}>
      <div
        style={{
          position: "relative",
          top: "50%",
          transform: "translateY(-50%)",
          width: "60%",
          textAlign: "left",
          margin: "auto",
        }}
      >
        <Title level={4} style={{ marginTop: "18px" }}>
          {t("errors.error_boundary_report.title")}
        </Title>
        <Text style={{ marginTop: "12px" }}>
          {t("errors.error_boundary_report.message")}{" "}
          <strong>{t("errors.error_boundary_report.supportEmail")}</strong>.
          {t("errors.error_boundary_report.instruction")}
        </Text>
        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <PrimaryButton onClick={() => navigate(-1)}>
            {t("buttons.back")}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundary;
