import { useTranslation } from "react-i18next";
import { Col, Divider, Row, Spin, theme, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import Input from "../../components/form/Input.jsx";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import Select from "../../components/form/Select.jsx";
import DiscussionBox from "./components/DiscussionBox.jsx";
import CommunitySearchForm from "./components/CommunitySearchForm.jsx";
import SearchFields from "./components/SearchFields.jsx";

const { Title } = Typography;

const CommunitySearch = ({
  form,
  handleEnterPress,
  handleSearch,
  discussionsData,
  discussionsSuccess,
  discussionsLoading,
}) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  return (
    <div>
      <div
        className="w-50 mb-5 mt-4"
        style={{ margin: "auto", textAlign: "center" }}
      >
        <div className="mb-3">
          <Title level={1} style={{ color: token.colorTextThird }}>
            {t("searchTheCommunity")}
          </Title>
        </div>
        <Input
          className="mt-4"
          placeholder={t("form.placeholders.discussionTitle")}
          style={{ borderRadius: 0 }}
          name="title"
          control={form.control}
          onKeyPress={handleEnterPress}
          prefix={<SearchOutlined />}
          suffix={
            <PrimaryButton onClick={form.handleSubmit(handleSearch)}>
              {t("buttons.search")}
            </PrimaryButton>
          }
        />
      </div>
      <div className="mt-3">
        {discussionsLoading && (
          <div className="mt-3 d-flex justify-content-center align-items-center h-100">
            <Spin size="large" />
          </div>
        )}
        {discussionsSuccess && (
          <Row>
            <Divider
              style={{ color: "rgba(1, 1, 46, 0.134)", marginBottom: 0 }}
            />
            <Col span={19} className="pt-4">
              <Row>
                <Col span={12}>
                  <Title level={4} style={{ color: token.colorTextThird }}>
                    {t("results", { count: discussionsData.count })}
                  </Title>
                </Col>
                <Col span={12}>
                  <Row style={{ justifyContent: "right" }}>
                    <Col
                      className="me-3"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Title level={4}>{t("sortBy")}:</Title>
                    </Col>
                    <Col className="me-3">
                      <Select
                        style={{ borderRadius: 0 }}
                        name="ordering"
                        control={form.control}
                        options={[
                          { label: "Most recent", value: "-published" },
                          { label: "Oldest", value: "published" },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="pe-4">
                {discussionsData.results.map((i) => (
                  <DiscussionBox discussion={i} showAvatar={false} key={i.id} />
                ))}
              </div>
            </Col>
            <Col
              span={5}
              className="py-3 pe-3 ps-4"
              style={{ borderLeft: "1px solid rgba(1, 1, 46, 0.134)" }}
            >
              <SearchFields form={form} includeTopics />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

CommunitySearch.propTypes = {
  form: PropTypes.object.isRequired,
  handleEnterPress: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  discussionsData: PropTypes.object.isRequired,
  discussionsSuccess: PropTypes.bool.isRequired,
  discussionsLoading: PropTypes.bool.isRequired,
};

const CommunitySearchWithForm = CommunitySearchForm(CommunitySearch);

export default CommunitySearchWithForm;
