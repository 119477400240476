import { useEffect } from "react";

import { Col, Row } from "antd";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import DebouncedInput from "../../../../components/form/DebouncedInput.jsx";
import { usePatchSurveyMutation } from "../../../../redux/service.js";
import DatePicker from "../../../../components/form/DatePicker.jsx";
import FormField from "../../../../components/form/FormField.jsx";

const surveyUpdateSchema = (t) =>
  yup.object().shape({
    startDate: yup
      .date()
      .typeError(t("form.validation.startDateNotValid"))
      .required(t("form.validation.startDateRequired")),
    softDeadline: yup
      .date()
      .typeError(t("form.validation.softDeadlineNotValid"))
      .when(
        "startDate",
        (startDate, schema) =>
          startDate &&
          schema.min(
            startDate,
            t("form.validation.softDeadlineBeforeStartDate"),
          ),
      )
      .when(
        "hardDeadline",
        (hardDeadline, schema) =>
          hardDeadline &&
          schema.max(
            hardDeadline,
            t("form.validation.softDeadlineBeforeHardDeadline"),
          ),
      )
      .nullable(),
    hardDeadline: yup
      .date()
      .typeError(t("form.validation.hardDeadlineNotValid"))
      .required(t("form.validation.hardDeadlineRequired"))
      .when(
        "startDate",
        (startDate, schema) =>
          startDate &&
          schema.min(
            startDate,
            t("form.validation.hardDeadlineBeforeStartDate"),
          ),
      ),
    title: yup
      .string()
      .typeError(t("form.validation.titleNotValid"))
      .required(t("form.validation.titleRequired")),
  });

const SurveyDetails = ({ survey }) => {
  const { t } = useTranslation();
  const [patchSurvey] = usePatchSurveyMutation();

  const form = useForm({
    resolver: yupResolver(surveyUpdateSchema(t)),
    mode: "onChange",
    defaultValues: {
      title: survey.title,
      startDate: survey.startDate,
      hardDeadline: survey.hardDeadline,
      softDeadline: survey.softDeadline,
    },
  });

  useEffect(() => {
    const subscription = form.watch(async (data) => {
      const isValid = await form.trigger();
      if (isValid) patchSurvey({ id: survey.id, data });
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  return (
    <Row gutter={40}>
      <Col lg={12} md={12} sm={24}>
        <FormField
          label={t("form.labels.title")}
          required
          field={
            <DebouncedInput
              initialValue={survey.title}
              onChange={(value) => form.setValue("title", value)}
              rows={1}
            />
          }
        />
      </Col>
      <Col lg={4} md={4} sm={24}>
        <FormField
          label={t("form.labels.surveyStartDate")}
          required
          field={
            <DatePicker
              name="startDate"
              placeholder={t("form.placeholders.surveyStartDate")}
              control={form.control}
            />
          }
        />
      </Col>
      <Col lg={4} md={4} sm={24}>
        <FormField
          label={t("form.labels.surveySoftDeadline")}
          field={
            <DatePicker
              name="softDeadline"
              placeholder={t("form.placeholders.surveySoftDeadline")}
              control={form.control}
            />
          }
        />
      </Col>
      <Col lg={4} md={4} sm={24}>
        <FormField
          label={t("form.labels.surveyHardDeadline")}
          required
          field={
            <DatePicker
              name="hardDeadline"
              placeholder={t("form.placeholders.surveyHardDeadline")}
              control={form.control}
            />
          }
        />
      </Col>
    </Row>
  );
};

SurveyDetails.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    hardDeadline: PropTypes.string.isRequired,
    softDeadline: PropTypes.string,
  }).isRequired,
};

export default SurveyDetails;
