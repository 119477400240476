import { App, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PropTypes from "prop-types";

import PrimaryButton from "../../../components/PrimaryButton.jsx";
import FormField from "../../../components/form/FormField.jsx";
import Input from "../../../components/form/Input.jsx";
import {
  useCreateTopicMutation,
  useUpdateTopicMutation,
} from "../../../redux/service.js";
import { handleErrors } from "../../../utilities/index.js";
import { useEffect } from "react";

const surveyUpdateSchema = (t) =>
  yup.object().shape({
    title: yup
      .string()
      .typeError(t("form.validation.titleNotValid"))
      .required(t("form.validation.titleRequired")),
    description: yup
      .string()
      .typeError(t("form.validation.descriptionNotValid"))
      .required(t("form.validation.descriptionRequired")),
  });

const TopicCreateModal = ({ open, onCancel, initialData }) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const [createTopic] = useCreateTopicMutation();
  const [updateTopic] = useUpdateTopicMutation();

  const form = useForm({
    resolver: yupResolver(surveyUpdateSchema(t)),
    mode: "onChange",
    defaultValues: {
      title: "",
      description: "",
    },
  });

  useEffect(() => {
    if (initialData) {
      form.reset({
        title: initialData.title,
        description: initialData.description,
      });
    }
  }, [initialData]);

  const onSubmit = (values) => {
    let mutation;
    if (initialData?.id) {
      mutation = updateTopic({ id: initialData.id, data: values });
    } else {
      mutation = createTopic(values);
    }

    mutation
      .unwrap()
      .then(() => {
        notification.success({
          message: initialData?.id ? t("topicUpdated") : t("topicCreated"),
        });
        onCancel();
        form.reset({
          title: "",
          description: "",
        });
      })
      .catch((errors) => handleErrors(errors, form.setError, notification));
  };

  const handleClose = () => {
    form.reset();
    onCancel();
  };

  return (
    <Modal
      open={open}
      title={initialData?.id ? t("updateTopic") : t("createTopic")}
      onCancel={onCancel}
      width={800}
      destroyOnClose
      footer={[
        <PrimaryButton key="cancel" onClick={handleClose}>
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton key="confirm" onClick={form.handleSubmit(onSubmit)}>
          {t("buttons.submit")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <FormField
        label={t("form.labels.title")}
        required
        field={<Input name="title" control={form.control} />}
      />
      <FormField
        label={t("form.labels.description")}
        required
        field={
          <Input name="description" type="textarea" control={form.control} />
        }
      />
    </Modal>
  );
};

TopicCreateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialData: PropTypes.object,
};

TopicCreateModal.defaultProps = {
  initialData: null,
};

export default TopicCreateModal;
