import PropTypes from "prop-types";
import { Typography } from "antd";

const { Title } = Typography;

const FieldError = ({ errors }) => {
  let renderErrors = [];

  if (Array.isArray(errors)) {
    renderErrors = errors.map((i) => i.message);
  } else {
    renderErrors = [errors.message];
  }

  return [...new Set(renderErrors)].map((i) => (
    <Title key={i} level={5} type="danger" style={{ marginTop: "0" }}>
      {i}
    </Title>
  ));
};

FieldError.propTypes = {
  errors: PropTypes.any.isRequired,
};

export default FieldError;
