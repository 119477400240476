const notificationsService = (builder) => ({
  getNotificationsByReadStatus: builder.query({
    query: ({ isRead, page, pageLimit = 10 }) =>
      `/notifications/?is_read=${isRead}${page ? `&page=${page}&page_limit=${pageLimit}` : ""}`,
    providesTags: (result) =>
      result
        ? result.results.map((i) => ({ type: "Notifications", id: i.id }))
        : [],
  }),
  readNotifications: builder.mutation({
    query: () => ({
      url: "/notifications/read/",
      method: "GET",
    }),
    invalidatesTags: (result) =>
      result ? result.map((i) => ({ type: "Notifications", id: i.id })) : [],
  }),
  getUnreadNotificationsCount: builder.query({
    query: () => ({
      url: "/notifications/unread-count/",
      method: "GET",
    }),
    providesTags: (result) => (result ? [{ type: "Notifications" }] : []),
  }),
  getNotificationSettingsForUser: builder.query({
    query: () => ({
      url: "/notifications/settings/",
      method: "GET",
    }),
    providesTags: (result) => (result ? [...result.map((i) => ({ type: "NotificationSettings", id: i.id })), { type: "NotificationSettings" }] : []),
  }),
  patchNotificationSettings: builder.mutation({
    query: ({ id, data }) => ({
      url: `/notifications/settings/${id}/`,
      method: "PATCH",
      data: data,
    }),
    invalidatesTags: [{ type: "NotificationSettings" }],
  }),
  getEmailSettingsForUser: builder.query({
    query: () => ({
      url: "/emails/settings/",
      method: "GET",
    }),
    providesTags: (result) => (result ? [...result.map((i) => ({ type: "EmailSettings", id: i.id })), { type: "EmailSettings" }] : []),
  }),
  patchEmailSettings: builder.mutation({
    query: ({ id, data }) => ({
      url: `/emails/settings/${id}/`,
      method: "PATCH",
      data: data,
    }),
    invalidatesTags: [{ type: "EmailSettings" }],
  }),
});

export default notificationsService;
