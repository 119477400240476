import { Col, Row, Typography } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const PrincipleStatistics = ({ principle }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{ display: "flex", marginLeft: "auto", color: "red !important" }}
    >
      <div style={{ display: "flex" }} className="me-3">
        <Text
          style={{ textTransform: "uppercase", color: principle.color }}
          className="me-2"
        >
          {t("labels.questions")}
        </Text>
        <div>
          <Title
            level={5}
            style={{
              fontWeight: "bold",
              color: principle.color,
            }}
          >
            {principle.answeredQuestionsCount}/
            {principle.requiredQuestionsCount}
          </Title>
        </div>
      </div>
      <div style={{ display: "flex" }} className="me-3">
        <div>
          <Text
            style={{ textTransform: "uppercase", color: principle.color }}
            className="me-2"
          >
            {t("labels.objects", { plural: "s" })}
          </Text>
        </div>
        <div>
          <Title
            level={5}
            style={{
              fontWeight: "bold",
              color: principle.color,
            }}
          >
            {principle.answeredObjectsCount}/{principle.requiredObjectsCount}
          </Title>
        </div>
      </div>
      <div style={{ display: "flex" }} className="me-2">
        <Text
          style={{ textTransform: "uppercase", color: principle.color }}
          className="me-2"
        >
          {t("labels.narratives", { plural: "s" })}
        </Text>
        <div>
          <Title
            level={5}
            style={{
              fontWeight: "bold",
              color: principle.color,
            }}
          >
            {principle.answeredNarrativesCount}/
            {principle.requiredNarrativesCount}
          </Title>
        </div>
      </div>
    </div>
  );
};

PrincipleStatistics.propTypes = {
  principle: PropTypes.object.isRequired,
};

const PrincipleVerticalBar = ({ principle }) => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "5px",
          backgroundColor: principle.color,
        }}
      />
      <Row className="mt-2">
        <Col xl={12} md={10} sm={24}>
          <Title
            level={5}
            className="mt-1 ps-3"
            style={{ color: principle.color }}
          >
            {principle.title}
          </Title>
        </Col>
        <Col
          xl={12}
          md={14}
          sm={24}
          style={{ display: "flex", justifyContent: "end" }}
        >
          {principle?.statistics && (
            <PrincipleStatistics principle={principle.statistics} />
          )}
        </Col>
      </Row>
    </div>
  );
};

PrincipleVerticalBar.propTypes = {
  principle: PropTypes.object.isRequired,
};

export default PrincipleVerticalBar;
