import { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Checkbox, Radio, Typography } from "antd";
import { useDispatch } from "react-redux";

import {
  commonsApi,
  useCreateAnswerMutation,
  useDeleteAnswerMutation,
  useGetCurrentUserQuery,
  useLazyGetAnswerByIdQuery,
  usePatchAnswerMutation, useUpdateAnswerMutation,
} from "../../../../redux/service.js";
import { OptionType } from "../../../../utilities/constants.jsx";
import DebouncedInput from "../../../../components/form/DebouncedInput.jsx";
import { OptionEntities } from "./OptionEntities.jsx";

const { Text } = Typography;

const Option = ({ option, answerId }) => {
  const [createAnswer] = useCreateAnswerMutation();
  const [updateAnswer] = useUpdateAnswerMutation();
  const [patchAnswer] = usePatchAnswerMutation();
  const [deleteAnswer] = useDeleteAnswerMutation();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [getAnswerById, answerResponse] = useLazyGetAnswerByIdQuery();
  const dispatch = useDispatch();

  const [answer, setAnswer] = useState(null);
  const optionBorder = {
    borderRadius: 0,
    border: "1px solid rgba(185, 187, 198, 1)",
  };

  useEffect(() => {
    if (answerId) {
      getAnswerById({ id: answerId });
    }
  }, [answerId]);

  useEffect(() => {
    setAnswer(answerResponse.isSuccess ? answerResponse.data : null);
    setShowTextInput(
      answerResponse.isSuccess
        ? option.type === OptionType.CHOICE_TEXT.value
        : false,
    );
    if (answerResponse.isSuccess) {
      dispatch(
        commonsApi.util.updateQueryData(
          "getQuestionById",
          { questionId: option.question },
          (question) => ({
            ...question,
            options: question.options.map((i) => {
              if (i.id === answerResponse.data.option) {
                return { ...i, answer: answerResponse.data.id };
              }
              return i;
            }),
          }),
        ),
      );
    }
  }, [answerResponse]);

  const [showTextInput, setShowTextInput] = useState(false);

  useEffect(
    () =>
      setShowTextInput(
        answer ? ![null, undefined].includes(answer?.textAnswer) : false,
      ),
    [answer],
  );

  const shouldShowOptionEntitiesButtons = useMemo(() => {
    if (option.type === OptionType.CHOICE_TEXT.value && showTextInput) {
      return true;
    } else if (answer && answer?.option === option.id) {
      return true;
    } else if (
      [OptionType.OBJECT.value, OptionType.NARRATIVE.value].includes(
        option.type,
      )
    ) {
      return true;
    }
    return false;
  }, [answer, option]);

  const selectMultipleChoiceOption = (isChecked, option) => {
    if (isChecked) {
      const data = {
        option: option.id,
        question: option.question,
        organization: currentUser.organization.id,
      };
      createAnswer(data)
        .unwrap()
        .then((data) => getAnswerById({ id: data.id }));
    } else if (answer) {
      deleteAnswer(answer.id, answer.question)
        .unwrap()
        .then(() => setAnswer(null));
    }
  };

  const selectSingleChoiceOption = async (selectedOption) => {
      if (answer) {
        const answerResponse = await updateAnswer({
          id: answer.id,
          data: {
            option: selectedOption.id,
            question: option.question,
            organization: currentUser.organization.id,
          },
        });
        const data = await getAnswerById({ id: answerResponse.data.id });
        setAnswer(data);
      } else {
        const answerResponse = await createAnswer({
          option: selectedOption.id,
          question: option.question,
          organization: currentUser.organization.id,
        })
        const data = await getAnswerById({ id: answerResponse.data.id });
        setAnswer(data);
      }
  };

  const handleTextAnswer = (textAnswer) => {
    if (answer) {
      patchAnswer({ id: answer.id, data: { textAnswer } })
        .unwrap()
        .then((data) => setAnswer(data));
    } else {
      createAnswer({
        option: option.id,
        question: option.question,
        organization: currentUser.organization.id,
        textAnswer,
      })
        .unwrap()
        .then((data) => getAnswerById({ id: data.id }));
    }
  };

  const handleTextChoiceSelect = (isChecked) => {
    if (!isChecked && answer) {
      deleteAnswer(answer.id)
        .unwrap()
        .then(() => setAnswer(null));
    }
    setShowTextInput(isChecked);
  };

  let children = null;
  if (option.type === OptionType.SINGLE_CHOICE.value) {
    children = (
      <div style={{ display: "flex", color: "red" }}>
        <Radio
          value={option.id}
          checked={option.id === answer?.option}
          onChange={() => selectSingleChoiceOption(option)}
        />
        <Text
          className="ms-2"
          style={{ color: "rgba(28, 32, 36, 1)", fontWeight: 500 }}
        >
          {option.label}
        </Text>
      </div>
    );
  } else if (option.type === OptionType.MULTIPLE_CHOICE.value) {
    children = (
      <div style={{ display: "flex" }}>
        <Checkbox
          checked={answer}
          onChange={(e) => selectMultipleChoiceOption(e.target.checked, option)}
        />
        <Text
          className="ms-2"
          style={{ color: "rgba(28, 32, 36, 1)", fontWeight: 500 }}
        >
          {option.label}
        </Text>
      </div>
    );
  } else if (option.type === OptionType.CHOICE_TEXT.value) {
    children = (
      <>
        <div style={{ display: "flex" }}>
          <Checkbox
            checked={showTextInput}
            onChange={(e) => handleTextChoiceSelect(e.target.checked)}
          />
          <Text
            className="ms-2"
            style={{ color: "rgba(28, 32, 36, 1)", fontWeight: 500 }}
          >
            {option.label}
          </Text>
        </div>
        {showTextInput && (
          <DebouncedInput
            initialValue={answer?.textAnswer || ""}
            onChange={(value) => handleTextAnswer(value)}
            className="mt-2"
          />
        )}
      </>
    );
  } else if (option.type === OptionType.TEXT.value) {
    children = (
      <DebouncedInput
        initialValue={answer?.textAnswer || ""}
        onChange={(value) => handleTextAnswer(value)}
        rows={option.textFieldRows}
        className="mt-2"
      />
    );
  }

  return (
    <div key={option.id} style={{ background: "white" }}>
      <div className="mb-2 py-2 px-3" style={optionBorder}>
        {children}
        {shouldShowOptionEntitiesButtons && (
          <OptionEntities option={option} answer={answer} />
        )}
      </div>
    </div>
  );
};

Option.propTypes = {
  answerId: PropTypes.number,
  option: PropTypes.object.isRequired,
};

Option.defaultProps = {
  answerId: null,
};

export default Option;
