import { theme, Typography } from "antd";
import { useTranslation } from "react-i18next";

import Tabs from "../../components/Tabs.jsx";
import AccountSettings from "./components/AccountSettings.jsx";
import NotificationSettings from "./components/NotificationSettings.jsx";

const { Title } = Typography;


const Settings = () => {
  const { token } = theme.useToken();
  const { t } = useTranslation();


  return (
    <div className="ms-5 w-50">
      <Title
        level={2}
        className="mb-4"
        style={{ color: token.colorPrimaryText, fontWeight: "bold" }}
      >
        {t("manageAccount")}
      </Title>
    <Tabs
      type="card"
      items={[
        {
          label: t("labels.account"),
          key: "account",
          children: <AccountSettings />,
        },
        {
          label: t("labels.notifications"),
          key: "notifications",
          children: <NotificationSettings />,
        },
      ]}
    />
    </div>
  );
};

export default Settings;
