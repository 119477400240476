import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { App, theme, Typography } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import FormField from "../../components/form/FormField.jsx";
import Input from "../../components/form/Input.jsx";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import Checkbox from "../../components/form/Checkbox.jsx";
import {
  useCreateDiscussionMutation,
  useGetTopicsQuery,
  useLazyGetDiscussionByIdQuery,
  useUpdateDiscussionMutation,
  useLazyGetSubjectsQuery,
} from "../../redux/service.js";
import Select from "../../components/form/Select.jsx";
import { handleErrors } from "../../utilities/index.js";
import ContentField from "./components/ContentField.jsx";

const { Title } = Typography;

const discussionSchema = (t) =>
  yup.object().shape({
    title: yup
      .string()
      .typeError(t("form.validation.titleNotValid"))
      .required(t("form.validation.titleRequired")),
    content: yup
      .string()
      .typeError(t("form.validation.bodyNotValid"))
      .required(t("form.validation.bodyRequired")),
    topicIds: yup
      .array()
      .typeError(t("form.validation.topicsNotValid"))
      .required(t("form.validation.topicsRequired"))
      .min(1, t("form.validation.topicsMinimum")),
    subjects: yup
      .array()
      .typeError(t("form.validation.tagsNotValid"))
      .required(t("form.validation.tagsRequired"))
      .min(1, t("form.validation.tagsMinimum")),
    notificationsEnabled: yup
      .bool()
      .typeError(t("form.validation.notificationsEnabledNotValid")),
    narratives: yup.array(),
    objects: yup.array(),
    surveyViews: yup.array(),
  });

const DiscussionForm = () => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const { discussionId } = useParams();
  const location = useLocation();

  const [getDiscussionById, { data: existingDiscussionData }] =
    useLazyGetDiscussionByIdQuery();
  const [createDiscussion, createDiscussionResponse] =
    useCreateDiscussionMutation();
  const [updateDiscussion] = useUpdateDiscussionMutation();
  const [
    getSubjects,
    { data: subjectsResponse, isSuccess: subjectsIsSuccess },
  ] = useLazyGetSubjectsQuery();
  const { data: topicsResponse, isSuccess: topicsIsSuccess } =
    useGetTopicsQuery({ pagination: "off" });

  const form = useForm({
    resolver: yupResolver(discussionSchema(t)),
    mode: "onChange",
    defaultValues: {
      title: "",
      topicIds: null,
      subjects: null,
      content: "",
      notificationsEnabled: false,
      narratives: [],
      objects: [],
      surveyViews: [],
    },
  });

  useEffect(() => {
    if (location.state?.object) {
      form.setValue("objects", [location.state.object]);
    }
    if (location.state?.surveyView) {
      form.setValue("surveyViews", [location.state.surveyView]);
    }
  }, [location]);

  useEffect(() => {
    if (discussionId) {
      getDiscussionById(discussionId);
    } else {
      getSubjects({ pagination: "off" });
    }
  }, [discussionId]);

  useEffect(() => {
    if (existingDiscussionData) {
      form.reset({
        title: existingDiscussionData.title,
        topicIds: existingDiscussionData.topics,
        subjects: existingDiscussionData.subjects.map((i) => i.name),
        content: existingDiscussionData.content,
        notificationsEnabled: existingDiscussionData.notificationsEnabled,
        narratives: existingDiscussionData.attachedNarratives,
        objects: existingDiscussionData.attachedObjects,
        surveyViews: existingDiscussionData.surveyViews,
      });
      getSubjects({
        pagination: "off",
      });
    }
  }, [existingDiscussionData]);

  const handleSubmit = (values) => {
    let mutationFunction;
    const data = {
      ...values,
      attachedObjectIds: values.objects.map((i) => i.id),
      attachedNarrativeIds: values.narratives.map((i) => i.id),
      attachedSurveyViewUuids: values.surveyViews.map((i) => i.uuid),
      subjects: values.subjects.map((i) => ({ name: i })),
    };
    if (discussionId) {
      mutationFunction = updateDiscussion({ id: discussionId, data });
    } else {
      mutationFunction = createDiscussion(data);
    }

    mutationFunction
      .unwrap()
      .then((data) => {
        navigate(`/discussions/${data.id}`);
        notification.success({
          message: discussionId
            ? t("discussionUpdated")
            : t("newDiscussionCreated"),
        });
      })
      .catch((errors) => handleErrors(errors, form.setError, notification));
  };

  return (
    <div className="mb-5">
      <Title level={3} style={{ color: token.colorTextThird }}>
        {discussionId ? t("updateDiscussion") : t("newDiscussion")}
      </Title>
      <div className="mt-4">
        <div className="w-50">
          <FormField
            label={t("form.labels.title")}
            required
            field={
              <Input
                name="title"
                placeholder={t("form.placeholders.title")}
                control={form.control}
              />
            }
          />
          <FormField
            label={t("form.labels.topics")}
            required
            field={
              <Select
                mode="multiple"
                name="topicIds"
                options={
                  topicsIsSuccess
                    ? topicsResponse.map((i) => ({
                        value: i.id,
                        label: i.title,
                      }))
                    : []
                }
                placeholder={t("form.placeholders.topics")}
                control={form.control}
              />
            }
          />
          <FormField
            label={t("form.labels.tags")}
            required
            field={
              <Select
                mode="tags"
                name="subjects"
                options={
                  subjectsIsSuccess
                    ? subjectsResponse.map((i) => ({
                        value: i.name,
                        label: i.name,
                      }))
                    : []
                }
                placeholder={t("form.placeholders.subjectsSelect")}
                control={form.control}
              />
            }
          />
        </div>
        <ContentField form={form} label={t("form.labels.body")}>
          <div style={{ display: "inline-block", marginRight: 0 }}>
            <Checkbox
              name="notificationsEnabled"
              label={t("form.labels.emailNotificationsComments")}
              control={form.control}
            />
            <div className="mt-3">
              <PrimaryButton
                type="tertiary"
                className="me-4"
                onClick={() => navigate("/community")}
              >
                {t("buttons.cancel")}
              </PrimaryButton>
              <PrimaryButton
                disabled={createDiscussionResponse.isLoading}
                onClick={form.handleSubmit(handleSubmit)}
              >
                {t("buttons.post")}
              </PrimaryButton>
            </div>
          </div>
        </ContentField>
      </div>
    </div>
  );
};

export default DiscussionForm;
