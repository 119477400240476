import {useEffect, useMemo, useState} from "react";

import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { App, Col, Row, Spin, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  useLazyGetCurrentUserQuery, useLazyGetOrganizationByIdQuery,
  useLazyGetPrinciplesQuery,
  useLazyGetSurveyByIdQuery,
  useLazyGetSurveyStatisticsQuery,
  useLazyGetSurveyViewByIdQuery, useLazyIncrementSurveyViewViewsQuery,
} from "../../../../redux/service.js";
import { Roles, SharingRights } from "../../../../utilities/constants.jsx";
import PrimaryButton from "../../../../components/PrimaryButton.jsx";
import DownloadSurvey from "../DownloadSurvey.jsx";
import SurveyViewDetails from "./SurveyViewDetails.jsx";

const { Title } = Typography;

export class InsufficientPermissions extends Error {
  constructor(message = "", navigateTo = null) {
    super(message);
    this.message = message;
    this.navigateTo = navigateTo;
  }
}

const SurveyView = () => {
  const { notification } = App.useApp();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const { surveyId } = useParams();
  const location = useLocation();

  const [getSurveyById, surveyResponse] = useLazyGetSurveyByIdQuery();
  const [getSurveyViewById, surveyViewResponse] =
    useLazyGetSurveyViewByIdQuery();
  const [getCurrentUser, { isLoading: isCurrentUserLoading }] =
    useLazyGetCurrentUserQuery();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [getPrinciples, principlesResponse] = useLazyGetPrinciplesQuery();
  const [getSurveyStatics, surveyStatisticsResponse] =
    useLazyGetSurveyStatisticsQuery();
  const [incrementSurveyViewViews] = useLazyIncrementSurveyViewViewsQuery();
  const [getOrganizationById, organizationResponse] =
    useLazyGetOrganizationByIdQuery();
  const [filteredQuestions, setFilteredQuestions] = useState([]);

  const getSurveyViewData = async () => {
    const principles = [];
    const questions = [];
    const response = await getSurveyViewById(searchParams.get("view"));
    if (
      response.data.sharingRights !== SharingRights.PUBLIC.value &&
      !isAuthenticated
    ) {
      throw new InsufficientPermissions(t("surveyViewNotPublic"), "/login");
    }

    (response.data?.answers || []).forEach((i) => {
      principles.push(i.principle);
      questions.push(i.question);
    });
    const organization = response.data.organization.id;

    return { organization, principles, questions };
  };

  const getSurveyData = async () => {
    /**
     * Retrieves the survey data along with the survey view, if specified.
     * The view parameter must be present if the user tries to look up a report of a different organization. If
     * it's not specified, the data of the users' organization will be returned.
     */
    let filters = { organization: null, principles: [], questions: null };
    if (searchParams.has("view")) {
      filters = await getSurveyViewData();
    } else {
      if (!isAuthenticated) {
          throw new InsufficientPermissions(t("viewRequired"), "/login");
      }

     if (location.pathname.includes("public")) {
        navigate(`/reports/${surveyId}/report${location.search}`, {
          replace: true,
        });
      }

      const response = await getCurrentUser();
      if (response.isSuccess) {
        if (response.data.role === Roles.ADMIN.value) {
          if (!searchParams.has("organization")) {
            throw new InsufficientPermissions(
              t("organizationRequiredParameter"),
              "/",
            );
          }
          filters.organization = searchParams.get("organization");
        } else {
          filters.organization = response.data.organization.id
        }
      }
    }

    getSurveyById({
      surveyId,
      filters: { organization: filters.organization },
    });
    getPrinciples({
      ids: filters.principles,
      survey: surveyId,
    });
    setFilteredQuestions(filters.questions);
    getSurveyStatics({
      survey: surveyId,
      organization: filters.organization,
    });
    if (filters.organization) {
      getOrganizationById(filters.organization);
    }
  };

  const retrieveData = () => {
    getSurveyData().catch((err) => {
      if (err instanceof InsufficientPermissions) {
        notification.error({ message: err.message, key: err.message });
        navigate(err.navigateTo);
        return;
      }
      throw err;
    });
  }

  useEffect(() => {
    retrieveData();

    if (isAuthenticated && searchParams.has("view")) {
      setTimeout(() => {
        incrementSurveyViewViews(searchParams.get("view"));
      }, 15000);
    }
  }, [isAuthenticated]);

  const organizationName = useMemo(() => {
    if (organizationResponse.isSuccess) {
      return organizationResponse.data.name;
    }
    if (surveyViewResponse.isSuccess) {
      return surveyViewResponse.data.organization.title;
    }
    return null;
  }, [organizationResponse]);

  if (surveyResponse.isLoading || isCurrentUserLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  if (surveyResponse.isError) {
    return <Navigate to="/" />;
  }

  return surveyResponse.isSuccess ? (
    <div
      style={
        isAuthenticated
          ? { width: "90%" }
          : { width: "85%", margin: "100px auto 0" }
      }
    >
      <Title className="ps-3 mb-3" style={{ color: token.colorTextThird }} level={2}>{organizationName}</Title>
      <Row className="ps-3">
        <Col xl={10} lg={10} md={24} sm={24} style={{ display: "flex" }}>
          <Title
            level={2}
            style={{ alignSelf: "center", color: token.colorTextThird }}
          >
            {surveyResponse.data.title}
          </Title>
        </Col>

            <Col
          xl={10}
          lg={10}
          md={18}
          sm={18}
          style={{
            display: "flex",
            justifyContent: surveyViewResponse.isSuccess ? "center" : "end",
            margin: "auto",
          }}
        >
          <DownloadSurvey
            survey={surveyResponse.data}
            filters={{
              view: searchParams.get("view"),
              organization: searchParams.get("organization"),
            }}
          >
            <PrimaryButton>{t("buttons.downloadReport")}</PrimaryButton>
          </DownloadSurvey>
           {surveyViewResponse.isSuccess && (
               <div className="ms-4">
              <PrimaryButton
                onClick={() => navigate("/discussions/form", {
                      state: { surveyView: surveyViewResponse.data },
                    })}
              >{t("buttons.shareToCommunity")}</PrimaryButton>
            </div>
           )}
        </Col>
      </Row>
      {principlesResponse.isSuccess && (
        <SurveyViewDetails
          principlesResponse={principlesResponse}
          surveyStatisticsResponse={surveyStatisticsResponse}
          surveyViewResponse={surveyViewResponse}
          filteredQuestions={filteredQuestions}
        />
      )}
    </div>
  ) : null;
};

export default SurveyView;
