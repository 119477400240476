import { DateTime } from "luxon";
import { App, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  useExportUserDataMutation,
  useGetExportedUserDataQuery,
} from "../../../redux/service.js";
import PrimaryButton from "../../../components/PrimaryButton.jsx";

const { Title, Text } = Typography;

const RetrieveData = ({ userId }) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const { data: exportedData, isSuccess: isExportedDataSuccess } =
    useGetExportedUserDataQuery(userId);
  const [exportData] = useExportUserDataMutation();

  const handleRetrieveData = () => {
    exportData(userId)
      .unwrap()
      .then(() =>
        notification.success({ message: t("organizationDataExportCsv") }),
      )
      .catch((error) => {
        if (error.status === 400) {
          notification.error({ message: error.data.errors[0].detail });
        }
      });
  };

  return (
    <div>
      <Title level={4}>{t("retrieveData")}</Title>
      <PrimaryButton
        disabled={isExportedDataSuccess}
        className="mt-2"
        onClick={handleRetrieveData}
      >
        {t("buttons.export")}
      </PrimaryButton>
      {isExportedDataSuccess && (
        <div>
          <Text>
            {t("dataRetrievedOn", {
              date: DateTime.fromISO(exportedData.createdAt).toFormat(
                "dd MMMM yyyy",
              ),
              endDate: DateTime.fromISO(exportedData.endDate).toFormat(
                "dd MMMM yyyy",
              ),
            })}
          </Text>
          &nbsp;
          <Text>
            {t("clickHereTo")}&nbsp;
            <a href={exportedData.file}>{t("buttons.downloadLowerCase")}</a>.
          </Text>
        </div>
      )}
    </div>
  );
};

RetrieveData.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default RetrieveData;
