import { useState } from "react";

import { App, Col, Modal, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PrimaryButton from "../../../components/PrimaryButton.jsx";
import Upload from "../../../components/form/Upload.jsx";
import { useImportOrganizationsFromCsvMutation } from "../../../redux/service.js";
import FormField from "../../../components/form/FormField.jsx";
import Input from "../../../components/form/Input.jsx";

const Errors = ({ errors }) => {
  return (
    <div>
      {errors.map((error) => (
        <div key={error.rowIndex}>
          <ul>
            <li>Row Index - {error.rowIndex}</li>
            <li>Row Data - {error.row.join(", ")}</li>
            <li>
              Row Errors -{" "}
              {Array.isArray(error.errors)
                ? error.errors.join(", ")
                : JSON.stringify(error.errors)}
            </li>
          </ul>
          <hr />
        </div>
      ))}
    </div>
  );
};

Errors.propTypes = {
  errors: PropTypes.array.isRequired,
};

const importCsvSchema = (t) =>
  yup.object().shape({
    file: yup.mixed().required(t("form.validation.fileRequired")),
    adminEmailIndex: yup
      .number()
      .typeError(t("form.validation.adminEmailIndexRequired"))
      .required(t("form.validation.adminEmailIndexRequired")),
    orgNameIndex: yup
      .number()
      .typeError(t("form.validation.orgNameIndexRequired"))
      .required(t("form.validation.orgNameIndexRequired")),
    addressIndex: yup
      .number()
      .typeError(t("form.validation.addressIndexRequired"))
      .required(t("form.validation.addressIndexRequired")),
    organizationSizeIndex: yup
      .number()
      .typeError(t("form.validation.organizationSizeIndexNotValid"))
      .nullable(),
    countryIndex: yup
      .number()
      .typeError(t("form.validation.countryIndexNotValid"))
      .nullable(),
    websiteIndex: yup
      .number()
      .typeError(t("form.validation.websiteIndexNotValid"))
      .nullable(),
    phoneNumberIndex: yup
      .number()
      .typeError(t("form.validation.phoneNumberIndexNotValid"))
      .nullable(),
    externalIdIndex: yup
      .number()
      .typeError(t("form.validation.externalIdIndexNotValid"))
      .nullable(),
  });

const ImportOrganizationModal = ({ open, onCancel }) => {
  const { t } = useTranslation();
  const [importCsv, importCsvResponse] =
    useImportOrganizationsFromCsvMutation();
  const { notification } = App.useApp();
  const [errors, setErrors] = useState(null);

  const form = useForm({
    resolver: yupResolver(importCsvSchema(t)),
    mode: "onChange",
    defaultValues: {
      file: null,
      adminEmailIndex: null,
      orgNameIndex: null,
      addressIndex: null,
      phoneNumberIndex: null,
      websiteIndex: null,
      countryIndex: null,
      organizationSizeIndex: null,
      externalIdIndex: null,
    },
  });

  const handleSubmit = async () => {
    const isValid = await form.trigger();
    if (isValid) {
      const formData = new FormData();
      const data = form.getValues();
      Object.keys(data).forEach((i) => {
        if (![null, undefined].includes(data[i])) {
          formData.set(i, data[i]);
        }
      });

      importCsv(formData)
        .unwrap()
        .then((data) => {
          setErrors(data.errors);
          if (data.errors.length === 0) {
            onCancel();
            form.reset();
          } else {
            setErrors(data.errors);
          }
          notification.success({
            message: t("importedOrganization", {
              orgCount: data.importedOrganizationsCount,
            }),
          });
        });
    }
  };

  return (
    <Modal
      open={open}
      destroyOnClose
      width={800}
      title={t("form.labels.importOrganizations")}
      onCancel={onCancel}
      footer={[
        importCsvResponse.isLoading ? (
          <Spin size="large" className="me-4" />
        ) : (
          <PrimaryButton key="success" onClick={handleSubmit}>
            {t("buttons.submit")}
          </PrimaryButton>
        ),
        !importCsvResponse.isLoading && (
          <PrimaryButton key="cancel" onClick={onCancel}>
            {t("buttons.close")}
          </PrimaryButton>
        ),
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <div className="ps-5">
        {errors && <Errors errors={errors} />}
        <Row className="mb-5">
          <Col span={11} className="px-3">
            <FormField
              label={t("form.labels.adminEmailIndex")}
              required
              field={
                <Input
                  placeholder={t("form.labels.adminEmailIndex")}
                  htmlType="number"
                  name="adminEmailIndex"
                  control={form.control}
                />
              }
            />
          </Col>
          <Col span={11} className="px-3">
            <FormField
              label={t("form.labels.orgNameIndex")}
              required
              field={
                <Input
                  placeholder={t("form.labels.orgNameIndex")}
                  htmlType="number"
                  name="orgNameIndex"
                  control={form.control}
                />
              }
            />
          </Col>
          <Col span={11} className="px-3">
            <FormField
              label={t("form.labels.addressIndex")}
              required
              field={
                <Input
                  placeholder={t("form.labels.addressIndex")}
                  htmlType="number"
                  name="addressIndex"
                  control={form.control}
                />
              }
            />
          </Col>
          <Col span={11} className="px-3">
            <FormField
              label={t("form.labels.organizationSizeIndex")}
              field={
                <Input
                  placeholder={t("form.labels.organizationSizeIndex")}
                  htmlType="number"
                  name="organizationSizeIndex"
                  control={form.control}
                />
              }
            />
          </Col>
          <Col span={11} className="px-3">
            <FormField
              label={t("form.labels.countryIndex")}
              field={
                <Input
                  placeholder={t("form.labels.countryIndex")}
                  htmlType="number"
                  name="countryIndex"
                  control={form.control}
                />
              }
            />
          </Col>
          <Col span={11} className="px-3">
            <FormField
              label={t("form.labels.websiteIndex")}
              field={
                <Input
                  placeholder={t("form.labels.websiteIndex")}
                  htmlType="number"
                  name="websiteIndex"
                  control={form.control}
                />
              }
            />
          </Col>
          <Col span={11} className="px-3">
            <FormField
              label={t("form.labels.phoneNumberIndex")}
              field={
                <Input
                  placeholder={t("form.labels.phoneNumberIndex")}
                  htmlType="number"
                  name="phoneNumberIndex"
                  control={form.control}
                />
              }
            />
          </Col>
          <Col span={11} className="px-3">
            <FormField
              label={t("form.labels.externalIdIndex")}
              field={
                <Input
                  placeholder={t("form.labels.externalIdIndex")}
                  htmlType="number"
                  name="externalIdIndex"
                  control={form.control}
                />
              }
            />
          </Col>
        </Row>
        <FormField
          label={t("form.labels.file")}
          required
          field={
            <Upload
              name="file"
              type="dropzone"
              buttonTitle={t("buttons.uploadCsvFile")}
              supportedFormats={[".csv"]}
              control={form.control}
            />
          }
        />
      </div>
    </Modal>
  );
};

ImportOrganizationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ImportOrganizationModal;
