import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

import PrincipleVerticalBar from "../PrincipleVerticalBar.jsx";
import { Question } from "./Question.jsx";
import PropTypes from "prop-types";

const SurveyViewDetails = ({
  principlesResponse,
  surveyStatisticsResponse,
  surveyViewResponse,
  filteredQuestions,
}) => {
  return (
    <>
      <Collapse
        defaultActiveKey={[]}
        style={{
          backgroundColor: "white",
        }}
        expandIconPosition="end"
        expandIcon={({ isActive }) =>
          isActive ? <DownOutlined /> : <UpOutlined />
        }
        className="mt-2 mb-4"
        items={principlesResponse.data.results
          .slice()
          .sort((a, b) => a.index - b.index)
          .map((principle) => ({
            key: principle.id,
            label: surveyStatisticsResponse.isSuccess && (
              <PrincipleVerticalBar
                principle={{
                  ...principle,
                  statistics: surveyStatisticsResponse.data.find(
                    (i) => i.id === principle.id,
                  ),
                }}
              />
            ),
            children: (
              <div key={principle.id} className="mt-3">
                  {principle.questions.filter((i) => filteredQuestions === null || filteredQuestions.includes(i)).map((question, index) => (
                    <Question
                      key={question}
                      questionIndex={index}
                      questionId={question}
                      surveyView={surveyViewResponse.data}
                    />
                  ))}
              </div>
            ),
          }))}
      />
    </>
  );
};

SurveyViewDetails.propTypes = {
  principlesResponse: PropTypes.object.isRequired,
  surveyStatisticsResponse: PropTypes.object.isRequired,
  surveyViewResponse: PropTypes.object.isRequired,
  filteredQuestions: PropTypes.array,
};

SurveyViewDetails.defaultProps = {
    filteredQuestions: null,
};

export default SurveyViewDetails;
