import { Tag, theme } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const CustomTag = ({ label, closable, onClose, ...props }) => {
  const { token } = theme.useToken();

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={token.colorBackgroundSecondary}
      onMouseDown={onPreventMouseDown}
      onClose={onClose}
      className="custom-tag mt-1 mb-1"
      {...props}
    >
      {closable && <CloseOutlined onClick={onClose} className="me-1" />}
      {label}
    </Tag>
  );
};

CustomTag.propTypes = {
  label: PropTypes.string.isRequired,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
};

CustomTag.defaultProps = {
  closable: false,
  onClose: null,
};

export default CustomTag;
