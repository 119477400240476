import { Tag } from "antd";
import PropTypes from "prop-types";

import { OrganizationRoles } from "../utilities/constants.jsx";

const RoleBadge = ({ roleName }) => {
  const role = OrganizationRoles.getItemByValue(roleName);
  return (
    role && (
      <Tag color={role.color} className="rounded-0">
        {role.label}
      </Tag>
    )
  );
};

RoleBadge.propTypes = {
  roleName: PropTypes.string,
};

RoleBadge.defaultProps = {
  roleName: "",
};

export default RoleBadge;
