import { Button, theme } from "antd";
import PropTypes from "prop-types";

const PrimaryButton = ({
  children,
  onClick,
  disabled,
  type,
  file,
  ...props
}) => {
  const { token } = theme.useToken();

  let backgroundColor;
  let color = "white";
  let border = null;
  if (disabled) {
    backgroundColor = "rgba(74, 105, 156, 0.6)";
  } else {
    if (type === "primary") {
      backgroundColor = token.backgroundColor;
    } else if (type === "secondary") {
      backgroundColor = token.colorBackgroundSecondary;
    } else if (type === "tertiary") {
      backgroundColor = "white";
      color = "rgba(74, 105, 156, 1)";
      border = `1px solid ${color}`;
    } else if (type === "dashed") {
      backgroundColor = "white";
      color = token.backgroundColor;
      border = `1px dashed ${token.backgroundColor}`;
    }
  }

  const styles = {
    color,
    borderRadius: 0,
    backgroundColor,
    border,
    ...(props?.style || {}),
  };

  const download = () => {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = file.url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleOnClick = (e) => {
    if (file) {
      download();
    } else {
      onClick(e);
    }
  };

  return (
    <Button
      onClick={handleOnClick}
      {...props}
      style={styles}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

PrimaryButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  style: {},
  type: "primary",
  file: null,
};

export default PrimaryButton;
