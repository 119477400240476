import { convertToFormData, createUrl } from "../../utilities/index.js";
import dayjs from "dayjs";

const transformRequestObjectData = (data) => {
  data.fields = Object.keys(data.fields)
    .filter((i) => ![undefined, null].includes(data.fields[i]))
    .map((i) => {
      if (i === "eventDateRange") {
        const startDate = dayjs.isDayjs(data.fields[i][0])
          ? data.fields[i][0].format("DD-MM-YYYY")
          : data.fields[i][0];
        const endDate = dayjs.isDayjs(data.fields[i][1])
          ? data.fields[i][1].format("DD-MM-YYYY")
          : data.fields[i][1];
        return {
          name: i,
          value: startDate + ":" + endDate,
        };
      }

      return {
        name: i,
        value: data.fields[i],
      };
    });

  if (dayjs.isDayjs(data.date)) {
    data.date = data.date.format("YYYY-MM-DD");
  }

  if (data?.evidence?.file) {
    data.evidence = {
      ...data.evidence,
      file: data.evidence.file,
      name: data.evidence.file.name,
    };
  }

  if (data.organization?.id) {
    data.organization = data.organization.id;
  }
  return data;
};

const objectsService = (builder) => ({
  paginateFilterObjects: builder.query({
    query: (queryParams) => ({
      url: createUrl("/objects/", queryParams),
      method: "GET",
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.results.map((i) => ({ type: "Objects", id: i.id })),
            { type: "Objects", id: "PARTIAL-LIST" },
          ]
        : [{ type: "Objects" }],
  }),
  createObject: builder.mutation({
    query: (data) => ({
      url: "/objects/",
      method: "POST",
      data: convertToFormData(transformRequestObjectData(data)),
    }),
    invalidatesTags: [{ type: "Objects" }],
  }),
  getObjectById: builder.query({
    query: (id) => ({
      url: `/objects/${id}/`,
      method: "GET",
    }),
    providesTags: (result) =>
      result ? [{ type: "Objects", id: result.id }] : [{ type: "Objects" }],
  }),
  editObject: builder.mutation({
    query: ({ id, data }) => ({
      url: `/objects/${id}/`,
      method: "PUT",
      data: convertToFormData(transformRequestObjectData(data)),
    }),
    invalidatesTags: () => [{ type: "Objects" }],
  }),
  deleteObject: builder.mutation({
    query: (id) => ({
      url: `/objects/${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: [{ type: "Objects" }],
  }),
  incrementObjectViews: builder.query({
    query: (id) => ({
      url: `/objects/${id}/views/`,
      method: "GET",
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Objects", id: result.id }] : [],
  }),
  requestObjectsExport: builder.query({
    query: (queryParams) => ({
      url: createUrl("/objects/export/", queryParams),
      method: "GET",
    }),
  }),
});

export default objectsService;
