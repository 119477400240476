import { useTranslation } from "react-i18next";

import {
  useDeleteTopicMutation,
  useLazyGetTopicsQuery,
} from "../../../redux/service.js";
import Table from "../../../components/Table.jsx";
import { useEffect, useState } from "react";
import TopicCreateModal from "./TopicCreateModal.jsx";
import PrimaryButton from "../../../components/PrimaryButton.jsx";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { App, Modal } from "antd";
import PropTypes from "prop-types";

const ManageTopicsModal = ({ open, onCancel }) => {
  const { t } = useTranslation();

  const [deleteTopic] = useDeleteTopicMutation();
  const [
    paginateFilterTopics,
    { data: topicsData, isSuccess: topicsIsSuccess },
  ] = useLazyGetTopicsQuery();
  const [topicModal, setTopicModal] = useState({
    open: false,
    topic: null,
  });
  const { notification } = App.useApp();

  useEffect(() => {
    paginateFilterTopics({ page: 1, pageSize: 10 });
  }, []);

  const handleTableChange = (pagination, filters) => {
    paginateFilterTopics({
      page: pagination.current,
      pageSize: pagination.pageSize,
      ...filters,
    });
  };

  const handleDeleteTopic = (id) => [
    deleteTopic(id)
      .unwrap()
      .then(() => notification.success({ message: t("topicDeleted") })),
  ];

  const columns = [
    {
      title: t("table.title"),
      dataIndex: "title",
      key: "title",
      addSearch: true,
      ellipsis: true,
      width: 550,
    },
    {
      title: t("buttons.edit"),
      key: "edit",
      render: (_, record) => (
        <EditOutlined
          onClick={() =>
            setTopicModal({
              open: true,
              topic: record,
            })
          }
        />
      ),
    },
    {
      title: t("buttons.delete"),
      key: "delete",
      render: (_, record) => (
        <DeleteOutlined onClick={() => handleDeleteTopic(record.id)} />
      ),
    },
  ];

  return (
    <Modal
      open={open}
      title={t("topics")}
      width={800}
      onCancel={onCancel}
      footer={[]}
      destroyOnClose
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <PrimaryButton onClick={() => setTopicModal({ open: true, topic: null })}>
        {t("createTopic")}
      </PrimaryButton>
      {topicsIsSuccess && (
        <Table
          className="mt-3"
          data={topicsData.results}
          totalCount={topicsData.count}
          columns={columns}
          handleTableChange={handleTableChange}
        />
      )}
      <TopicCreateModal
        open={topicModal.open}
        initialData={topicModal.topic}
        onCancel={() => setTopicModal({ open: false, topic: null })}
      />
    </Modal>
  );
};

ManageTopicsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ManageTopicsModal;
