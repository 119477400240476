import { useCallback, useEffect, useMemo, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { App, Col, Divider, Row, Spin, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import PrimaryButton from "../../components/PrimaryButton.jsx";
import {
  useDeleteObjectMutation,
  useGetCurrentUserQuery,
  useGetLanguagesQuery,
  useGetObjectByIdQuery,
  useLazyGetMembershipsQuery,
  useLazyIncrementObjectViewsQuery,
} from "../../redux/service.js";
import {
  ObjectTypes,
  OrganizationRoles,
  Roles,
  SharingRights,
} from "../../utilities/constants.jsx";
import ObjectModalForm from "./ObjectModalForm.jsx";

const { Title, Text } = Typography;

const ObjectDetails = ({ initialId }) => {
  const { objectId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const { notification } = App.useApp();

  const {
    data: objectData,
    isSuccess: isObjectSuccess,
    isLoading: isObjectLoading,
    isFetching: isObjectFetching,
  } = useGetObjectByIdQuery(objectId || initialId);
  const [isEdit, setIsEdit] = useState(location?.state?.isEdit || false);
  const { data: currentUser } = useGetCurrentUserQuery();
  const [incrementObjectViews] = useLazyIncrementObjectViewsQuery();
  const { data: languages, isSuccess: isLanguagesSuccess } =
    useGetLanguagesQuery();
  const [getMembershipsForOrganization, membershipsResult] =
    useLazyGetMembershipsQuery();
  const [deleteObjectById] = useDeleteObjectMutation();

  useEffect(() => {
    if (isObjectSuccess) {
      getMembershipsForOrganization({
        organization: objectData.organization,
        pagination: "off",
      });
      setTimeout(() => {
        incrementObjectViews(objectData.id);
      }, 15000);
    }
  }, [isObjectSuccess]);

  const canEditOrDeleteObject = useMemo(() => {
    if (currentUser && isObjectSuccess) {
      return (
        currentUser.role === Roles.ADMIN.value ||
        (([
          OrganizationRoles.ADMIN.value,
          OrganizationRoles.SUPERVISOR.value,
        ].includes(currentUser?.organizationRole) ||
          currentUser.id === objectData.createdBy.id ||
          objectData.contributors.includes(currentUser.id)) &&
          currentUser.organization.id === objectData.organization)
      );
    }
    return false;
  }, [currentUser, objectData]);

  const handleDelete = () => {
    deleteObjectById(objectId)
      .unwrap()
      .then(() => {
        notification.success({ message: t("deleteObject") });
        navigate("/objects");
      });
  };

  const EvidenceView = useCallback(() => {
    if (!isObjectSuccess) return null;

    const evidence = objectData.evidence;

    if (!evidence) {
      return (
          <div>
            <Text>{t("noEvidenceProvided")}</Text>
          </div>
      )
    }

    const evidenceFile = (
        <div>
          <Row>
          <Col span={18}>
              <Title level={5}>
                <a href={evidence.file} target="_blank" rel="noreferrer">
                  {evidence.name}
                </a>
              </Title>
            </Col>
            <Col xl={24} md={24} sm={24}>
              <PrimaryButton
                  file={{
                    url: evidence.fileDownloadUrl,
                    name: evidence.name,
                  }}
              >
                {t("buttons.download")}
              </PrimaryButton>
            </Col>
          </Row>
        </div>
    )

    return (
        <>
          {evidence?.file && evidenceFile}
          {evidence?.urls && (
              <div className="mt-2">
                <Text>{evidence.urls}</Text>
              </div>
        )}
        </>
    )
  }, [isObjectSuccess, isObjectFetching]);

  if (isObjectLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  return (
    isObjectSuccess && (
      <Row className="px-3 py-2">
        <Col span={16} className="pe-5">
          <div className="mb-3">
            <Text
              style={{
                backgroundColor: token.backgroundColor,
                color: "white",
                fontWeight: "400",
                textTransform: "uppercase",
              }}
              className="py-1 px-2"
            >
              {ObjectTypes.getItemByValue(objectData.type).label}
            </Text>
          </div>
          <Title className="mb-2" level={5}>
            {objectData.title}
          </Title>
          <Text>{objectData.description}</Text>
        </Col>
        <Col
          span={8}
          className="px-4 py-4"
          style={{
            backgroundColor: "rgba(237, 237, 237, 1)",
          }}
        >
          <Row className="mb-3">
            <Col lg={12} md={12} sm={24}>
              {canEditOrDeleteObject ? (
                <div style={{ display: "flex" }}>
                  <PrimaryButton onClick={() => setIsEdit(true)}>
                    {t("buttons.edit")}
                  </PrimaryButton>
                  <PrimaryButton
                    key="delete"
                    onClick={handleDelete}
                    type="secondary"
                    className="ms-2"
                  >
                    {t("buttons.delete")}
                  </PrimaryButton>
                </div>
              ) : null}
            </Col>
            <Col lg={24} md={24} sm={24} className="mt-2">
              <PrimaryButton
                onClick={() =>
                  navigate("/discussions/form", {
                    state: { object: objectData },
                  })
                }
              >
                {t("startADiscussion")}
              </PrimaryButton>
            </Col>
          </Row>
          <Title level={5}>Evidence</Title>
          <EvidenceView />
          <Divider />
          {membershipsResult.isSuccess && (
            <div className="mb-1">
              <Title level={5}>{t("labels.contributor")}</Title>
              <Text>
                {membershipsResult.data
                  .filter((member) =>
                    objectData.contributors.includes(member.user.id),
                  )
                  .map((member) => member.user.fullName)
                  .join(", ")}
              </Text>
            </div>
          )}
          <div className="mb-1">
            <Title level={5}>{t("form.labels.date")}</Title>
            <Text>{dayjs(objectData.date).format("DD/MM/YYYY")}</Text>
          </div>
          {isLanguagesSuccess && (
            <div className="mb-1">
              <Title level={5}>{t("form.labels.language")}</Title>
              <Text>
                {
                  languages.find(
                    (language) => language.value === objectData.language,
                  ).label
                }
              </Text>
            </div>
          )}
          <div className="mb-1">
            <Title level={5}>{t("form.labels.sharingRights")}</Title>
            <Text>
              {SharingRights.getItemByValue(objectData.sharingRights).label}
            </Text>
          </div>
          <div className="mb-1">
            {Object.keys(objectData.fields).map((field) => (
              <div key={field}>
                <Title level={5}>{t(`form.labels.${field}`)}</Title>
                <Text>{objectData.fields[field]}</Text>
              </div>
            ))}
          </div>
        </Col>
        <ObjectModalForm
          onCancel={() => setIsEdit(false)}
          open={isEdit}
          initialData={objectData}
        />
      </Row>
    )
  );
};

ObjectDetails.propTypes = {
  initialId: PropTypes.number,
};

ObjectDetails.defaultProps = {
  initialId: null,
};

export default ObjectDetails;
