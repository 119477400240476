import { useTranslation } from "react-i18next";
import { Col, Row, theme, Typography } from "antd";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import Avatar from "../../../components/Avatar.jsx";
import {
  useGetTopicsQuery,
} from "../../../redux/service.js";
import DiscussionTools from "./DiscussionTools.jsx";

const { Title, Text } = Typography;

const DiscussionBox = ({ discussion, showAvatar }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const parser = new DOMParser();
  const { topic } = useParams();

  const { data: discussionTopics, isSuccess: isTopicsSuccess } =
    useGetTopicsQuery(
      { pagination: "off", ids: discussion.topics },
      { skip: discussion.topics.length === 0 },
    );

  const getCreationDate = () => {
    if (
      DateTime.fromISO(discussion.createdAt).diff(DateTime.now(), "days").days >
      2
    ) {
      return DateTime.fromISO(discussion.createdAt).toRelative();
    } else {
      return DateTime.fromISO(discussion.createdAt).toFormat("dd MMM yyyy");
    }
  };

  const openUserProfile = () => {
    navigate(`/profile/${discussion.createdBy.id}`);
  };

  const openSingleDiscussion = () => {
    navigate(`/discussions/${discussion.id}`);
  };

  return (
    <Row
      className="my-3 py-4"
      style={{ borderTop: "1px solid rgba(1, 1, 46, 0.134)" }}
    >
      {showAvatar && (
        <Col span={1}>
          <Avatar user={discussion.createdBy} />
        </Col>
      )}
      <Col span={19} className="ps-3 pe-3">
        <a onClick={openSingleDiscussion}>
          <Title
            className="mb-2"
            level={4}
            style={{
              color: token.colorTextThird,
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            {_.truncate(discussion.title, { length: 200 })}
          </Title>
        </a>
        <Text style={{ color: "rgba(139, 141, 152, 1)" }}>
          {_.truncate(
            parser.parseFromString(discussion.content, "text/html").body
              .textContent,
            { length: 500 },
          )}
        </Text>
        <div style={{ display: "flex" }}>
          <div>
            <Text
              style={{ display: "block", color: "rgba(139, 141, 152, 1)" }}
              className="mt-2"
            >
              {getCreationDate()} {t("by") + " "}
              <a
                style={{ color: "rgba(74, 105, 156, 1)" }}
                onClick={openUserProfile}
              >
                {discussion.createdBy.fullName}
              </a>{" "}
              {isTopicsSuccess ? (
                <>
                  {t("in") + " "}
                  {discussionTopics.map((i, index) => (
                    <span key={i.title}>
                      <a
                        key={i.title}
                        style={{ color: "rgba(74, 105, 156, 1)" }}
                        onClick={() => navigate(`/community/topics/${i.title}`)}
                      >
                        {i.title}
                      </a>
                      {index + 1 !== discussionTopics.length && ", "}
                    </span>
                  ))}
                </>
              ) : null}
            </Text>
          </div>
          <div className="ms-3" style={{ alignContent: "end" }}>
            <DiscussionTools
              discussion={discussion}
              listProps={{ width: "180px" }}
              iconProps={{
                style: {
                  fontSize: "20px",
                  transform: "rotate(90deg)",
                  color: "rgba(74, 105, 156, 1)",
                },
              }}
            />
          </div>
          {discussion.isPinned && topic ? (
            <div className="ms-3" style={{ alignContent: "end" }}>
              <img src="/assets/pin-icon.svg" alt="pin icon" />
              <Text className="ms-2" style={{ color: "rgba(39, 155, 64, 1)" }}>
                {t("pinnedDiscussion")}
              </Text>
            </div>
          ) : null}
        </div>
      </Col>
      <Col span={4}>
        {[
          {
            icon: (
              <img src="/assets/comment.svg" alt="comment" className="me-3" />
            ),
            text: t("commentsCount", {
              count: discussion.commentsCount,
              plural: discussion.commentsCount === 1 ? "" : "s",
            }),
          },
        ].map((i) => (
          <div
            key={i.text}
            style={{ display: "flex", justifyContent: "left" }}
            className="mb-2"
          >
            {i.icon}
            <Title
              level={5}
              style={{
                color: "rgba(74, 105, 156, 1)",
                fontWeight: 400,
              }}
            >
              {i.text}
            </Title>
          </div>
        ))}
      </Col>
    </Row>
  );
};

DiscussionBox.propTypes = {
  discussion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    commentsCount: PropTypes.number.isRequired,
    views: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.object.isRequired,
    topics: PropTypes.array.isRequired,
    isPinned: PropTypes.bool.isRequired,
  }).isRequired,
  showAvatar: PropTypes.bool,
};

DiscussionBox.defaultProps = {
  showAvatar: true,
};

export default DiscussionBox;
