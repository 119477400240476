import { useEffect, useState } from "react";

import { App, Modal, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import PrimaryButton from "../../../components/PrimaryButton.jsx";
import ObjectsTable from "./ObjectsTable.jsx";
import { ObjectTypes } from "../../../utilities/constants.jsx";

const { Title } = Typography;

const ObjectSelectModal = ({
  open,
  onCancel,
  onSelect,
  multiple,
  required,
  filters,
  onCreateObject,
  initialSelectedObjects,
}) => {
  const { t } = useTranslation();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const { notification } = App.useApp();
  const { token } = theme.useToken();

  useEffect(() => {
    setSelectedObjects(initialSelectedObjects);
  }, [initialSelectedObjects]);

  const columns = [
    {
      title: t("table.title"),
      dataIndex: "title",
      key: "title",
      addSearch: true,
      ellipsis: true,
    },
    {
      title: t("table.type"),
      dataIndex: "type",
      key: "type",
      render: (_, record) => ObjectTypes.getItemByValue(record.type)?.label,
      addSearch: true,
      filters: ObjectTypes.asList().map((i) => ({
        text: i.label,
        value: i.value,
      })),
    },
  ];

  const handleConfirm = () => {
    if (
      ((Array.isArray(selectedObjects) && selectedObjects.length === 0) ||
        selectedObjects === null) &&
      required
    ) {
      notification.error({ message: "You must select an object." });
      return;
    }

    if (multiple) {
      onSelect(selectedObjects);
    } else {
      onSelect(selectedObjects[0]);
    }
  };

  return (
    <Modal
      open={open}
      width={900}
      title={
        <div style={{ display: "flex" }}>
          <div>
            <Title level={3}>{t("form.labels.selectObject")}</Title>
          </div>
          {onCreateObject && (
            <>
              <div
                className="px-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Title level={5} style={{ color: token.colorTextSecondary }}>
                  {t("or")}
                </Title>
              </div>
              <div>
                <PrimaryButton onClick={onCreateObject} type="secondary">
                  + {t("createNewObject")}
                </PrimaryButton>
              </div>
            </>
          )}
        </div>
      }
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <PrimaryButton key="cancel" onClick={onCancel}>
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton key="confirm" onClick={handleConfirm}>
          {t("buttons.confirm")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <ObjectsTable
        columns={columns}
        filters={filters}
        rowSelection={{
          type: multiple ? "checkbox" : "radio",
          onSelect: (record, selected) => {
            if (!selected) {
              setSelectedObjects((oldVal) =>
                oldVal.filter((i) => i.id !== record.id),
              );
            } else {
              setSelectedObjects((oldVal) => [...oldVal, record]);
            }
          },
          hideSelectAll: true,
          selectedRowKeys: selectedObjects.map((i) => i.id),
        }}
      />
    </Modal>
  );
};

ObjectSelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  filters: PropTypes.object,
  onCreateObject: PropTypes.func,
  initialSelectedObjects: PropTypes.array,
};

ObjectSelectModal.defaultProps = {
  multiple: false,
  required: true,
  filters: null,
  onCreateObject: null,
  initialSelectedObjects: [],
};

export default ObjectSelectModal;
