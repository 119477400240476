import { useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { App, theme, Typography } from "antd";
import { useDispatch } from "react-redux";

import PrimaryButton from "../../components/PrimaryButton.jsx";
import Input from "../../components/form/Input.jsx";
import { handleErrors } from "../../utilities/index.js";
import { useVerifyEmailMutation } from "../../redux/service.js";
import { setIsAuthenticated } from "../../redux/users/reducer.js";
import { LocalStorageName } from "../../utilities/constants.jsx";
import FormField from "../../components/form/FormField.jsx";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const emailVerificationSchema = (t) =>
  yup
    .object()
    .shape({
      token: yup
        .string()
        .typeError(t("form.validation.invalidCode"))
        .required(t("form.validation.codeRequired")),
    })
    .required();

const EmailVerification = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { token } = theme.useToken();
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
  const { t } = useTranslation();

  const { control, handleSubmit, setError, setValue } = useForm({
    resolver: yupResolver(emailVerificationSchema(t)),
    mode: "onChange",
  });

  const sendCode = (token) => {
    verifyEmail({ token })
      .unwrap()
      .then((data) => {
        notification.success({ message: t("verifyEmailSuccess") });
        dispatch(
          setIsAuthenticated({ isAuthenticated: true, user: data.user }),
        );
        navigate("/");
        window.localStorage.setItem(
          LocalStorageName,
          JSON.stringify({
            accessExpiration: data.accessExpiration,
            refreshExpiration: data.refreshExpiration,
          }),
        );
      })
      .catch((errors) => handleErrors(errors, setError, notification));
  };

  useEffect(() => {
    if (searchParams.has("token")) {
      setValue("token", searchParams.get("token"));
      sendCode(searchParams.get("token"));
    }
  }, [setValue, searchParams]);

  const onSubmit = (values) => {
    sendCode(values.token);
  };

  return (
    <div className="m-auto form-wrapper" style={{ width: "60%" }}>
      <div
        className="text-center mt-3 mb-4 w-75 m-auto"
        style={{ maxWidth: "400px" }}
      >
        <div className="text-center mb-4 mt-5">
          <Title
            level={3}
            style={{
              color: token.colorPrimaryText,
              fontSize: "clamp(18px, 2vw, 25px)",
            }}
          >
            {t("titles.emailVerify")}
          </Title>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="text-start">
          <div>
            <FormField
              label={t("form.labels.verificationCode")}
              required
              field={<Input name="token" control={control} />}
            />
          </div>
          <div className="mt-3 text-center">
            <PrimaryButton
              className="mb-3"
              disabled={isLoading}
              htmlType="submit"
            >
              {t("buttons.submit")}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailVerification;
