import { useEffect, useState } from "react";

import { theme } from "antd";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

import Table from "../../../components/Table.jsx";
import {
  useGetCountriesQuery,
  useGetInvitesQuery,
  useGetUsersQuery,
  useLazyGetOrganizationsQuery,
} from "../../../redux/service.js";
import { Chapters, RegistrationStatus } from "../../../utilities/constants.jsx";
import StatisticsBox from "./StatisticsBox.jsx";
import Tag from "../../../components/Tag.jsx";

const OrganizationStatistics = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const [getPaginatedOrganizations, organizationsResponse] =
    useLazyGetOrganizationsQuery();
  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();
  const { data: users, isSuccess: isUsersSuccess } = useGetUsersQuery();
  const { data: invites, isSuccess: isInvitesSuccess } = useGetInvitesQuery();
  const [organizationStatusFilter, setOrganizationStatusFilter] =
    useState(null);
  const [countriesFilter, setCountriesFilter] = useState(null);

  useEffect(() => {
    handleTableChange({ current: 1, pageSize: 10, ordering: "name" });
  }, []);

  useEffect(() => {
    if (
      organizationsResponse.isSuccess &&
      isCountriesSuccess &&
      !countriesFilter
    ) {
      setCountriesFilter(
        Object.keys(organizationsResponse.data.countries).map((countryKey) => ({
          text: (
            <span>
              {countries.find((i) => i.value === countryKey)?.label}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                ({organizationsResponse.data.countries[countryKey]})
              </span>
            </span>
          ),
          value: countryKey,
        })),
      );
    }
  }, [organizationsResponse, isCountriesSuccess]);

  useEffect(() => {
    if (organizationsResponse.isSuccess && !organizationStatusFilter) {
      setOrganizationStatusFilter(
        RegistrationStatus.asList().map((i) => ({
          text: (
            <span>
              {i.label}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                ({organizationsResponse.data.registrationStatusCount[i.value]})
              </span>
            </span>
          ),
          value: i.value,
        })),
      );
    }
  }, [organizationsResponse]);

  const handleTableChange = (pagination, filters) => {
    getPaginatedOrganizations({
      page: pagination.current,
      pageSize: pagination.pageSize,
      ordering: "name",
      ...filters,
    });
  };

  const columns = [
    {
      title: t("form.labels.userId"),
      dataIndex: "externalId",
      key: "externalId",
      width: 100,
      addSearch: true,
    },
    {
      title: t("form.labels.name"),
      dataIndex: "name",
      key: "name",
      addSearch: true,
      width: 400,
      sorter: true,
      render: (_, record) => (
        <div style={{ width: "350px" }}>
          <a
            onClick={() => navigate(`/organizations/${record.id}`)}
            style={{ color: token.linkColor, textDecoration: "underline" }}
          >
            {record.name}
          </a>
        </div>
      ),
    },
    {
      title: t("form.labels.status"),
      dataIndex: "registrationStatus",
      key: "registrationStatus",
      width: 110,
      render: (_, record) => {
        const status = RegistrationStatus.getItemByValue(
          record.registrationStatus,
        );
        return (
          <Tag
            label={status.label}
            color={status.backgroundColor}
            style={{
              fontWeight: "bold",
              color: status.color,
            }}
            className="py-1"
          />
        );
      },
      filters: organizationStatusFilter || [],
      filterMultiple: false,
    },
    {
      title: t("form.labels.dateJoined"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) =>
        DateTime.fromISO(record.createdAt).toFormat("dd MMM yyyy"),
    },
    {
      title: t("form.labels.country"),
      dataIndex: "countries",
      key: "countries",
      addSearch: true,
      render: (_, record) =>
        isCountriesSuccess &&
        countries.find((i) => i.value === record.country)?.label,
      filters: countriesFilter || [],
    },
    {
      title: t("table.chapter"),
      dataIndex: "chapter",
      key: "chapter",
      addSearch: true,
      width: 120,
      ellipsis: true,
      filters: Chapters.asList(),
      render: (_, record) =>
        Chapters.getItemByValue(record.organization?.chapter)?.label,
    },
    {
      title: t("form.labels.admin"),
      dataIndex: "admin.fullName",
      key: "adminFullName",
      width: 150,
      render: (_, record) => (
        <a
          style={{ color: token.linkColor, textDecoration: "underline" }}
          onClick={() => navigate(`/profile/${record.admin.id}`)}
        >
          {record?.admin?.fullName}
        </a>
      ),
    },
    {
      title: t("table.users"),
      dataIndex: "membersCount",
      key: "membersCount",
    },
  ];

  return (
    <div>
      <div style={{ display: "flex" }} className="mb-4">
        {isUsersSuccess && (
          <StatisticsBox
            label={t("usersRegisteredToCommons")}
            statistic={users.count}
            image="/assets/users-grey.svg"
          />
        )}
        {isInvitesSuccess && (
          <StatisticsBox
            label={t("usersPending")}
            statistic={invites.count}
            className="ms-3"
            image="/assets/pending-users.svg"
          />
        )}
      </div>
      <Table
        data={organizationsResponse.data?.results}
        totalCount={organizationsResponse.data?.count}
        columns={columns}
        isLoading={
          organizationsResponse.isUninitialized ||
          organizationsResponse.isFetching
        }
        handleTableChange={handleTableChange}
      />
    </div>
  );
};

export default OrganizationStatistics;
