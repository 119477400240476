import { useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { App, theme, Typography } from "antd";

import { useResetPasswordConfirmMutation } from "../../redux/service.js";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import Input from "../../components/form/Input.jsx";
import { handleErrors } from "../../utilities/index.js";
import FormField from "../../components/form/FormField.jsx";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const resetPasswordConfirmSchema = (t) =>
  yup
    .object()
    .shape({
      password: yup
        .string()
        .typeError(t("form.validation.passwordNotValid"))
        .required(t("form.validation.passwordRequired")),
      repeatPassword: yup
        .string()
        .typeError(t("form.validation.repeatPasswordNotValid"))
        .required(t("confirm.passwordConfirm"))
        .oneOf(
          [yup.ref("password"), null],
          t("form.validation.passwordsMustMatch"),
        ),
    })
    .required();

const ResetPasswordConfirm = () => {
  const navigate = useNavigate();
  const [resetPasswordConfirm, { isLoading }] =
    useResetPasswordConfirmMutation();
  const { notification } = App.useApp();
  const [searchParams] = useSearchParams();
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const { control, handleSubmit, setError } = useForm({
    resolver: yupResolver(resetPasswordConfirmSchema(t)),
    mode: "onChange",
  });

  useEffect(() => {
    if (!searchParams.has("token")) {
      notification.success({
        message: t("labels.invalid_link"),
        key: "invalid_reset_link",
      });
      navigate("/reset-password");
    }
  }, [notification, navigate, searchParams]);

  const onSubmit = (values) => {
    resetPasswordConfirm({ ...values, token: searchParams.get("token") })
      .unwrap()
      .then((data) => {
        notification.success({ message: data.message });
        navigate("/login");
      })
      .catch((errors) => handleErrors(errors, setError, notification));
  };

  return (
    <div className="m-auto form-wrapper" style={{ width: "60%" }}>
      <div
        className="text-center mt-3 mb-4 w-75 m-auto"
        style={{ maxWidth: "400px" }}
      >
        <div className="text-center mb-4 mt-5">
          <Title
            level={3}
            style={{
              color: token.colorPrimaryText,
              fontSize: "clamp(18px, 2vw, 25px)",
            }}
          >
            {t("titles.resetPassword")}
          </Title>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="text-start">
          <div className="mb-3">
            <FormField
              label={t("form.labels.password")}
              required
              field={
                <Input htmlType="password" name="password" control={control} />
              }
            />
          </div>
          <div>
            <FormField
              label={t("form.labels.repeatPassword")}
              required
              field={
                <Input
                  htmlType="password"
                  name="repeatPassword"
                  control={control}
                />
              }
            />
          </div>
          <div className="mt-3 text-center">
            <PrimaryButton
              className="mb-3"
              disabled={isLoading}
              htmlType="submit"
            >
              {t("buttons.submit")}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordConfirm;
