import { Collapse, theme, Typography } from "antd";
import createDOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { useGetFrequentlyAskedQuestionsQuery } from "../redux/service.js";

const { Title } = Typography;

const Faq = () => {
  const DOMPurify = createDOMPurify(window);
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const { data, isSuccess } = useGetFrequentlyAskedQuestionsQuery();

  const panelStyle = {
    color: token.colorTextThird,
    fontWeight: "bold",
    backgroundColor: "white",
    borderBottom: "1px solid #aeaeae",
    borderRadius: 0,
    marginBottom: 10,
    fontSize: "18px",
  };

  const iconStyles = {
    color: "#53bdd1",
    fontSize: "20px",
    marginTop: "2px",
  };

  return (
    isSuccess && (
      <div>
        <Title
          level={2}
          className="mb-4"
          style={{ color: token.colorTextThird }}
        >
          {t("frequentlyAskedQuestions")}
        </Title>
        <Collapse
          className="faq"
          expandIconPosition="end"
          items={data.map((question) => ({
            key: question.id,
            label: question.question,
            children: (
              <div
                style={{ fontSize: "15px", fontWeight: 400 }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(question.answer, {
                    USE_PROFILES: { html: true },
                  }),
                }}
              />
            ),
            style: panelStyle,
          }))}
          expandIcon={({ isActive }) =>
            isActive ? (
              <MinusCircleOutlined style={iconStyles} />
            ) : (
              <PlusCircleOutlined style={iconStyles} />
            )
          }
          style={{ backgroundColor: "white", border: 0 }}
        />
      </div>
    )
  );
};

export default Faq;
