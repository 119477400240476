import React from "react";

import { Col, Row, theme, Tooltip, Typography } from "antd";
import PropTypes from "prop-types";

const { Title } = Typography;

const FormField = ({
  label,
  field,
  required,
  showLabel,
  isEdit,
  tooltipContent,
  labelStyles,
}) => {
  const { token } = theme.useToken();

  return (
    <div className="mt-3">
      {showLabel && label && (
        <Row>
          <Col>
            <Title
              level={5}
              className="mb-2"
              style={{ color: token.colorPrimaryText, ...labelStyles }}
            >
              {label}
              {required && isEdit ? (
                <span
                  style={{ paddingLeft: "2px", color: token.colorAsterisk }}
                >
                  *
                </span>
              ) : null}
            </Title>
          </Col>
          {tooltipContent && (
            <Col className="ms-2">
              <Tooltip title={tooltipContent}>
                <img
                  style={{ width: "15px" }}
                  src="/assets/question.svg"
                  alt="question icon"
                />
              </Tooltip>
            </Col>
          )}
        </Row>
      )}
      {React.cloneElement(field, { isEdit })}
    </div>
  );
};

FormField.propTypes = {
  field: PropTypes.element.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  isEdit: PropTypes.bool,
  tooltipContent: PropTypes.element,
  labelStyles: PropTypes.object,
};

FormField.defaultProps = {
  label: null,
  required: false,
  showLabel: true,
  isEdit: true,
  tooltipContent: null,
  labelStyles: {},
};

export default FormField;
