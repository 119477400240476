import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {useDispatch} from "react-redux";
import {Spin, Typography} from "antd";

import {
  useGetCurrentUserQuery,
} from "../../redux/service.js";
import { Roles } from "../../utilities/constants.jsx";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import CreateSurveyModal from "./components/create-survey-form/CreateSurveyModal.jsx";
import OrganizationSurveys from "./components/survey-list/OrganizationSurveys.jsx";
import Tabs from "../../components/Tabs.jsx";
import SignatorySurveys from "./components/survey-list/SignatorySurveys.jsx";
import {setShowGreyBackground} from "../../redux/utils/reducer.js";

const { Title } = Typography;

const SurveyList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: currentUser, isLoading } =
    useGetCurrentUserQuery();
  const [openCreateModal, setOpenCreateModal] = useState(false);

    useEffect(() => {
    dispatch(setShowGreyBackground(true));

    return () => dispatch(setShowGreyBackground(false));
  }, []);


  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  return currentUser?.role === Roles.ADMIN.value ? (
      <div>
          <div style={{ display: 'flex' }}>
              <Title level={3} className="mb-4">
                  {t("surveys")}
                </Title>
                  <PrimaryButton
                  onClick={() => setOpenCreateModal(true)}
                  className="ms-3"
              >
                  <img
                      style={{verticalAlign: "baseline"}}
                      src="/assets/add-survey.svg"
                      alt="icon"
                      className="me-1"
                  />
                  {t("buttons.create")}
              </PrimaryButton>
              <CreateSurveyModal
                  open={openCreateModal}
                  onCancel={() => setOpenCreateModal(false)}
              />
          </div>
          <OrganizationSurveys />
      </div>
  ) : (
        <Tabs
                  type="card"
                  defaultActiveTab="organizationReports"
                  items={[
                      {
                          label: t("labels.myReports"),
                          key: "organizationReports",
                          children: <OrganizationSurveys/>,
                      },
                      {
                          label: t("labels.signatoryReports"),
                          key: "signatoryReports",
                          children: <SignatorySurveys/>,
                      }
                  ]}
                  className="surveys-tabs"
              />
  );
};

export default SurveyList;
