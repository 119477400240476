import PropTypes from "prop-types";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import SingleNarrativeView from "../SingleNarrativeView.jsx";
import PrimaryButton from "../../../components/PrimaryButton.jsx";

const NarrativePreview = ({ open, onCancel, data }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      destroyOnClose
      width={1200}
      title={t("form.labels.narrativePreview")}
      onCancel={onCancel}
      footer={[
        <PrimaryButton key="cancel" onClick={onCancel}>
          {t("buttons.close")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <div className="ps-5">
        <SingleNarrativeView initialData={data} showEdit={false} />
      </div>
    </Modal>
  );
};

NarrativePreview.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default NarrativePreview;
