import { useEffect, useRef, useState } from "react";

import { Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import PrimaryButton from "./PrimaryButton.jsx";

const getColumnSearchProps = (
  dataIndex,
  columns,
  searchInput,
  handleSearch,
  handleReset,
  hasOptions,
  t,
) => ({
  ...(!hasOptions
    ? {
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Input
              ref={searchInput}
              placeholder={`Search ${columns.find((i) => i.dataIndex === dataIndex).title.toLowerCase()}`}
              value={selectedKeys}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? e.target.value : null)
              }
              onPressEnter={() => handleSearch(confirm)}
              style={{
                marginBottom: 8,
                borderRadius: 0,
                display: "block",
              }}
            />
            <Space>
              <PrimaryButton
                type="primary"
                onClick={() => handleSearch(confirm)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                }}
              >
                {t("buttons.search")}
              </PrimaryButton>
              <PrimaryButton
                onClick={() =>
                  clearFilters && handleReset(clearFilters, confirm)
                }
                size="small"
                style={{
                  width: 90,
                }}
              >
                {t("buttons.reset")}
              </PrimaryButton>
            </Space>
          </div>
        ),
      }
    : {}),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? "#1677ff" : undefined,
      }}
    />
  ),
});

const CustomTable = ({
  data,
  columns,
  totalCount,
  initialPageSize,
  handleTableChange,
  rowSelection,
  isLoading,
  ...props
}) => {
  const searchInput = useRef(null);
  const [modifiedColumns, setModifiedColumns] = useState([]);
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(initialPageSize);

  const handleSearch = (confirm) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  useEffect(() => {
    if (columns) {
      setModifiedColumns(
        columns.map((i) => {
          let newColumn = i;
          if (i?.addSearch) {
            newColumn = {
              ...i,
              ...getColumnSearchProps(
                Array.isArray(i.dataIndex)
                  ? i.dataIndex.join(".")
                  : i.dataIndex,
                columns,
                searchInput,
                handleSearch,
                handleReset,
                i?.filters,
                t,
              ),
            };
          }
          return { ...newColumn, dataIndex: newColumn.dataIndex?.split(".") };
        }),
      );
    }
  }, [columns, t]);

  return (
    data && (
      <Table
        rowKey={(record) => record.id}
        dataSource={data}
        columns={modifiedColumns}
        pagination={{
          pageSize,
          total: totalCount,
        }}
        loading={{ spinning: isLoading, size: "large" }}
        rowSelection={rowSelection}
        onChange={(pagination, filters, sorter) => {
          setPageSize(pagination.pageSize);
          handleTableChange(pagination, filters, sorter);
        }}
        hideOnSinglePage
        {...props}
      />
    )
  );
};

CustomTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleTableChange: PropTypes.func,
  rowSelection: PropTypes.shape({
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  totalCount: PropTypes.number.isRequired,
  initialPageSize: PropTypes.number,
  isLoading: PropTypes.bool,
};

CustomTable.defaultProps = {
  totalCount: null,
  initialPageSize: 10,
  rowSelection: null,
  isLoading: false,
  handleTableChange: () => {},
};

export default CustomTable;
