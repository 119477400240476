import * as yup from "yup";

import { ObjectTypes } from "../../../utilities/constants.jsx";
import Input from "../../../components/form/Input.jsx";
import DatePicker from "../../../components/form/DatePicker.jsx";
import RangePicker from "../../../components/form/RangePicker.jsx";
import NumericInput from "../../../components/form/NumericInput.jsx";

export const evidenceSupportedFileFormats = [
  ".jpeg",
  ".jpg",
  ".png",
  ".tiff",
  ".bmp",
  ".gif",
  ".pdf",
  ".docx",
  ".csv",
  ".xlsx",
  ".pptx",
  ".mp3",
  ".mp4",
];

export const objectSchema = (t) => ({
  type: yup
    .string()
    .typeError(t("form.validation.typeNotValid"))
    .required(t("form.validation.typeRequired")),
  contributors: yup
    .array()
    .of(yup.number().typeError(t("form.validation.mustSelectContributor")))
    .typeError(t("form.validation.contributorNotValid")),
  title: yup
    .string()
    .typeError(t("form.validation.titleNotValid"))
    .required(t("form.validation.titleRequired")),
  description: yup.string().typeError(t("form.validation.descriptionNotValid")),
  date: yup
    .date()
    .typeError(t("form.validation.dateNotValid"))
    .required(t("form.validation.dateRequired")),
  language: yup
    .string()
    .typeError(t("form.validation.languageNotValid"))
    .required(t("form.validation.languageRequired")),
  sharingRights: yup
    .string()
    .typeError(t("form.validation.sharingRightsNotValid"))
    .required(t("form.validation.sharingRightsRequired")),
  fields: yup
    .object()
    .typeError(t("form.validation.objectFieldsNotValid"))
    .required(t("form.validation.objectFieldsRequired")),
  evidence: yup
    .object({
      file: yup
        .mixed()
        .typeError(t("form.validation.fileNotValid"))
        .nullable()
        .test(
          "file-size-check",
          t("form.validation.fileSizeLimit"),
          (value) => !(value instanceof File) || value.size < 10 * 1024 * 1024,
        ),
    })
    .nullable(),
});

export const getFieldsForAllTypes = (form, t, isEdit = true) => ({
  [ObjectTypes.STATEMENT.value]: {
    fields: [
      {
        label: t("form.labels.relevantStakeholders"),
        required: true,
        field: (
          <Input
            name="fields.relevantStakeholders"
            isEdit={isEdit}
            placeholder={t("form.placeholders.relevantStakeholders")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.purpose"),
        required: true,
        field: (
          <Input
            name="fields.purpose"
            isEdit={isEdit}
            placeholder={t("form.placeholders.purpose")}
            control={form.control}
          />
        ),
      },
    ],
    schema: yup.object().shape({
      relevantStakeholders: yup
        .string()
        .typeError(t("form.validation.relevantStakeholdersNotValid"))
        .required(t("form.validation.relevantStakeholdersRequired")),
      purpose: yup
        .string()
        .typeError(t("form.validation.purposeNotValid"))
        .required(t("form.validation.purposeRequired")),
    }),
  },
  [ObjectTypes.POLICY.value]: {
    fields: [
      {
        label: t("form.labels.scope"),
        required: true,
        field: (
          <Input
            name="fields.scope"
            isEdit={isEdit}
            placeholder={t("form.placeholders.scope")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.enforcementDate"),
        required: true,
        field: (
          <DatePicker
            name="fields.enforcementDate"
            isEdit={isEdit}
            placeholder={t("form.placeholders.enforcementDate")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.revisionDate"),
        field: (
          <DatePicker
            name="fields.revisionDate"
            isEdit={isEdit}
            placeholder={t("form.placeholders.revisionDate")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.numberOfPages"),
        required: true,
        field: (
          <NumericInput
            name="fields.numberOfPages"
            isEdit={isEdit}
            placeholder={t("form.placeholders.numberOfPages")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.publisher"),
        required: true,
        field: (
          <Input
            name="fields.publisher"
            isEdit={isEdit}
            placeholder={t("form.placeholders.publisher")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.media"),
        required: true,
        field: (
          <Input
            name="fields.media"
            isEdit={isEdit}
            placeholder={t("form.placeholders.media")}
            control={form.control}
          />
        ),
      },
    ],
    schema: yup.object().shape({
      scope: yup
        .string()
        .typeError(t("form.validation.scopeNotValid"))
        .required(t("form.validation.scopeRequired")),
      publisher: yup
        .string()
        .typeError(t("form.validation.publisherNotValid"))
        .required(t("form.validation.publisherRequired")),
      media: yup
        .string()
        .typeError(t("form.validation.mediaNotValid"))
        .required(t("form.validation.mediaRequired")),
      numberOfPages: yup
        .number()
        .typeError(t("form.validation.numberOfPagesNotValid"))
        .required(t("form.validation.numberOfPagesRequired")),
      enforcementDate: yup
        .date()
        .typeError(t("form.validation.enforcementDateNotValid"))
        .required(t("form.validation.enforcementDateRequired")),
      revisionDate: yup
        .date()
        .typeError(t("form.validation.revisionDateNotValid")),
    }),
  },
  [ObjectTypes.LEARNING_OBJECT.value]: {
    fields: [
      {
        label: t("form.labels.department"),
        required: true,
        field: (
          <Input
            name="fields.department"
            isEdit={isEdit}
            placeholder={t("form.placeholders.department")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.subject"),
        required: true,
        field: (
          <Input
            name="fields.learningObjectSubject"
            isEdit={isEdit}
            placeholder={t("form.placeholders.learningObjectSubject")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.educationalLevel"),
        required: true,
        field: (
          <Input
            name="fields.educationalLevel"
            isEdit={isEdit}
            placeholder={t("form.placeholders.educationalLevel")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.learningOutcome"),
        required: true,
        field: (
          <Input
            name="fields.learningOutcome"
            isEdit={isEdit}
            placeholder={t("form.placeholders.learningOutcome")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.interactivityType"),
        required: true,
        field: (
          <Input
            name="fields.interactivityType"
            isEdit={isEdit}
            placeholder={t("form.placeholders.interactivityType")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.description"),
        required: true,
        field: (
          <Input
            name="fields.description"
            isEdit={isEdit}
            placeholder={t("form.placeholders.description")}
            control={form.control}
          />
        ),
      },
    ],
    schema: yup.object().shape({
      educationalLevel: yup
        .string()
        .typeError(t("form.validation.educationalLevelNotValid"))
        .required(t("form.validation.educationLevelRequired")),
      department: yup
        .string()
        .typeError(t("form.validation.departmentNotValid"))
        .required(t("form.validation.departmentRequired")),
      description: yup
        .string()
        .typeError(t("form.validation.descriptionNotValid"))
        .required(t("form.validation.descriptionRequired")),
      learningOutcome: yup
        .string()
        .typeError(t("form.validation.learningOutcomeNotValid"))
        .required(t("form.validation.learningOutcomeRequired")),
      learningObjectSubject: yup
        .string()
        .typeError(t("form.validation.subjectNotValid"))
        .required(t("form.validation.subjectRequired")),
      interactivityType: yup
        .string()
        .typeError(t("form.validation.interactivityTypeNotValid"))
        .required(t("form.validation.interactivityTypeRequired")),
    }),
  },
  [ObjectTypes.EVENT.value]: {
    fields: [
      {
        label: t("form.labels.eventDateRange"),
        required: true,
        field: (
          <RangePicker
            name="fields.eventDateRange"
            isEdit={isEdit}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.eventLocation"),
        required: true,
        field: (
          <Input
            name="fields.location"
            isEdit={isEdit}
            placeholder={t("form.placeholders.eventLocation")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.eventSpeakers"),
        required: true,
        field: (
          <Input
            name="fields.speakers"
            isEdit={isEdit}
            placeholder={t("form.placeholders.eventSpeakers")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.eventDescription"),
        required: true,
        field: (
          <Input
            name="fields.description"
            isEdit={isEdit}
            placeholder={t("form.placeholders.eventDescription")}
            control={form.control}
          />
        ),
      },
    ],
    schema: yup.object().shape({
      location: yup
        .string()
        .typeError(t("form.validation.locationNotValid"))
        .required(t("form.validation.locationRequired")),
      eventDateRange: yup
        .array()
        .typeError(t("form.validation.eventDateRangeNotValid"))
        .required(t("form.validation.eventDateRangeRequired")),
      speakers: yup
        .string()
        .typeError(t("form.validation.speakersNotValid"))
        .required(t("form.validation.speakersRequired")),
      description: yup
        .string()
        .typeError(t("form.validation.descriptionNotValid"))
        .required(t("form.validation.descriptionRequired")),
    }),
  },
  [ObjectTypes.PUBLIC_MEDIA.value]: {
    fields: [
      {
        label: t("form.labels.producerAuthor"),
        required: true,
        field: (
          <Input
            name="fields.author"
            isEdit={isEdit}
            placeholder={t("form.placeholders.author")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.publishedDate"),
        required: true,
        field: (
          <DatePicker
            name="fields.publishedDate"
            isEdit={isEdit}
            placeholder={t("form.placeholders.publishedDate")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.degreeOfRecognition"),
        required: true,
        field: (
          <Input
            name="fields.degreeOfRecognition"
            isEdit={isEdit}
            placeholder={t("form.placeholders.degreeOfRecognition")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.mediaName"),
        required: true,
        field: (
          <Input
            name="fields.mediaName"
            isEdit={isEdit}
            placeholder={t("form.placeholders.mediaName")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.mediaType"),
        required: true,
        field: (
          <Input
            name="fields.mediaType"
            isEdit={isEdit}
            placeholder={t("form.placeholders.mediaType")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.duration"),
        required: true,
        field: (
          <Input
            name="fields.duration"
            isEdit={isEdit}
            placeholder={t("form.placeholders.duration")}
            control={form.control}
          />
        ),
      },
    ],
    schema: yup.object().shape({
      publishedDate: yup
        .date()
        .typeError(t("form.validation.publicationDateNotValid"))
        .required(t("form.validation.publicationDateRequired")),
      degreeOfRecognition: yup
        .string()
        .typeError(t("form.validation.degreeOfRecognitionNotValid"))
        .required(t("form.validation.degreeOfRecognitionRequired")),
      author: yup
        .string()
        .typeError(t("form.validation.producerAuthorNotValid"))
        .required(t("form.validation.producerAuthorRequired")),
      mediaName: yup
        .string()
        .typeError(t("form.validation.mediaNameNotValid"))
        .required(t("form.validation.mediaNameRequired")),
      mediaType: yup
        .string()
        .typeError(t("form.validation.mediaTypeNotValid"))
        .required(t("form.validation.mediaTypeRequired")),
      duration: yup
        .string()
        .typeError(t("form.validation.durationNotValid"))
        .required(t("form.validation.durationRequired")),
    }),
  },
});
