import { createBrowserRouter, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { PrivateRoutes, PublicRoutes } from "./Routes";
import DefaultLayout from "../components/layouts/DefaultLayout";
import AuthenticatedLayout from "../components/layouts/AuthenticatedLayout";
import ErrorBoundary from "../components/layouts/ErrorBoundary.jsx";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const wrapWithSentryErrorBoundary = (Component, props = {}) => (
  <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
    <Component {...props} />
  </Sentry.ErrorBoundary>
);

const router = sentryCreateBrowserRouter([
  {
    element: wrapWithSentryErrorBoundary(DefaultLayout),
    children: [
      ...PublicRoutes.map((route) => ({
        path: route.path,
        element: wrapWithSentryErrorBoundary(route.view),
      })),
      { path: "*", element: <Navigate to="/login" /> },
    ],
  },
  {
    element: wrapWithSentryErrorBoundary(AuthenticatedLayout),
    children: [
      ...PrivateRoutes.map((route) => ({
        path: route.path,
        element: wrapWithSentryErrorBoundary(route.view, route?.props),
      })),
      { path: "*", element: <Navigate to="/" /> },
    ],
  },
]);

export default router;
