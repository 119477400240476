import { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Col, Row, Select, Spin, theme, Tooltip, Tree, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

import {
  useLazyGetAnswersQuery,
  useLazyGetPrinciplesQuery,
  useLazyGetQuestionsQuery,
} from "../../../../redux/service.js";
import {
  ObjectTypes,
  OptionType,
  SharingRights,
} from "../../../../utilities/constants.jsx";
import EntityDetailsTooltip from "./EntityDetailsTooltip.jsx";

const { Title, Text } = Typography;

const EntityRow = ({ answerId, entityId, form, entityType }) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const entitiesSharingRights = useWatch({
    control: form.control,
    name: "entitiesSharingRights",
  });
  const answers = useWatch({ control: form.control, name: "answers" });
  const [selectOpen, setSelectOpen] = useState(false);
  const [entitySharingRight, setEntitySharingRight] = useState(null);
  const isDefaultView = form.watch("isDefault");

  /*
    Check if the user has included the answer that this entity belongs to, in the survey view
     */
  const isAnswerIncludedInView = useMemo(() => {
    const selectedAnswers = Object.keys(answers)
      .map((selectedAnswerId) => {
        if (
          `${entityType.toLowerCase()}s` in answers[selectedAnswerId] &&
          answerId == selectedAnswerId
        ) {
          return answers[selectedAnswerId][`${entityType.toLowerCase()}s`].find(
            (entity) => entity == entityId,
          );
        }
      })
      .filter((i) => i !== undefined);

    return selectedAnswers.length !== 0;
  }, [answers, entityId]);

  /*
    Get the entity object with the provided ID
     */
  const entity = useMemo(() => {
    if (entitiesSharingRights) {
      const _entity = entitiesSharingRights.find(
        (i) =>
          i.entityType === entityType &&
          i.id === entityId &&
          i.answer === answerId,
      );
      setEntitySharingRight(_entity.sharingRights);
      return _entity;
    }
  }, [entitiesSharingRights, entityId]);

  const handleChangeSharingRights = (sharingRights) => {
    setEntitySharingRight(sharingRights);
    form.setValue(
      "entitiesSharingRights",
      entitiesSharingRights.map((i) => {
        if (i.id === entity.id && i.entityType === entityType) {
          return {
            ...i,
            sharingRights,
          };
        }
        return i;
      }),
    );
    setSelectOpen(false);
  };

  const entityDetails = useMemo(() => {
    if (!entity) return null;

    if (entityType === OptionType.OBJECT.value) {
      return `${t("labels.object")} - ${ObjectTypes.getItemByValue(entity.type).label}`;
    } else {
      return t("labels.narrative");
    }
  }, [entity]);

  return (
    entity && (
      <Row style={{ display: "flex" }}>
        <Col sm={24} md={17} lg={17}>
          <Tooltip
            overlayClassName="entity-tooltip"
            title={
              <EntityDetailsTooltip
                entityId={entity.id}
                entityType={entityType}
              />
            }
          >
            <Text style={{ color: token.colorBackgroundSecondary }}>
              {entity.title}
            </Text>
          </Tooltip>
        </Col>
        <Col sm={12} md={3} lg={3}>
          <Text>{entityDetails}</Text>
        </Col>
        <Col sm={12} md={4} lg={4} style={{ textAlign: "right" }}>
          <Select
            style={{ width: "170px", textAlign: "left" }}
            open={selectOpen}
            disabled={
              isDefaultView ? isAnswerIncludedInView : !isAnswerIncludedInView
            }
            value={entitySharingRight}
            onDropdownVisibleChange={(visible) => setSelectOpen(visible)}
            onClick={(e) => e.stopPropagation()}
            onChange={handleChangeSharingRights}
            options={SharingRights.asList()}
          />
        </Col>
      </Row>
    )
  );
};

EntityRow.propTypes = {
  answerId: PropTypes.number.isRequired,
  entityId: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
};

const PrinciplesForm = ({ surveyId, form, defaultSelected }) => {
  const [getPrinciples, principlesResponse] = useLazyGetPrinciplesQuery();
  const [getQuestions, questionsResponse] = useLazyGetQuestionsQuery();
  const [getAnswers, answersResponse] = useLazyGetAnswersQuery();
  const [treeData, setTreeData] = useState([]);
  const isDefault = form.watch("isDefault");
  const entitiesSharingRights = form.watch("entitiesSharingRights");

  useEffect(() => {
    getPrinciples({ survey: surveyId, pagination: "off" })
      .unwrap()
      .then((data) => {
        getQuestions({ principles: data.map((i) => i.id), pagination: "off" })
          .unwrap()
          .then((questionsData) => {
            getAnswers({
              questions: questionsData.map((i) => i.id),
              survey: surveyId,
              pagination: "off",
            });
          });
      });
  }, []);

  useEffect(() => {
    if (
      principlesResponse.isSuccess &&
      questionsResponse.isSuccess &&
      answersResponse.isSuccess
    ) {
      const data = getTreeData();
      setTreeData(data);
      form.setValue(
        "entitiesSharingRights",
        answersResponse.data.reduce((acc, answer) => {
          acc = acc.concat(
            answer.attachedObjects.map((obj) => ({
              answer: answer.id,
              id: obj.id,
              title: obj.title,
              sharingRights: obj.sharingRights,
              type: obj.type,
              entityType: OptionType.OBJECT.value,
            })),
          );
          acc = acc.concat(
            answer.attachedNarratives.map((narrative) => ({
              answer: answer.id,
              id: narrative.id,
              title: narrative.title,
              sharingRights: narrative.sharingRights,
              entityType: OptionType.NARRATIVE.value,
            })),
          );
          return acc;
        }, []),
      );
    }
  }, [principlesResponse, questionsResponse, answersResponse]);

  const getTreeData = () => {
    const principles = principlesResponse.data;
    const questions = questionsResponse.data;
    const answers = answersResponse.data;

    return principles.map((principle) => {
      const questionsData = questions
        .filter((question) => question.principle === principle.id)
        .map((question) => {
          const answersData = answers
            .filter((answer) => answer.question === question.id)
            .map((answer) => {
              const answerKeyName = `${principle.id}-${question.id}-${answer.id}`;

              const attachedObjects = answer.attachedObjects.map((object) => ({
                title: (
                  <EntityRow
                    answerId={answer.id}
                    entityId={object.id}
                    form={form}
                    entityType={OptionType.OBJECT.value}
                  />
                ),
                isEndNode: true,
                checked: true,
                key: `${answerKeyName}-objects-${object.id}`,
              }));

              const attachedNarratives = answer.attachedNarratives.map(
                (narrative) => ({
                  title: (
                    <EntityRow
                      answerId={answer.id}
                      entityId={narrative.id}
                      form={form}
                      entityType={OptionType.NARRATIVE.value}
                    />
                  ),
                  isEndNode: true,
                  key: `${answerKeyName}-narratives-${narrative.id}`,
                }),
              );

              let content;
              if (answer.textAnswer === "") {
                content = questions
                  .find((i) => i.id === answer.question)
                  .options.find((i) => i.id === answer.option).label;
              } else {
                content = answer.textAnswer;
              }
              return {
                title: content,
                key: `${principle.id}-${question.id}-${answer.id}`,
                disabled: isDefault,
                isEndNode:
                  attachedNarratives.length === 0 &&
                  attachedObjects.length === 0,
                children: [...attachedObjects, ...attachedNarratives],
              };
            });
          return {
            title: <Title level={5}>{question.content}</Title>,
            key: `${principle.id}-${question.id}`,
            disabled: answersData.length === 0 || isDefault,
            children: answersData,
          };
        });

      return {
        title: (
          <Title
            level={5}
            style={{ color: principle.color, textTransform: "uppercase" }}
          >
            {principle.title}
          </Title>
        ),
        key: `${principle.id}`,
        disabled:
          questionsData.every((i) => i.children.every((j) => j.disabled)) ||
          isDefault,
        children: questionsData,
      };
    });
  };

  const handleCheck = (checkedKeys) => {
    const selectedAnswers = {};

    checkedKeys.forEach((key) => {
      if (key.split("-").length < 3) {
        return;
      }

      const principle = parseInt(key.split("-")[0]);
      const answer = parseInt(key.split("-")[2]);

      if (key.split("-").length === 3) {
        if (!selectedAnswers[answer]) {
          selectedAnswers[answer] = {
            answer,
            principle,
            objects: [],
            narratives: [],
          };
        }
        return;
      }

      const type = key.split("-")[3];
      const entityId = parseInt(key.split("-")[4]);
      if (selectedAnswers[answer]) {
        selectedAnswers[answer][type].push(entityId);
      } else {
        selectedAnswers[answer] = {
          answer,
          principle,
          objects: [],
          narratives: [],
        };
        selectedAnswers[answer][type].push(entityId);
      }

      if (isDefault) {
        form.setValue(
          "entitiesSharingRights",
          entitiesSharingRights.map((i) => {
            if (
              i.id === entityId &&
              i.entityType === type.substring(0, type.length - 1).toUpperCase()
            ) {
              return {
                ...i,
                sharingRights: SharingRights.PUBLIC.value,
              };
            }
            return i;
          }),
        );
      }
    });
    form.setValue("answers", selectedAnswers);
  };

  if (
    principlesResponse.isLoading ||
    questionsResponse.isLoading ||
    answersResponse.isLoading
  ) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="mt-3">
      {treeData.length > 0 && (
        <Tree
          checkable
          defaultExpandAll={true}
          defaultCheckedKeys={defaultSelected}
          selectable={false}
          onCheck={handleCheck}
          treeData={treeData}
          titleRender={(node) => {
            if (node?.isEndNode) {
              return node.title;
            } else {
              return <span className="tree-title">{node.title}</span>;
            }
          }}
        />
      )}
    </div>
  );
};

PrinciplesForm.propTypes = {
  surveyId: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  defaultSelected: PropTypes.array.isRequired,
};

export default PrinciplesForm;
