import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { App, Col, Row, Spin, theme, Typography } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  useVerifyInviteEmailQuery,
  useRegisterMutation,
  useGetCountriesQuery,
} from "../../redux/service.js";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import { handleErrors } from "../../utilities/index.js";
import { setIsAuthenticated } from "../../redux/users/reducer.js";
import { LocalStorageName } from "../../utilities/constants.jsx";
import Input from "../../components/form/Input.jsx";
import Select from "../../components/form/Select.jsx";
import FormField from "../../components/form/FormField.jsx";
import OrganizationUserPermissionsModal from "../organizations/components/OrganizationUserPermissionsModal.jsx";

const registerSchema = (t) =>
  yup.object().shape({
    organization: yup
      .object({
        country: yup
          .string()
          .typeError(t("form.validation.countryNotValid"))
          .required(t("form.validation.countryRequired")),
        address: yup
          .string()
          .typeError(t("form.validation.addressNotValid"))
          .required(t("form.validation.addressRequired")),
        phoneNumber: yup
          .string()
          .typeError(t("form.validation.phoneNumberNotValid"))
          .nullable(),
        email: yup
          .string()
          .email(t("form.validation.emailNotValid"))
          .typeError(t("form.validation.emailRequired"))
          .required(t("form.validation.enterValidEmail")),
        invites: yup
          .array()
          .of(yup.string().email(t("form.validation.enterValidEmail"))),
      })
      .required(t("form.validation.organizationRequired")),
    user: yup.object({
       password: yup
        .string()
        .typeError(t("form.validation.passwordNotValid"))
        .min(8, t("form.validation.passwordLengthValidation"))
        .matches(/[A-Z]/, t("form.validation.passwordCapitalLetterValidation"))
        .matches(/[0-9]/, t("form.validation.passwordNumberValidation"))
        .required(t("form.validation.passwordRequired")),
      confirmPassword: yup
        .string()
        .typeError(t("form.validation.passwordNotValid"))
        .oneOf(
          [yup.ref("password"), null],
          t("form.validation.passwordsMustMatch"),
        )
        .required(t("form.validation.passwordRequired")),
      firstName: yup.string().typeError(t("form.validation.firstNameNotValid")).required(t("form.validation.firstNameRequired")),
      lastName: yup.string().typeError(t("form.validation.lastNameNotValid")).required(t("form.validation.lastNameRequired")),
    }),
  });

const { Title, Text } = Typography;

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { notification } = App.useApp();
  const [step, setStep] = useState(0);
  const queryParams = new URLSearchParams(location.search);
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const [register, { isLoading }] = useRegisterMutation();
  const { data, isSuccess, error, isFetching } = useVerifyInviteEmailQuery(
    queryParams.get("token"),
  );
  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();
  const [openPermissionsModal, setOpenPermissionsModal] = useState(false);

  const form = useForm({
    resolver: yupResolver(registerSchema(t)),
    mode: "onChange",
    defaultValues: {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      },
      organization: {
        country: "",
        address: "",
        phoneNumber: "",
        email: "",
        invites: [],
      },
      token: "",
    },
  });

  const steps = [
    {
      validationFields: ["user"],
      content: (
        <Title
          level={4}
          style={{
            color: token.colorPrimaryText,
            fontSize: "clamp(18px, 2vw, 25px)",
          }}
          className="text-weight-bold"
        >
          {t("messages.welcome")}
          <br />
          {t("messages.addAccountPasswordAndName")}
        </Title>
      ),
      formFields: [
        {
          label: t("form.labels.email"),
          field: <Input disabled name="user.email" control={form.control} />,
        },
        {
          label: t("form.labels.password"),
          required: true,
          field: (
            <Input htmlType="password" name="user.password" control={form.control} />
          ),
        },
        {
          label: t("form.labels.confirmPassword"),
          required: true,
          field: (
            <Input
              htmlType="password"
              name="user.confirmPassword"
              control={form.control}
            />
          ),
        },
        {
          label: t("form.labels.firstName"),
          required: true,
          field: <Input name="user.firstName" control={form.control} />,
        },
        {
          label: t("form.labels.lastName"),
          required: true,
          field: <Input name="user.lastName" control={form.control} />,
        },
      ],
    },
    ...(queryParams.get("admin_invite") === "true"
      ? [
          {
            validationFields: ["organization.country", "organization.address", "organization.phoneNumber", "organization.email"],
            content: (
              <Title
                level={3}
                style={{
                  color: token.colorPrimaryText,
                  fontSize: "clamp(18px, 2vw, 25px)",
                }}
              >
                {t("registerOrganizationSetup.letsBegin")}
              </Title>
            ),
            formFields: [
              {
                label: t("form.labels.organizationName"),
                field: (
                  <Input
                    name="organization.name"
                    disabled
                    placeholder={t("form.placeholders.university")}
                    control={form.control}
                  />
                ),
              },
              {
                label: t("form.labels.country"),
                required: true,
                field: (
                  <Select
                    name="organization.country"
                    placeholder={t("form.placeholders.selectCountry")}
                    options={isCountriesSuccess ? countries : []}
                    control={form.control}
                  />
                ),
              },
              {
                label: t("form.labels.organizationAddress"),
                required: true,
                field: (
                  <Input
                    name="organization.address"
                    placeholder={t("form.placeholders.streetCountry")}
                    control={form.control}
                  />
                ),
              },
              {
                label: t("form.labels.contactNumber"),
                field: (
                  <Input
                    name="organization.phoneNumber"
                    htmlType="phone"
                    placeholder={t("form.placeholders.phoneNumber")}
                    control={form.control}
                  />
                ),
              },
              {
                label: t("form.labels.contactEmail"),
                required: true,
                field: (
                  <Input
                    name="organization.email"
                    placeholder={t("form.placeholders.email")}
                    control={form.control}
                  />
                ),
              },
            ],
          },
          {
          validationFields: ["organization.invites"],
            content: (
              <div className="mt-5">
                <Title
                  level={3}
                  style={{
                    color: token.colorPrimaryText,
                    fontSize: "clamp(18px, 2vw, 25px)",
                  }}
                  className="mb-3"
                >
                  {t("messages.welcome")}
                </Title>
                <Text className="mb-4">
                  {t("registerOrganizationSetup.addUsersToOrganization")}
                </Text>
                <div className="mt-3">
                  <Text>
                    <a
                      onClick={() => setOpenPermissionsModal(true)}
                      style={{ color: token.linkColor }}
                    >
                      {t("registerOrganizationSetup.learnMoreAboutPermissions")}
                    </a>
                  </Text>
                </div>
              </div>
            ),
            formFields: [
              {
                label: t("form.labels.addOrganizationUsers"),
                field: (
                  <Select
                    mode="tags"
                    name="organization.invites"
                    placeholder={t("form.placeholders.enterEmail")}
                    open={false}
                    control={form.control}
                  />
                ),
              },
            ],
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (isSuccess && !form.formState.isDirty) {
      form.reset({
        user: {
          email: data.email,
        },
        ...(data.organization
          ? {
              organization: {
                name: data.organization.name,
                country: data.organization.country,
                address: data.organization.address,
                phoneNumber: data.organization.phoneNumber,
                email: data.organization.email,
              },
            }
          : {}),
        token: queryParams.get("token"),
      });
    }
  }, [isSuccess]);

  const handleSubmit = (values) => {
    register(values)
      .unwrap()
      .then((data) => {
        notification.success({ message: t("registrationSuccess") });
        dispatch(
          setIsAuthenticated({ isAuthenticated: true, user: data.user }),
        );
        window.localStorage.setItem(
          LocalStorageName,
          JSON.stringify({
            accessExpiration: data.accessExpiration,
            refreshExpiration: data.refreshExpiration,
          }),
        );
        navigate("/");
      })
      .catch((error) => {
        if (error.status === 400) {
          const attributeName = error.data.errors[0].attr;
          let stepIndex = null;
          steps.forEach((step, index) => {
            if ((step?.validationFields || []).includes(attributeName)) {
              stepIndex = index;
            }
          });

          if (stepIndex !== null) {
            setStep(stepIndex);
          }
        }
        handleErrors(error, form.setError, notification);
      });
  };

  const handleNext = async () => {
    const validationFields = steps[step]?.validationFields;
    const isValid = await form.trigger(validationFields);

    if (isValid) {
      if (queryParams.get("admin_invite") === "true") {
        if ([0, 1].includes(step)) {
          setStep((oldVal) => oldVal + 1);
        } else {
          handleSubmit(form.getValues());
        }
      } else {
        handleSubmit(form.getValues());
      }
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep((oldVal) => oldVal - 1);
    }
  };

  if (isFetching) {
    return (
      <div className="w-50 m-auto text-center mt-3 mb-4 m-auto">
        <Spin size="large" className="mt-5" />
      </div>
    );
  }

  return (
    <div className="m-auto form-wrapper" style={{ width: "75%" }}>
      <div className="text-center mt-3 mb-4 w-85 m-auto">
        {isSuccess ? (
          <form className="m-auto">
            <div className="text-start">
              <div className="text-center mt-3 mb-4 m-auto">
                {steps[step].content}
              </div>
              <div
                className="w-75 m-auto text-start"
                style={{ maxWidth: "400px" }}
              >
                {steps[step].formFields.map((field) => (
                  <FormField
                    key={field.label}
                    label={field.label}
                    required={field.required}
                    field={field.field}
                  />
                ))}
              </div>
            </div>
            <Row className="justify-content-center">
              {step > 0 && (
                <Col className="me-3">
                  <PrimaryButton
                    className="mb-3 mt-3"
                    disabled={isLoading}
                    onClick={handleBack}
                    style={{ fontWeight: 500 }}
                    size="large"
                  >
                    {t("buttons.back")}
                  </PrimaryButton>
                </Col>
              )}
              <Col>
                <PrimaryButton
                  className="mb-3 mt-3"
                  disabled={isLoading}
                  onClick={handleNext}
                  style={{ fontWeight: 500 }}
                  size="large"
                >
                  {step === 2 || queryParams.get("admin_invite") !== "true"
                    ? t("buttons.completeSetup")
                    : t("buttons.next")}
                </PrimaryButton>
                {step === 2 && (
                  <div>
                    <a onClick={handleNext} style={{ color: token.linkColor }}>
                      {t("skipStep")}
                    </a>
                  </div>
                )}
              </Col>
            </Row>
          </form>
        ) : (
          <div className="mt-5">
            <Title level={4} style={{ color: token.colorPrimaryText }}>
              {t(`errors.${error.data.errors[0].code}`, {
                defaultValue: error.data.errors[0].detail,
              })}
            </Title>
          </div>
        )}
      </div>
      <OrganizationUserPermissionsModal
        open={openPermissionsModal}
        onCancel={() => setOpenPermissionsModal(false)}
      />
    </div>
  );
};

export default Register;
