import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  useLazyGetCommentsQuery,
  useLazyPaginateFilterDiscussionsQuery,
  useLazyPaginateFilterNarrativesQuery,
} from "../../../redux/service.js";
import ContentTab, { EntityDetails } from "../../../components/ContentTab.jsx";
import UserForm from "../components/UserForm.jsx";
import Tabs from "../../../components/Tabs.jsx";
import { ProfileCommentDetails } from "./index.jsx";

const PrivateProfile = ({ userId }) => {
  const { t } = useTranslation();

  return (
    <div className="ms-5 mb-4">
      <div>
        <Tabs
          type="card"
          defaultActiveKey="profile"
          items={[
            {
              label: t("labels.profile"),
              key: "profile",
              children: <UserForm userId={userId} />,
            },
            {
              label: t("labels.narratives", { plural: "s" }),
              key: "narratives",
              children: (
                <ContentTab
                  filters={{ createdBy: userId }}
                  getPaginatedContent={useLazyPaginateFilterNarrativesQuery}
                  orderingField="published_date"
                  renderComponent={(narrative) => (
                    <EntityDetails entity={narrative} path="/narratives" />
                  )}
                />
              ),
            },
            {
              label: t("discussions"),
              key: "communityDiscussions",
              children: (
                <ContentTab
                  filters={{ createdBy: userId }}
                  getPaginatedContent={useLazyPaginateFilterDiscussionsQuery}
                  orderingField="published"
                  renderComponent={(discussion) => (
                    <EntityDetails entity={discussion} path="/discussions" />
                  )}
                />
              ),
            },
            {
              label: t("comments"),
              key: "communityComments",
              children: (
                <ContentTab
                  filters={{ createdBy: userId }}
                  getPaginatedContent={useLazyGetCommentsQuery}
                  orderingField="created_at"
                  renderComponent={(comment) => (
                    <ProfileCommentDetails entity={comment} />
                  )}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

PrivateProfile.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default PrivateProfile;
