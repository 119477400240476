import { useEffect, useState } from "react";

import { Input } from "antd";
import PropTypes from "prop-types";
import FieldError from "./FieldError.jsx";

const DebouncedInput = ({
  onChange,
  placeholder,
  initialValue,
  errors,
  rows,
  isTextArea,
  ...props
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (value !== initialValue) {
      const delayInputTimeoutId = setTimeout(() => onChange(value), 1500);
      return () => clearTimeout(delayInputTimeoutId);
    }
  }, [value]);

  const Component = isTextArea ? Input.TextArea : Input;

  return (
    <div>
      <Component
        style={{ borderRadius: 0 }}
        type="text"
        rows={rows}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        {...props}
      />
      {errors && <FieldError errors={errors} />}
    </div>
  );
};

DebouncedInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  isTextArea: PropTypes.bool,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  rows: PropTypes.number,
};

DebouncedInput.defaultProps = {
  isTextArea: true,
  placeholder: "",
  initialValue: "",
  errors: null,
  rows: 7,
};

export default DebouncedInput;
