import { useEffect } from "react";

import { App, Modal } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import PrimaryButton from "../../../../../components/PrimaryButton.jsx";
import FormField from "../../../../../components/form/FormField.jsx";
import Input from "../../../../../components/form/Input.jsx";
import { handleErrors } from "../../../../../utilities/index.js";
import {
  useCreatePrincipleMutation,
  useUpdatePrincipleMutation,
} from "../../../../../redux/service.js";
import Upload from "../../../../../components/form/Upload.jsx";
import ColorPicker from "../../../../../components/form/ColorPicker.jsx";

const iconSupportedFileFormats = [".jpeg", ".jpg", ".png", ".svg"];

const principleCreateSchema = (t) =>
  yup.object().shape({
    title: yup
      .string()
      .typeError(t("form.validation.titleNotValid"))
      .required(t("form.validation.titleRequired")),
    description: yup
      .string()
      .typeError(t("form.validation.descriptionNotValid"))
      .required(t("form.validation.descriptionRequired")),
    label: yup.string().typeError(t("form.validation.labelNotValid")),
    color: yup.string().typeError(t("form.validation.colorNotValid")),
    icon: yup
      .mixed()
      .test("fileType", t("form.validation.fileFormatInvalid"), (value) => {
        if (!value) {
          return true;
        }
        return iconSupportedFileFormats.includes(value.type);
      })
      .nullable(),
  });

const defaultValues = {
  title: "",
  description: "",
  label: "",
  color: "#486c37",
  icon: null,
};

const PrincipleModalForm = ({ open, onCancel, surveyId, data }) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const [createPrinciple] = useCreatePrincipleMutation();
  const [updatePrinciple] = useUpdatePrincipleMutation();

  const form = useForm({
    resolver: yupResolver(principleCreateSchema(t)),
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    if (data) {
      Object.keys(data).map((key) => {
        if ([...Object.keys(defaultValues), "id"].includes(key)) {
          form.setValue(key, data[key]);
        }
      });
    }
  }, [data]);

  const onSubmit = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((i) => {
      if (![null, undefined].includes(values[i])) {
        formData.set(i, values[i]);
      }
    });
    formData.set("survey", surveyId);
    let action;
    if (values?.id) {
      action = updatePrinciple({ id: values.id, data: formData });
    } else {
      action = createPrinciple(formData);
    }
    action
      .unwrap()
      .then(() => {
        onCancel();
        form.reset();
      })
      .catch((errors) => handleErrors(errors, form.setError, notification));
  };

  return (
    <Modal
      open={open}
      destroyOnClose
      width={600}
      title={
        data?.id
          ? t("form.labels.updatePrinciple")
          : t("form.labels.createPrinciple")
      }
      onCancel={onCancel}
      footer={[
        <PrimaryButton key="cancel" onClick={onCancel}>
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton key="next" onClick={form.handleSubmit(onSubmit)}>
          {t("buttons.submit")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <FormField
        label={t("form.labels.title")}
        required
        field={
          <Input
            name="title"
            placeholder={t("form.placeholders.title")}
            control={form.control}
          />
        }
      />
      <FormField
        label={t("form.labels.description")}
        required
        field={
          <Input
            name="description"
            placeholder={t("form.placeholders.description")}
            control={form.control}
          />
        }
      />
      <FormField
        label={t("form.labels.label")}
        field={
          <Input
            name="label"
            placeholder={t("form.placeholders.label")}
            control={form.control}
          />
        }
      />
      <FormField
        label={t("form.labels.icon")}
        field={
          <Upload
            name="icon"
            type="dropzone"
            buttonTitle={t("buttons.uploadIcon")}
            supportedFormats={iconSupportedFileFormats}
            control={form.control}
          />
        }
      />
      <FormField
        label={t("form.labels.color")}
        field={<ColorPicker name="color" control={form.control} />}
      />
    </Modal>
  );
};

PrincipleModalForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  surveyId: PropTypes.string.isRequired,
  data: PropTypes.object,
};

PrincipleModalForm.defaultProps = {
  data: null,
};

export default PrincipleModalForm;
