import { useTranslation } from "react-i18next";
import { App, Modal, Spin, theme, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { useUpdateSurveyDetailsMutation } from "../../../../redux/service.js";
import PrimaryButton from "../../../../components/PrimaryButton.jsx";

const { Title } = Typography;

const SubmissionConfirmationModal = ({ open, onCancel, survey }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const { notification } = App.useApp();
  const navigate = useNavigate();

  const [submitSurvey, submitSurveyResponse] = useUpdateSurveyDetailsMutation();

  const handleSubmitSurvey = () => {
    submitSurvey({
      id: survey.details.id,
      data: { isSubmitted: true },
    })
      .unwrap()
      .then(() => {
        notification.success({ message: t("surveySubmitted") });
        navigate("/");
      })
      .catch((error) =>
        notification.error({ message: error.data.errors[0].detail }),
      )
      .finally(onCancel);
  };

  return (
    <Modal
      open={open}
      title={t("confirmSubmission")}
      onCancel={onCancel}
      footer={[
        <PrimaryButton key="cancel" onClick={onCancel} type="secondary">
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton
          key="confirm"
          onClick={handleSubmitSurvey}
          disabled={submitSurveyResponse.isLoading}
        >
          {submitSurveyResponse.isLoading && (
            <Spin className="me-2" size="small" />
          )}{" "}
          {t("buttons.confirm")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      {submitSurveyResponse.isLoading ? (
        <div>
          <Title level={5}>{t("reportIsBeingSubmitted")}</Title>
        </div>
      ) : (
        <Title level={5} style={{ color: token.colorTextThird }}>
          {t("submitConfirmation")}
        </Title>
      )}
    </Modal>
  );
};

SubmissionConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  survey: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SubmissionConfirmationModal;
