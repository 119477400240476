import { useEffect, useState } from "react";

import { Pagination, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import {useNavigate} from "react-router-dom";

import Select from "./form/Select.jsx";
import NoResults from "./NoResults.jsx";

const { Title, Text } = Typography;

const itemRender = (_, type, originalElement, hasNext, hasPrevious) => {
  if (type === "prev") {
    return (
      <a
        className="py-2 px-3"
        style={{
          border: "1px solid rgba(198, 206, 220, 1)",
          color: hasPrevious ? "black" : "silver",
        }}
      >
        <LeftOutlined /> Previous
      </a>
    );
  }
  if (type === "next") {
    return (
      <a
        className="py-2 px-3"
        style={{
          border: "1px solid rgba(198, 206, 220, 1)",
          color: hasNext ? "black" : "silver",
        }}
      >
        Next <RightOutlined />
      </a>
    );
  }
  return originalElement;
};

export const EntityDetails = ({ entity, path }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();

  return (
    <div
      key={entity.id}
      style={{ borderBottom: "1px solid rgba(1, 1, 46, 0.134)" }}
      className="py-3"
    >
      <a onClick={() => navigate(`${path}/${entity.id}`)}>
        <Title level={4} style={{ color: token.colorTextThird }} className="mb-2">
          {entity.title}
        </Title>
      </a>
      <Text style={{ fontSize: "16px", color: "rgba(126, 128, 138, 1)" }}>
        {DateTime.fromISO(entity.createdAt).toFormat("dd MMM yyyy")}
      </Text>
    </div>
  );
};

EntityDetails.propTypes = {
  entity: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

const defaultPageSize = 10;

const ContentTab = ({
  filters,
  getPaginatedContent,
  orderingField,
  renderComponent,
}) => {
  const { t } = useTranslation();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      ordering: `-${orderingField}`,
      ...filters,
    },
  });
  const formData = form.watch();
  const [paginateAndFilterContent, contentResponse] = getPaginatedContent();
  const [pageSize, setPageSize] = useState(defaultPageSize);

  useEffect(() => {
    paginateAndFilterContent({
      page: 1,
      pageSize: defaultPageSize,
      ...formData,
    });
  }, []);

  useEffect(() => {
    paginateAndFilterContent({
      page: 1,
      pageSize: defaultPageSize,
      ...formData,
    });
  }, []);

  const handlePaginationChange = (page, pageSize) => {
    setPageSize(pageSize);
    paginateAndFilterContent({ page, pageSize, ...formData });
  };

  return (
    contentResponse.isSuccess && (
      <div className="mt-3">
        {orderingField && (
          <div
            className="mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Title level={5} className="me-3">
              {t("sortBy")}:
            </Title>
            <Select
              style={{ borderRadius: 0 }}
              name="ordering"
              control={form.control}
              options={[
                { label: "Most recent", value: `-${orderingField}` },
                { label: "Oldest", value: orderingField },
              ]}
            />
          </div>
        )}
        {contentResponse.data.results.map((i, key) => renderComponent(i, key))}
        {contentResponse.data.results.length === 0 ? (
          <NoResults />
        ) : (
          <Pagination
              style={{ justifyContent: "center" }}
            rootClassName="mt-4 text-center"
            total={contentResponse.data.count}
            itemRender={(...props) =>
              itemRender(
                ...props,
                contentResponse.data.next !== null,
                contentResponse.data.previous !== null,
              )
            }
            showSizeChanger
            pageSize={pageSize}
            onChange={handlePaginationChange}
          />
        )}
      </div>
    )
  );
};

ContentTab.propTypes = {
  filters: PropTypes.object.isRequired,
  getPaginatedContent: PropTypes.func.isRequired,
  orderingField: PropTypes.string,
  renderComponent: PropTypes.func.isRequired,
};

ContentTab.defaultProps = {
  orderingField: null,
};

export default ContentTab;
