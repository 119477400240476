import { useEffect, useRef } from "react";

import PropTypes from "prop-types";

const OutsideClickHandler = ({ onOutsideClick, isPopoverOpen, children }) => {
  const wrapperRef = useRef(null);
  const hiddenInputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onOutsideClick(hiddenInputRef?.current?.value === "true");
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div ref={wrapperRef}>
      <input
        type="hidden"
        value={isPopoverOpen}
        id="outside-click-handler"
        ref={hiddenInputRef}
      />
      {children}
    </div>
  );
};

OutsideClickHandler.propTypes = {
  onOutsideClick: PropTypes.func.isRequired,
  isPopoverOpen: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default OutsideClickHandler;
