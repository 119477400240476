import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Col, Row, theme, Typography } from "antd";
import { DateTime } from "luxon";
import {useNavigate} from "react-router-dom";

import {
  useGetCountriesQuery,
  useLazyGetMembershipsQuery,
  useLazyPaginateFilterNarrativesQuery,
  useLazyPaginateFilterObjectsQuery, useLazyPaginateFilterSurveyViewsQuery,
} from "../../../redux/service.js";
import Tabs from "../../../components/Tabs.jsx";
import ContentTab, { EntityDetails } from "../../../components/ContentTab.jsx";
import {
  Chapters,
  OrganizationCommunicationStatuses,
} from "../../../utilities/constants.jsx";
import RoleBadge from "../../../components/RoleBadge.jsx";
import CommunicatingStatus from "../components/CommunicatingStatus.jsx";

const { Title, Text } = Typography;

const ReportDetails = ({ report }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();

  return (
    <div
      key={report.survey.id}
      style={{ borderBottom: "1px solid rgba(1, 1, 46, 0.134)" }}
      className="py-3"
    >
      <a onClick={() => navigate(`/reports/${report.surveyDetails.survey}/report?view=${report.uuid}`)}>
        <Title level={4} style={{ color: token.colorTextThird }} className="mb-2">
          {report.title}
        </Title>
      </a>
      {report.submittedAt && (
        <Text style={{ fontSize: "16px", color: "rgba(126, 128, 138, 1)" }}>
          {DateTime.fromISO(report.submittedAt).toFormat("dd MMM yyyy")}
        </Text>
      )}
    </div>
  );
};

ReportDetails.propTypes = {
  report: PropTypes.object.isRequired,
};

const MemberDetails = ({ member }) => {
  const { token } = theme.useToken();

  return (
    <div
      key={member.id}
      style={{ borderBottom: "1px solid rgba(1, 1, 46, 0.134)" }}
      className="py-3"
    >
      <Title level={5} style={{ color: token.colorTextThird }} className="mb-2">
        {member.user.fullName}
        <span className="ms-3">
          <RoleBadge roleName={member.role} />
        </span>
      </Title>
    </div>
  );
};

MemberDetails.propTypes = {
  member: PropTypes.object.isRequired,
};

const PublicOrganizationProfile = ({ organization }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();

  return (
    <div className="mb-4">
      <Row className="mt-3 mb-4 ms-3">
        <Col lg={5} md={5} sm={24}>
          <img
            style={{ width: "180px" }}
            src={organization.logo || "/assets/placeholder.png"}
            alt="organization logo"
          />
        </Col>
        <Col lg={19} md={19} sm={24}>
          <div className="mb-2">
            <CommunicatingStatus
              status={OrganizationCommunicationStatuses.getItemByValue(
                organization.communicatingStatus,
              )}
            />
          </div>
          <Title level={3} style={{ color: token.colorTextThird }}>
            {organization.name}
          </Title>
          <Row className="mt-3">
            <Col span={6}>
              <div className="mb-2">
                <Title level={5} style={{ color: token.colorTextThird }}>
                  {t("labels.location")}
                </Title>
                {isCountriesSuccess && (
                  <Text>
                    {organization.address}
                    {organization.country
                      ? ", " +
                        countries.find((i) => i.value === organization.country)
                          ?.label
                      : ""}
                  </Text>
                )}
              </div>
              <div>
                <Title level={5} style={{ color: token.colorTextThird }}>
                  {t("labels.website")}
                </Title>
                <Text>{organization.website}</Text>
              </div>
            </Col>
            <Col span={6}>
              <div>
                <Title level={5} style={{ color: token.colorTextThird }}>
                  {t("labels.chapter")}
                </Title>
                <Text>{Chapters.getLabel(organization.chapter)}</Text>
              </div>
              <div>
                <Title level={5} style={{ color: token.colorTextThird }}>
                  {t("labels.memberSince")}
                </Title>
                <Text>
                  {DateTime.fromISO(organization.createdAt).toFormat(
                    "dd MMM yyyy",
                  )}
                </Text>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ borderTop: "1px solid rgba(211, 212, 219, 1)" }} />
      <Row style={{ margin: "auto" }} className="w-75 my-5">
        {[
          {
            icon: (
              <img
                style={{ width: "40px" }}
                src="/assets/users.svg"
                alt="users icon"
              />
            ),
            label: t("labels.members"),
            count: organization.membersCount,
          },
          {
            icon: (
              <img
                style={{ width: "35px" }}
                src="/assets/survey-icon.svg"
                alt="reports icon"
              />
            ),
            label: t("labels.reports"),
            count: organization.reportsCount,
          },
          {
            icon: (
              <img
                style={{ width: "45px" }}
                src="/assets/narratives-profile-icon.svg"
                alt="narratives icon"
              />
            ),
            label: t("labels.narratives", { plural: "s" }),
            count: organization.narrativesCount,
          },
          {
            icon: (
              <img
                style={{ width: "40px" }}
                src="/assets/object-repository.svg"
                alt="objects icon"
              />
            ),
            label: t("labels.objects", { plural: "s" }),
            count: organization.objectsCount,
          },
        ].map((i) => (
          <Col key={i.label} lg={6} md={6} sm={24} style={{ display: "flex" }}>
            {i.icon}
            <div className="ms-3">
              <Title
                level={2}
                style={{ color: token.colorTextThird, fontWeight: 500 }}
              >
                {i.count}
              </Title>
              <Title
                level={4}
                style={{ color: token.colorTextThird, fontWeight: 500 }}
              >
                {i.label}
              </Title>
            </div>
          </Col>
        ))}
      </Row>
      <Tabs
        type="card"
        defaultActiveKey="narratives"
        items={[
          {
            label: t("labels.members"),
            key: "members",
            children: (
              <ContentTab
                filters={{ organization: organization.id }}
                getPaginatedContent={useLazyGetMembershipsQuery}
                renderComponent={(member, key) => (
                  <MemberDetails member={member} key={key} />
                )}
              />
            ),
          },
          {
            label: t("labels.reports"),
            key: "reports",
            children: (
              <ContentTab
                filters={{ organization: organization.id, isDefault: true }}
                getPaginatedContent={useLazyPaginateFilterSurveyViewsQuery}
                orderingField="created_at"
                renderComponent={(report, key) => (
                  <ReportDetails report={report} key={key} />
                )}
              />
            ),
          },
          {
            label: t("labels.narratives", { plural: "s" }),
            key: "narratives",
            children: (
              <ContentTab
                filters={{ organizations: organization.id }}
                getPaginatedContent={useLazyPaginateFilterNarrativesQuery}
                orderingField="published_date"
                renderComponent={(narrative, key) => (
                  <EntityDetails entity={narrative} key={key} path="/narratives" />
                )}
              />
            ),
          },
          {
            label: t("labels.objects", { plural: "s" }),
            key: "objects",
            children: (
              <ContentTab
                filters={{ organizations: organization.id }}
                getPaginatedContent={useLazyPaginateFilterObjectsQuery}
                orderingField="created_at"
                renderComponent={(object, key) => (
                  <EntityDetails entity={object} key={key} path="/objects" />
                )}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

PublicOrganizationProfile.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default PublicOrganizationProfile;
