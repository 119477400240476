import { useEffect, useRef } from "react";

import Quill from "quill";
import PropTypes from "prop-types";
import "quill-mention/autoregister";
import "quill-mention/dist/quill.mention.css";
import _ from "lodash";

const QuillInput = ({ value }) => {
  const quillRef = useRef(null);
  const identifier = _.uniqueId();

  useEffect(() => {
    const quill = new Quill(`#quill-read-${identifier}`, {
      readOnly: true,
      modules: {
        toolbar: null,
      },
    });

    quill.clipboard.dangerouslyPasteHTML(value);
    quillRef.current = quill;
  }, []);

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.clipboard.dangerouslyPasteHTML(value);
    }
  }, [value]);

  return (
    <div id={`quill-read-${identifier}`} className="quill-read-only"></div>
  );
};

QuillInput.propTypes = {
  value: PropTypes.string.isRequired,
};

export default QuillInput;
