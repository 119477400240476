import { useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { App, Modal } from "antd";

import { usePatchOptionMutation } from "../../../../../redux/service.js";
import {
  ObjectTypes,
  OptionType,
} from "../../../../../utilities/constants.jsx";
import { handleErrors } from "../../../../../utilities/index.js";
import PrimaryButton from "../../../../../components/PrimaryButton.jsx";
import FormField from "../../../../../components/form/FormField.jsx";
import Select from "../../../../../components/form/Select.jsx";
import Input from "../../../../../components/form/Input.jsx";

const requireObjectSchema = (t) =>
  yup.object().shape({
    type: yup
      .string()
      .typeError(t("form.validation.typeNotValid"))
      .required(t("form.validation.typeRequired")),
    objectType: yup
      .string()
      .typeError(t("form.validation.objectTypeNotValid"))
      .required(t("form.validation.objectTypeRequired")),
    suggestedContent: yup.object().shape({
      title: yup.string().typeError(t("form.validation.objectTypeNotValid")),
    }),
  });

export const RequireObjectModal = ({
  open,
  onCancel,
  optionId,
  initialData,
}) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const [patchOption] = usePatchOptionMutation();
  const form = useForm({
    resolver: yupResolver(requireObjectSchema(t)),
    mode: "onChange",
    defaultValues: {
      type: OptionType.OBJECT.value,
      objectType: "",
      suggestedContent: {},
    },
  });

  useEffect(() => {
    if (initialData) {
      form.setValue("objectType", initialData.objectType);
      form.setValue("suggestedContent", initialData.suggestedContent);
    }
  }, [initialData]);

  const onSubmit = (data) => {
    const formData = data;
    if (initialData?.id) {
      formData.id = initialData.id;
    }

    patchOption({ id: optionId, data: { optionEntities: [formData] } })
      .unwrap()
      .then(() => {
        onCancel();
        form.reset();
      })
      .catch((errors) => handleErrors(errors, form.setError, notification));
  };

  return (
    <Modal
      open={open}
      destroyOnClose
      width={600}
      title={t("form.labels.requireObject")}
      onCancel={onCancel}
      footer={[
        <PrimaryButton key="cancel" onClick={onCancel}>
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton key="submit" onClick={form.handleSubmit(onSubmit)}>
          {t("buttons.submit")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <FormField
        label={t("form.labels.objectType")}
        required
        field={
          <Select
            name="objectType"
            options={ObjectTypes.asList()}
            placeholder={t("form.placeholders.objectTypesSelect")}
            control={form.control}
          />
        }
      />
      <FormField
        label={t("form.labels.title")}
        field={
          <Input
            name="suggestedContent.title"
            placeholder={t("form.placeholders.title")}
            control={form.control}
          />
        }
      />
    </Modal>
  );
};

RequireObjectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  optionId: PropTypes.number.isRequired,
  initialData: PropTypes.object,
};

RequireObjectModal.defaultProps = {
  initialData: null,
};

const requireNarrativeSchema = (t) =>
  yup.object().shape({
    type: yup
      .string()
      .typeError(t("form.validation.typeNotValid"))
      .required(t("form.validation.typeRequired")),
    suggestedContent: yup.object().shape({
      title: yup.string().typeError(t("form.validation.objectTypeNotValid")),
      impactPurpose: yup
        .string()
        .typeError(t("form.validation.impactPurposeNotValid")),
      impactStatement: yup
        .string()
        .typeError(t("form.validation.impactStatementNotValid")),
    }),
  });

export const RequireNarrativeModal = ({
  open,
  onCancel,
  optionId,
  initialData,
}) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const [patchOption] = usePatchOptionMutation();
  const form = useForm({
    resolver: yupResolver(requireNarrativeSchema(t)),
    mode: "onChange",
    defaultValues: {
      type: OptionType.NARRATIVE.value,
      suggestedContent: {},
    },
  });

  useEffect(() => {
    if (initialData) {
      form.setValue("suggestedContent", initialData.suggestedContent);
    }
  }, [initialData]);

  const onSubmit = (data) => {
    const formData = data;
    if (initialData?.id) {
      formData.id = initialData.id;
    }

    patchOption({ id: optionId, data: { optionEntities: [formData] } })
      .unwrap()
      .then(() => {
        onCancel();
        form.reset();
      })
      .catch((errors) => handleErrors(errors, form.setError, notification));
  };

  return (
    <Modal
      open={open}
      destroyOnClose
      width={600}
      title={t("form.labels.requireNarrative")}
      onCancel={onCancel}
      footer={[
        <PrimaryButton key="cancel" onClick={onCancel}>
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton key="submit" onClick={form.handleSubmit(onSubmit)}>
          {t("buttons.submit")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <FormField
        label={t("form.labels.title")}
        field={
          <Input
            name="suggestedContent.title"
            placeholder={t("form.placeholders.title")}
            control={form.control}
          />
        }
      />
      <FormField
        label={t("form.labels.impactPurpose")}
        field={
          <Input
            name="suggestedContent.impactPurpose"
            placeholder={t("form.placeholders.impactPurpose")}
            control={form.control}
          />
        }
      />
      <FormField
        label={t("form.labels.impactStatement")}
        field={
          <Input
            name="suggestedContent.impactStatement"
            placeholder={t("form.placeholders.impactStatement")}
            control={form.control}
          />
        }
      />
    </Modal>
  );
};

RequireNarrativeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  optionId: PropTypes.number.isRequired,
  initialData: PropTypes.object,
};

RequireNarrativeModal.defaultProps = {
  initialData: null,
};
