import { App, Modal, theme, Typography } from "antd";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { OrganizationRoles, Roles } from "../../../utilities/constants.jsx";
import Input from "../../../components/form/Input.jsx";
import Select from "../../../components/form/Select.jsx";
import { handleErrors } from "../../../utilities/index.js";
import PrimaryButton from "../../../components/PrimaryButton.jsx";
import FormField from "../../../components/form/FormField.jsx";
import {
  useDeleteMembershipByIdMutation,
  usePatchMembershipByIdMutation,
} from "../../../redux/service.js";

const { Title } = Typography;

const MembershipFormModal = ({
  currentUser,
  open,
  member,
  isDeleteOperation,
  onCancel,
}) => {
  const { token } = theme.useToken();
  const { notification } = App.useApp();
  const [deleteMembershipById] = useDeleteMembershipByIdMutation();
  const [patchInviteById] = usePatchMembershipByIdMutation();
  const { t } = useTranslation();

  const memberSchema = yup.object().shape({
    firstName: yup
      .string()
      .typeError(t("form.validation.firstNameNotValid"))
      .required(t("form.validation.firstNameRequired")),
    lastName: yup
      .string()
      .typeError(t("form.validation.lastNameNotValid"))
      .required(t("form.validation.lastNameRequired")),
    email: yup
      .string()
      .typeError(t("form.validation.emailNotValid"))
      .required(t("form.validation.emailRequired")),
    role: yup
      .string()
      .typeError(t("form.validation.roleNotValid"))
      .required(t("form.validation.roleRequired")),
  });

  const form = useForm({
    resolver: yupResolver(memberSchema),
    mode: "onChange",
    defaultValues: {
      firstName: member.user.firstName,
      lastName: member.user.lastName,
      email: member.user.email,
      role: member.role,
      permissions: member.permissions,
    },
  });

  const fields = [
    {
      label: t("form.labels.firstName"),
      required: true,
      field: (
        <Input
          name="firstName"
          disabled={isDeleteOperation}
          placeholder={t("form.labels.firstName")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.lastName"),
      required: true,
      field: (
        <Input
          name="lastName"
          disabled={isDeleteOperation}
          placeholder={t("form.labels.lastName")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.email"),
      required: true,
      field: (
        <Input
          name="email"
          disabled
          placeholder={t("form.labels.email")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.role"),
      required: true,
      field: (
        <Select
          name="role"
          disabled={isDeleteOperation}
          placeholder={t("form.placeholders.selectRole")}
          options={
            currentUser.role === Roles.ADMIN.value
              ? OrganizationRoles.asList()
              : OrganizationRoles.asList().filter(
                  (i) => i.value !== OrganizationRoles.ADMIN.value,
                )
          }
          control={form.control}
        />
      ),
    },
  ];

  const onSubmit = async () => {
    if (isDeleteOperation) {
      deleteMembershipById(member.id)
        .unwrap()
        .then(() => {
          notification.success({
            message: `${t("messages.deleteMember.start")} ${member.user.fullName} ${t("messages.deleteMember.end")}`,
          });
          onClose();
        })
        .catch((errors) => handleErrors(errors, null, notification));
    } else {
      const isValid = await form.trigger();
      if (isValid) {
        patchInviteById({
          membership: member.id,
          data: form.getValues(),
        })
          .unwrap()
          .then(() => {
            notification.success({
              message: t("messages.memberUpdated"),
            });
            onClose();
          })
          .catch((errors) => handleErrors(errors, form.setError, notification));
      }
    }
  };

  const onClose = () => {
    form.reset();
    onCancel();
  };

  return (
    <Modal
      open={open}
      title={
        isDeleteOperation ? t("buttons.removeUser") : t("buttons.editUser")
      }
      onCancel={onClose}
      footer={[
        <PrimaryButton
          key="cancel"
          onClick={onClose}
          style={{ background: token.colorBackgroundSecondary }}
        >
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton key="confirm" onClick={onSubmit}>
          {isDeleteOperation ? t("buttons.confirm") : t("buttons.submit")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      {isDeleteOperation && (
        <div>
          <Title level={3}>{t("confirm.deleteUserConfirmation")}</Title>
        </div>
      )}
      {fields.map((i) => (
        <FormField key={i.label} {...i} />
      ))}
    </Modal>
  );
};

MembershipFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  isDeleteOperation: PropTypes.bool,
};

MembershipFormModal.defaultProps = {
  isDeleteOperation: false,
};

export default MembershipFormModal;
