import { useLocation } from "react-router-dom";

import NarrativeForm from "./components/NarrativeForm.jsx";
import {
  useCreateAnswerMutation,
  useCreateNarrativeMutation,
  usePatchAnswerMutation,
} from "../../redux/service.js";

const CreateNarrativeForm = () => {
  const location = useLocation();

  const [createNarrative] = useCreateNarrativeMutation();
  const [patchAnswer] = usePatchAnswerMutation();
  const [createAnswer] = useCreateAnswerMutation();

  const saveNarrative = async (values) => {
    const response = await createNarrative(values);

    if (response?.data) {
      if (location.state?.answer) {
        if (location.state.answer?.id) {
          await patchAnswer({
            id: location.state.answer.id,
            data: {
              ...location.state.answer,
              attachedNarrativeIds: [response.data.id],
            },
          });
        } else {
          await createAnswer({
            ...location.state.answer,
            attachedNarrativeIds: [response.data.id],
          });
        }
      }
    }
    return new Promise((resolve, reject) =>
      response?.data ? resolve(response.data) : reject(response.error.data),
    );
  };

  return <NarrativeForm onSubmit={saveNarrative} />;
};

export default CreateNarrativeForm;
