import { useMemo } from "react";

import { theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Input from "../../../components/form/Input.jsx";
import Select from "../../../components/form/Select.jsx";
import DatePicker from "../../../components/form/DatePicker.jsx";
import { SharingRights } from "../../../utilities/constants.jsx";
import FormField from "../../../components/form/FormField.jsx";
import {
  useGetLanguagesQuery,
  useGetMembershipsQuery,
} from "../../../redux/service.js";

const { Title, Text } = Typography;

const ObjectDetailsFormFields = ({ form, organizationId }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const { data: memberships, isSuccess: isMembershipsSuccess } =
    useGetMembershipsQuery({
      organization: organizationId,
      pagination: "off",
    });
  const { data: languages, isSuccess: isLanguagesSuccess } =
    useGetLanguagesQuery();

  const objectDetailsFields = useMemo(() => {
    if (!isLanguagesSuccess || !isMembershipsSuccess) return [];

    return [
      {
        label: t("form.labels.title"),
        required: true,
        field: (
          <Input
            name="title"
            placeholder={t("form.placeholders.title")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.contributors"),
        tooltipContent: (
          <div className="p-2">
            <Title
              className="mb-2"
              style={{ color: token.colorTextThird }}
              level={5}
            >
              {t("labels.contributor")}
            </Title>
            <Text style={{ color: token.colorTextThird }}>
              {t("contributorsHelpText")}
            </Text>
          </div>
        ),
        field: (
          <Select
            name="contributors"
            mode="multiple"
            options={memberships.map((member) => ({
              value: member.user.id,
              label: member.user.fullName,
            }))}
            control={form.control}
            placeholder={t("form.placeholders.contributors")}
          />
        ),
      },
      {
        label: t("form.labels.date"),
        required: true,
        field: (
          <DatePicker
            name="date"
            placeholder={t("form.placeholders.date")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.description"),
        field: (
          <Input
            name="description"
            placeholder={t("form.placeholders.description")}
            type="textarea"
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.language"),
        required: true,
        field: (
          <Select
            name="language"
            options={languages}
            placeholder={t("form.placeholders.language")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.sharingRights"),
        tooltipContent: (
          <div className="p-2">
            <Title
              className="mb-2"
              style={{ color: token.colorTextThird }}
              level={4}
            >
              {t("labels.sharingRights")}
            </Title>
            <div className="mb-2">
              <Title
                className="mb-1"
                style={{ color: token.colorTextThird }}
                level={5}
              >
                {t("labels.public")}
              </Title>
              <Text>{t("sharingRightsPublicHelpText")}</Text>
            </div>
            <div className="mb-2">
              <Title
                className="mb-"
                style={{ color: token.colorTextThird }}
                level={5}
              >
                {t("labels.private")}
              </Title>
              <Text>{t("sharingRightsPrivateHelpText")}</Text>
            </div>
            <div className="mb-2">
              <Title
                className="mb-1"
                style={{ color: token.colorTextThird }}
                level={5}
              >
                {t("labels.prmeCommunity")}
              </Title>
              <Text>{t("sharingRightsPrmeCommunityHelpText")}</Text>
            </div>
          </div>
        ),
        required: true,
        field: (
          <Select
            name="sharingRights"
            options={SharingRights.asList()}
            placeholder={t("form.placeholders.sharingRights")}
            control={form.control}
          />
        ),
      },
    ];
  }, [memberships, languages]);

  return objectDetailsFields.map((i) => <FormField key={i.label} {...i} />);
};

ObjectDetailsFormFields.propTypes = {
  form: PropTypes.object.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default ObjectDetailsFormFields;
