import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showGreyBackground: false,
  breadcrumbs: null,
};

const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setShowGreyBackground: (state, { payload }) => {
      state.showGreyBackground = payload;
    },
    setBreadcrumbs: (state, { payload }) => {
      state.breadcrumbs = payload;
    },
  },
});

export const {
  setShowGreyBackground,
  setBreadcrumbs,
  logout,
} = utilsSlice.actions;
export default utilsSlice.reducer;
