import { useEffect } from "react";

import PropTypes from "prop-types";

import Table from "../../../components/Table.jsx";
import { useLazyPaginateFilterNarrativesQuery } from "../../../redux/service.js";

const NarrativesTable = ({ columns, rowSelection, filters }) => {
  const [paginateAndFilterNarratives, narrativesResponse] =
    useLazyPaginateFilterNarrativesQuery();

  useEffect(() => {
    handleTableChange({ current: 1, pageSize: 10 }, filters);
  }, [filters]);

  const handleTableChange = (pagination, tableFilters) => {
    paginateAndFilterNarratives({
      page: pagination.current,
      pageSize: pagination.pageSize,
      ...tableFilters,
      ...filters,
    });
  };

  return (
    <Table
      data={narrativesResponse.data?.results || []}
      totalCount={narrativesResponse.data?.count || 0}
      isLoading={
        narrativesResponse.isUninitialized || narrativesResponse.isFetching
      }
      columns={columns}
      rowSelection={rowSelection}
      handleTableChange={handleTableChange}
    />
  );
};

NarrativesTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rowSelection: PropTypes.object,
  filters: PropTypes.object,
};

NarrativesTable.defaultProps = {
  rowSelection: null,
  filters: {},
};

export default NarrativesTable;
