import { useState } from "react";
import { Button, List, Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const Tools = ({ items, iconProps, listProps }) => {
  const [open, setOpen] = useState(false);

  const closePopoverWrapper = (func) => {
    return () => {
      func();
      setOpen(false);
    };
  };

  return (
    <>
      <Popover
        content={
          <div>
            <List
              bordered
              dataSource={items}
              renderItem={(item) => (
                <List.Item
                  style={{ padding: 0 }}
                  onClick={closePopoverWrapper(item.onClick)}
                >
                  <Button
                    type="text"
                    style={{
                      width: "100%",
                      height: "40px",
                      textAlign: "left",
                      border: 0,
                      borderRadius: 0,
                    }}
                  >
                    {item.title}
                  </Button>
                </List.Item>
              )}
              {...listProps}
            />
          </div>
        }
        trigger="click"
        open={open}
        placement="bottom"
        onOpenChange={() => setOpen((val) => !val)}
      >
        <MoreOutlined {...iconProps} />
      </Popover>
    </>
  );
};

Tools.propTypes = {
  items: PropTypes.array.isRequired,
  iconProps: PropTypes.object,
  listProps: PropTypes.object,
};

Tools.defaultProps = {
  iconProps: { style: { fontSize: "32px" } },
  listProps: { style: { width: "220px" } },
};

export default Tools;
