import { useMemo } from "react";

import { useController } from "react-hook-form";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import FieldError from "./FieldError.jsx";

const { RangePicker } = DatePicker;

const CustomRangePicker = ({ name, control, isEdit }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  const value = useMemo(() => {
    if (
      Array.isArray(field.value) &&
      field.value.filter((i) => !dayjs.isDayjs(i)).length > 0
    ) {
      const newValues = field.value.map((i) => {
        if (!dayjs.isDayjs(i)) {
          return dayjs(i, "DD-MM-YYYY");
        }
        return i;
      });
      return newValues;
    }
    return field.value;
  }, [field.value]);

  return (
    <div>
      {isEdit ? (
        <RangePicker
          style={{ borderRadius: 0 }}
          {...field}
          value={value}
          format="DD/MM/YYYY"
          status={error && "error"}
        />
      ) : (
        field.value &&
        dayjs.isDayjs(field.value[0]) &&
        dayjs.isDayjs(field.value[1]) && (
          <div>
            {field.value[0].format("DD/MM/YYYY")} :{" "}
            {field.value[1].format("DD/MM/YYYY")}
          </div>
        )
      )}
      {error && <FieldError errors={error} />}
    </div>
  );
};

CustomRangePicker.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
};

CustomRangePicker.defaultProps = {
  isEdit: true,
};

export default CustomRangePicker;
