import { createUrl } from "../../utilities/index.js";

const narrativesService = (builder) => ({
  paginateFilterNarratives: builder.query({
    query: (queryParams) => ({
      url: createUrl("/narratives/", queryParams),
      method: "GET",
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.results.map((i) => ({ type: "Narratives", id: i.id })),
            { type: "Narratives", id: "PARTIAL-LIST" },
            { type: "Narratives" },
          ]
        : [{ type: "Narratives" }],
  }),
  createNarrative: builder.mutation({
    query: (data) => ({
      url: "/narratives/",
      method: "POST",
      data: data,
    }),
    invalidatesTags: [{ type: "Narratives" }, { type: "NarrativeSubjects" }],
    throwOnError: true,
  }),
  updateNarrative: builder.mutation({
    query: ({ narrative, data }) => ({
      url: `/narratives/${narrative}/`,
      method: "PUT",
      data: data,
    }),
    invalidatesTags: (result) =>
      result
        ? [
            { type: "Narratives" },
            { type: "NarrativeSubjects" },
            { type: "Narratives", id: result.id },
          ]
        : [{ type: "Narratives" }, { type: "NarrativeSubjects" }],
  }),
  getNarrativeById: builder.query({
    query: (id) => ({
      url: `/narratives/${id}/`,
      method: "GET",
    }),
    providesTags: (result) =>
      result
        ? [{ type: "Narratives", id: result.id }]
        : [{ type: "Narratives" }],
  }),
  publishNarrative: builder.mutation({
    query: (id) => ({
      url: `/narratives/${id}/publish/`,
      method: "GET",
    }),
    invalidatesTags: (result, error, args) =>
      result ? [{ type: "Narratives", id: args }] : [{ type: "Narratives" }],
  }),
  deleteNarrative: builder.mutation({
    query: (id) => ({
      url: `/narratives/${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error) => (error ? [] : [{ type: "Narratives" }]),
  }),
  incrementNarrativeViews: builder.query({
    query: (id) => ({
      url: `/narratives/${id}/views/`,
      method: "GET",
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Narratives", id: result.id }] : [],
  }),
});

export default narrativesService;
