import { useEffect, useState } from "react";

import { Col, Row, Spin, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  useGetCurrentUserQuery,
  useGetDiscussionsStatisticsQuery,
  useLazyPaginateFilterDiscussionsQuery,
} from "../../redux/service.js";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import Input from "../../components/form/Input.jsx";
import DiscussionBox from "./components/DiscussionBox.jsx";
import TopicsList from "./components/TopicsList.jsx";
import AdminTopics from "./components/ManageTopicsModal.jsx";
import { Roles } from "../../utilities/constants.jsx";

const { Title, Text } = Typography;

const queryParams = {
  pageSize: 10,
  time: "month",
  ordering: "-popular",
};

const Community = () => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const navigate = useNavigate();

  const [topicsModalOpen, setTopicsModalOpen] = useState(false);
  const { data: discussionsStatistics, isSuccess: statisticsSuccess } =
    useGetDiscussionsStatisticsQuery();
  const [
    paginateFilterDiscussions,
    {
      data: discussionsData,
      isSuccess: discussionsIsSuccess,
      isLoading: discussionsLoading,
    },
  ] = useLazyPaginateFilterDiscussionsQuery();
  const { data: currentUser } = useGetCurrentUserQuery();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      title: null,
    },
  });

  useEffect(() => {
    paginateFilterDiscussions({
      page: 1,
      ...queryParams,
    });
  }, []);

  const handleSearch = (values) => {
    navigate(`/community/search?title=${values?.title}`);
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      form.handleSubmit(handleSearch)();
    }
  };

  const loadMoreDiscussions = () =>
    paginateFilterDiscussions({
      page: discussionsData.next,
      ...queryParams,
    });

  return (
    <div>
      <div
        className="w-50 mb-4"
        style={{ margin: "auto", textAlign: "center" }}
      >
        <div>
          <Title level={3} style={{ color: "rgba(74, 105, 156, 1)" }}>
            {t("welcomeToThe")}
          </Title>
          <Title
            level={1}
            className="mb-2"
            style={{ color: token.colorTextThird }}
          >
            {t("commonsCommunity")}
          </Title>
          <Text style={{ color: token.colorTextThird, fontWeight: "bold" }}>
            {t("communityConnectText")}
          </Text>
          {statisticsSuccess && (
            <Row className="mt-4">
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img src="/assets/users.svg" alt="members" className="me-2" />
                <Title level={5} style={{ color: "rgba(74, 105, 156, 1)" }}>
                  {t("discussionMembers", {
                    count: discussionsStatistics.usersCount,
                    plural: discussionsStatistics.usersCount === 1 ? "" : "s",
                  })}
                </Title>
              </Col>
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src="/assets/discussions.svg"
                  alt="discussions"
                  className="me-2"
                />
                <Title level={5} style={{ color: "rgba(74, 105, 156, 1)" }}>
                  {t("discussionsCount", {
                    count: discussionsStatistics.discussionsCount,
                    plural:
                      discussionsStatistics.discussionsCount === 1 ? "" : "s",
                  })}
                </Title>
              </Col>
            </Row>
          )}
        </div>
        <Input
          className="mt-4"
          placeholder={t("form.placeholders.discussionTitle")}
          style={{ borderRadius: 0 }}
          name="title"
          control={form.control}
          onKeyPress={handleEnterPress}
          prefix={<SearchOutlined />}
          suffix={
            <PrimaryButton onClick={form.handleSubmit(handleSearch)}>
              {t("buttons.search")}
            </PrimaryButton>
          }
        />
      </div>
      <TopicsList />
      <div className="mt-5">
        <div>
          <Row>
            <Col span={12} style={{ textAlign: "left" }}>
              <Title level={4} style={{ color: token.colorTextThird }}>
                {t("trendingDiscussions")}
              </Title>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              {currentUser?.role === Roles.ADMIN.value && (
                <PrimaryButton onClick={() => setTopicsModalOpen(true)}>
                  {t("manageTopics")}
                </PrimaryButton>
              )}
            </Col>
          </Row>
          <div className="pe-4">
            {discussionsIsSuccess &&
              discussionsData.results.map((i) => (
                <DiscussionBox discussion={i} key={i.id} />
              ))}
            {discussionsLoading && (
              <div className="mt-3 d-flex justify-content-center align-items-center h-100">
                <Spin size="large" />
              </div>
            )}
          </div>
          {discussionsData?.next && (
            <div style={{ textAlign: "center" }}>
              <PrimaryButton onClick={loadMoreDiscussions} size="large">
                {t("loadMore")}
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          backgroundColor: "rgba(74, 105, 156, 1)",
          textAlign: "center",
        }}
        className="py-5 mt-5"
      >
        <Title level={4} style={{ color: "white" }} className="mb-2">
          {t("joinTheMovement")}
        </Title>
        <PrimaryButton
          style={{
            backgroundColor: "white",
            color: "rgba(74, 105, 156, 1)",
            fontWeight: 600,
          }}
          onClick={() => navigate("/discussions/form")}
        >
          {t("startADiscussion")}
        </PrimaryButton>
      </div>
      <AdminTopics
        open={topicsModalOpen}
        onCancel={() => setTopicsModalOpen(false)}
      />
    </div>
  );
};

export default Community;
