import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { useLazyGetCommentsQuery } from "../../../redux/service.js";
import CommentDetails from "./CommentDetails.jsx";
import CommentForm from "./CommentForm.jsx";
import PrimaryButton from "../../../components/PrimaryButton.jsx";

const pageSizeMainComments = 10;
const pageSizeChildrenComments = 2;

const Comments = forwardRef(
  (
    {
      discussion,
      parentCommentId,
      setParentLoadedComments,
      childCommentsLevel,
      replaceMainParentComment,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const [comments, setComments] = useState([]);
    const [oldParentComments, setOldParentComments] = useState(null);
    const [page, setPage] = useState(1);
    const [getComments, { data: commentsResponse, isSuccess }] =
      useLazyGetCommentsQuery();

    useImperativeHandle(ref, () => ({ setComments }));

    useEffect(() => {
      if (!page) return;

      const params = {
        page,
        page_size: parentCommentId
          ? pageSizeChildrenComments
          : pageSizeMainComments,
        discussion: discussion.id,
        ...(parentCommentId
          ? { parent: parentCommentId }
          : { is_parent: true }),
      };
      getComments(params);
    }, [discussion, parentCommentId, page]);

    useEffect(() => {
      if (isSuccess && !oldParentComments) {
        setComments((prev) => {
          const commentIds = commentsResponse.results.map((i) => i.id);
          const oldComments = prev.filter((i) => !commentIds.includes(i.id));
          const newComments = commentsResponse.results;
          if (parentCommentId) {
            setParentLoadedComments((oldVal) => oldVal + newComments.length);
          }
          return [...oldComments, ...newComments];
        });
      }
    }, [isSuccess, commentsResponse]);

    const handleLoadMoreParentComments = () => {
      setPage(commentsResponse.next);
    };

    const addCommentCallback = (newComment) => {
      const isExistingComment = comments.find((i) => i.id === newComment.id);
      setComments((oldVal) => {
        if (isExistingComment) {
          return oldVal.map((i) => {
            if (i.id === newComment.id) {
              return newComment;
            }
            return i;
          });
        }
        return [...oldVal, newComment];
      });
    };

    const handleBackToMainParentComments = () => {
      setComments(oldParentComments);
      setOldParentComments(null);
    };

    return (
      <div className="comments-section">
        {parentCommentId === null && (
          <div className="mt-5 mb-3">
            <CommentForm submitCommentCallback={addCommentCallback} />
          </div>
        )}
        {oldParentComments && (
          <div
            style={{ color: "#4A699C", fontSize: "15px", fontWeight: 500 }}
            onClick={handleBackToMainParentComments}
          >
            <ArrowLeftOutlined style={{ color: "#4A699C" }} />{" "}
            {t("backToCommentThreads")}
          </div>
        )}
        {comments.map((comment, index) => (
          <div
            key={comment.id}
            style={
              comment.parent && index !== comments.length - 1
                ? { borderLeft: "1px solid rgba(211, 212, 219, 1)" }
                : {}
            }
          >
            <CommentDetails
              discussion={discussion}
              initialComment={comment}
              isLastComment={index === comments.length - 1}
              prevChildCommentsLevel={
                comment.parent ? childCommentsLevel + 1 : 0
              }
              replaceMainParentComment={
                replaceMainParentComment
                  ? replaceMainParentComment
                  : (comment) => {
                      if (!oldParentComments) {
                        setOldParentComments(comments);
                      }
                      setComments([comment]);
                    }
              }
            />
          </div>
        ))}
        {isSuccess && commentsResponse.next && (
          <div
            style={{ textAlign: parentCommentId ? "left" : "center" }}
            className="mt-2 mb-3"
          >
            <PrimaryButton
              onClick={handleLoadMoreParentComments}
              size={parentCommentId ? null : "large"}
            >
              {t("loadMore")}
            </PrimaryButton>
          </div>
        )}
      </div>
    );
  },
);

Comments.propTypes = {
  discussion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdBy: PropTypes.object.isRequired,
  }),
  parentCommentId: PropTypes.number,
  setParentLoadedComments: PropTypes.func,
  childCommentsLevel: PropTypes.number,
  replaceMainParentComment: PropTypes.func,
};

Comments.defaultProps = {
  discussion: null,
  parentCommentId: null,
  setParentLoadedComments: null,
  childCommentsLevel: null,
  replaceMainParentComment: null,
};

Comments.displayName = "Comments";

export default Comments;
