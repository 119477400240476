import { useController } from "react-hook-form";
import { ColorPicker } from "antd";
import FieldError from "./FieldError.jsx";
import PropTypes from "prop-types";

const CustomColorPicker = ({ name, control }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div>
      <ColorPicker
        showText
        size="large"
        format="hex"
        onChangeComplete={(color) => field.onChange(color.toHexString())}
        {...field}
        onChange={() => {}}
      />
      {error && <FieldError errors={error} />}
    </div>
  );
};

CustomColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};

export default CustomColorPicker;
