import { App } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Tools from "../../../components/Tools.jsx";
import { Roles } from "../../../utilities/constants.jsx";
import {
  useDeleteDiscussionMutation,
  useGetCurrentUserQuery,
  useLazyReportDiscussionQuery,
  usePatchDiscussionMutation,
} from "../../../redux/service.js";

const DiscussionTools = ({ discussion, ...props }) => {
  const { notification } = App.useApp();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deleteDiscussion] = useDeleteDiscussionMutation();
  const [patchDiscussion] = usePatchDiscussionMutation();
  const [reportDiscussionById] = useLazyReportDiscussionQuery();
  const { data: currentUser, isSuccess: currentUserSuccess } =
    useGetCurrentUserQuery();

  const handleDeleteDiscussion = () => {
    deleteDiscussion(discussion.id)
      .unwrap()
      .then(() => {
        notification.success({ message: t("discussionDeleted") });
        navigate("/community");
      });
  };

  const pinDiscussion = (isPinned) => {
    patchDiscussion({ id: discussion.id, data: { isPinned } })
      .unwrap()
      .then((data) =>
        notification.success({
          message: t(
            data.isPinned ? "discussionIsPinned" : "discussionIsUnpinned",
          ),
        }),
      );
  };

  const reportDiscussion = () =>
    reportDiscussionById(discussion.id)
      .unwrap()
      .then(() =>
        notification.success({ message: t("discussionReportSuccessfully") }),
      );

  const handleCommentNotifications = (notificationsEnabled) => {
    patchDiscussion({
      id: discussion.id,
      data: { notificationsEnabled },
    })
      .unwrap()
      .then((data) =>
        notification.success({
          message: data.notificationsEnabled
            ? t("commentNotificationsTurnedOn")
            : t("commentNotificationsTurnedOff"),
        }),
      );
  };

  return (
    currentUserSuccess && (
      <Tools
        items={[
          ...(discussion.createdBy.id === currentUser.id
            ? [
                {
                  title: discussion.notificationsEnabled
                    ? t("turnOffCommentNotifications")
                    : t("turnOnCommentNotifications"),
                  onClick: () =>
                    handleCommentNotifications(
                      !discussion.notificationsEnabled,
                    ),
                },
              ]
            : []),
          ...(currentUser.role === Roles.ADMIN.value
            ? [
                {
                  title: t(
                    discussion.isPinned ? "unpinDiscussion" : "pinDiscussion",
                  ),
                  onClick: () => pinDiscussion(!discussion.isPinned),
                },
              ]
            : []),
          ...(currentUser.role === Roles.ADMIN.value ||
          discussion.createdBy.id === currentUser.id
            ? [
                {
                  title: t("buttons.edit"),
                  onClick: () => navigate(`/discussions/form/${discussion.id}`),
                },
                {
                  title: t("buttons.delete"),
                  onClick: handleDeleteDiscussion,
                },
              ]
            : [
                {
                  title: t("report"),
                  onClick: reportDiscussion,
                },
              ]),
        ]}
        {...props}
      />
    )
  );
};

DiscussionTools.propTypes = {
  discussion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    commentsCount: PropTypes.number.isRequired,
    views: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.object.isRequired,
    likesCount: PropTypes.number.isRequired,
    topics: PropTypes.array.isRequired,
    isPinned: PropTypes.bool.isRequired,
    notificationsEnabled: PropTypes.bool,
  }).isRequired,
};

export default DiscussionTools;
