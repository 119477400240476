import PropTypes from "prop-types";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import PrimaryButton from "./PrimaryButton.jsx";

const DeleteConfirmationModel = ({
  title,
  open,
  onSubmit,
  onCancel,
  isLoading,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={title}
      onCancel={onCancel}
      footer={[
        <PrimaryButton key="cancel" type="secondary" onClick={onCancel}>
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton key="confirm" disabled={isLoading} onClick={onSubmit}>
          {t("buttons.confirm")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      {children}
    </Modal>
  );
};

DeleteConfirmationModel.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default DeleteConfirmationModel;
