import { Typography } from "antd";

const { Title, Text } = Typography;

const PrivacyPolicy = () => {
  return (
    <div className="px-5 mt-5">
      <Title level={1} className="mb-3">
        Privacy Policy
      </Title>
      <Title level={5} className="mb-3">
        The UN Global Compact is committed to protecting the privacy of our
        Participants, event attendees and website users. This privacy policy
        applies to all websites and mobile applications linking to or posting
        this privacy policy (each a “Site”).
      </Title>
      <div className="mb-3">
        <Title level={5}>1) Purpose of this privacy policy</Title>
        <Text>
          This privacy policy describes how we use, store, disclose, and process
          personal information that we obtain through any of our Sites,
          including information that you may provide in connection with a letter
          of commitment and online application to become a Participant of the UN
          Global Compact (together, an “Application”), or that we obtain when
          you attend a UN Global Compact event, request content or otherwise
          through the course of our operations. Our Sites are not intended for
          children and we do not knowingly collect personal information relating
          to children. If you are a parent or guardian and you learn that your
          children have provided us with personal information, please contact
          us. If we become aware that we have collected personal information
          from a child under age 16 without verification of parental consent, we
          will take steps to remove that information from our servers. It is
          important that you read this privacy policy together with any other
          privacy policy or fair processing policy we may provide on specific
          occasions when we are collecting or processing personal information
          about you so that you are fully aware of how and why we are using your
          information. The term “personal information” as used in this privacy
          policy shall mean any information that enables us to identify an
          individual, directly or indirectly, by reference to an identifier such
          as name, identification number, location data, online identifier or
          one or more factors specific to the individual.
        </Text>
      </div>
      <div className="mb-3">
        <Title level={5}>2) Who we are</Title>
        <Text>
          This privacy policy is issued on behalf of the UN Global Compact and
          the Foundation for the Global Compact (from now on &quot;we&quot;,
          &quot;us&quot; or &quot;our&quot;). Both these entities are the
          controller and responsible for the following websites (from now on
          “Sites”): If you have any questions about this privacy policy or our
          processing activities, please contact us at info@unglobalcompact.org.
        </Text>
      </div>
      <div className="mb-3">
        <Title level={5}>3) Information we collect</Title>
        <Text>
          a) Information you provide to us We collect personal information that
          is provided to us by Participants, event attendees, event sponsors,
          Site users and others, which may include: • Contact data, such as your
          name, employer, job title, department or similar identifier, postal
          address, email address and telephone numbers. Contact data is provided
          when you register for or attend one of our events, when you request
          content from our Sites or when you otherwise communicate with us using
          the “Contact Us” page on our Sites or via email. Organizations also
          provide contact data when submitting Applications to become a
          Participant of the UN Global Compact. • Credentials, such as
          passwords, secret question and answer, or similar security information
          used for authentication and account access. • Marketing and
          subscription data, such as your preferences in receiving marketing
          from us and our third parties, and your interests when you subscribe
          to email subscriptions on our Sites. • Event data, such as the contact
          data you provide when you register for an event, and a record of your
          participation in our events as an attendee, presenter or a member of
          the press. • Survey information, such as the contact data and content
          you provide when you respond to surveys or our requests for public
          comments, and a record of your participation in groups, forums or task
          forces that we organize or sponsor. • Invoicing information, such as
          invoice data and bank account details to process payments. • Candidate
          data, such as employment history, qualifications, academic
          qualifications and education records, and any other information that
          you provide to us when applying for a role, for example in your
          curriculum vitae, a covering letter, on an application form or during
          an interview, or that we have received from a recruitment agency or
          background check provider. • When you contact us directly via email,
          we use the personal information provided in your email to respond to
          your questions or comments. We may also store your comments for future
          reference. b) Information we collect By accessing the Sites certain
          information about the user, such as Internet protocol (IP) addresses,
          navigation through the Site, the software used and the time spent,
          along with other similar information, will be stored on our servers.
          These will not specifically identify the user. We also may collect any
          telephone number from which you contact us. c) Cookies We use
          “cookies” and similar technologies on our Sites. A cookie is a piece
          of data stored on your device to help us improve your access to our
          Sites and identify repeat visitors to our Sites. For instance, when we
          use a cookie to identify you, you would not have to provide a password
          more than once, thereby saving time while on our Sites. We do not
          apply analytics or marketing cookies for the PRME Commons Site 4) How
          we use information We process personal information for the purposes
          set out in this privacy policy only where we have a valid legal ground
          for doing so under applicable data protection law. The legal ground
          will depend on the purpose for which we process your personal
          information. We use your personal information in the following ways as
          necessary in our legitimate business interests, including to meet our
          obligations to Participants: • To register you as the designated
          contact point for a Participant or Signatory or prospective
          Participant or Signatory of the UN Global Compact and/or joint
          initiatives, • To manage our relationship and status with your
          organization as a Participant or Signatory of the UN Global Compact
          and/or joint initiatives, • To administer or otherwise carry out our
          obligations in relation to any agreement to which we are a party, • To
          provide you with our resources and other content that you have
          requested, • To respond to requests or inquiries. We use your personal
          information in the following ways as necessary for certain legitimate
          interests, or where you have given your consent to such processing to
          the extent required by applicable law (such consent can be withdrawn
          at any time): • To notifying you about changes to our policies and/or
          asking you to take a survey, • To send you our bulletins and
          newsletters, • To share information on specific engagement
          opportunities (including events, webinars, participation in Action
          Platforms, etc.) available to Participants and Signatories of the UN
          Global Compact or other information on activities, resources and
          reports offered by our initiative and/or other joint initiatives
          and/or Global Compact Local Networks and/or partner organizations, •
          To send you communications concerning an individual role you undertake
          as part of the governance of the UN Global Compact and/or other joint
          initiatives, • Targeted outreach to flag high-level engagement
          opportunities, • To manage the implementation of our integrity
          processes with Participants and Signatories or prospective
          Participants and Signatories of the UN Global Compact, including
          through formal and informal engagement, • To respond to inquiries, •
          To share logistic notes about events, webinars and/or other programs,
          • To confirm and administer your participation in online trainings,
          self-assessment tools or hubs, • To deal with any enquiries or
          complaints you or others make, • To gather statistical information to
          make our research products more relevant to you, • To administer and
          protect our operations and our Sites (including troubleshooting, data
          analysis, testing, system maintenance, support, reporting and hosting
          of data), • To deliver relevant content to you and measure or
          understand the effectiveness of that content, and
        </Text>
      </div>
      <div className="mb-3">
        <Title level={5}>5) How we share information</Title>
        <Text>
          Data provided in the Application is shared with the Global Compact
          Local Network in the country where you are located so that you can
          learn about engagement opportunities and activities available at the
          national/local level. If you apply to be a participant to the PRME
          initiative, upon approval of your application we share your data with
          the PRME Chapter representatives. We may share your personal
          information with trusted third parties that provide services to help
          us with our activities. These third parties include our payment
          processing providers, website analytics companies, email service
          providers, IT service providers, conference call providers, events
          management providers, website developers, consultants, accountants,
          lawyers and other professional advisers and others service providers.
          We will only transfer your personal information to trusted third
          parties who provide sufficient guarantees in respect of the technical
          and organizational security measures governing the processing to be
          carried out and who can demonstrate a commitment to compliance with
          those measures. We do not allow our third party service providers to
          use your personal information for their own purposes and only permit
          them to process your personal information for specified purposes and
          in accordance with our instructions. We may disclose personal
          information in response to a court order, subpoena, law enforcement
          proceeding, regulatory inquiry or as otherwise legally required. Such
          disclosure of personal information will be limited (a) to the extent
          necessary to comply with national security, public interest, or law
          enforcement requirements or (b) by statute, government regulation or
          case law that creates conflicting obligations or explicit
          authorizations. Also, we may share personal information in order to
          enforce or apply our terms and other agreements; or to protect the
          rights, property, or safety of the Global Compact Networks, our
          Participants or Signatories, or others. This includes exchanging
          information with other organizations for fraud protection and credit
          risk reduction. We may also share aggregated or anonymous information
          that cannot identify you with third parties. For example, we may
          disclose the number of visitors to our Sites, attendees to our events,
          or the number of people who have downloaded content from our Sites.
        </Text>
      </div>
      <div className="mb-3">
        <Title level={5}>6) How long we keep information</Title>
        <Text>
          We store personal information, in a form which permits us to identify
          individuals, for no longer than is necessary for the purpose for which
          the personal information is processed. We use your personal
          information as necessary to comply with our legal obligations, resolve
          disputes, and enforce our agreements and rights, or if it is not
          technically and reasonably feasible to remove it. Otherwise, we will
          seek to delete personal information within a reasonable timeframe upon
          request.
        </Text>
      </div>
      <div className="mb-3">
        <Title level={5}>7) Security</Title>
        <Text>
          We maintain commercially reasonable security measures to protect
          personal information against unauthorized access and disclosure and
          that are consistent with our operations and generally accepted
          industry standards. These measures include the implementation of
          technical, physical and administrative security safeguards. We cannot
          ensure or warrant the security of any personal information that you
          transmit to us and you agree that you provide this and engage in such
          transmissions at your own risk. Once we receive personal information
          from you, we will endeavor to maintain its security on our systems. We
          have established policies and procedures for securely managing
          personal information and protecting personal information against
          unauthorized access.
        </Text>
      </div>
      <div className="mb-3">
        <Title level={5}>8) Third parties websites</Title>
        <Text>
          Our Sites may contain links to other websites that are not covered by
          this privacy policy and where information practices may be different
          from ours. Our provision of a link to any other website or location is
          for your convenience and does not signify our endorsement of such
          other website or location or its contents. Please be aware that the
          terms of this privacy policy do not apply to these outside websites or
          content, or to any collection of data after you click on links to such
          outside websites.
        </Text>
      </div>
      <div className="mb-3">
        <Title level={5}>
          9) International transfers of personal information
        </Title>
        <Text>
          We are headquartered in the United States. By using any of our Sites
          from outside the United States, you acknowledge that your personal
          information may be accessed by us or transferred to us in the United
          States and to our affiliates, partners, and service providers who are
          located around the world; and that your personal information will be
          transferred to, and stored and processed in, the United States or
          elsewhere in the world where our servers are located.
        </Text>
      </div>
      <div className="mb-3">
        <Title level={5}>10) European residents</Title>
        <Text>
          If you are based in the European Economic Area or the United Kingdom,
          we will only process your personal information for a purpose described
          in this privacy policy if (1) you have provided your consent (which
          can be withdrawn at any time), (2) the processing is necessary for the
          performance of a contract we are about to enter into or have entered
          into with you, (3) we are required by law, or (4) the processing is
          necessary for the purposes of our legitimate commercial interests
          (except where such interests are overridden by your rights and
          interests). If you are based in the European Economic Area or the
          United Kingdom, in certain circumstances you have rights under data
          protection laws in relation to your personal information that we hold
          about you—specifically: • Request access to your personal information.
          You may have the right to request access to any personal information
          we hold about you as well as related information, including the
          purposes for processing the personal information, the recipients or
          categories of recipients with whom the personal information has been
          shared, where possible, the period for which the personal information
          will be stored, the source of the personal information, and the
          existence of any automated decision making. • Request correction of
          your personal information. You may have the right to obtain without
          undue delay the rectification of any inaccurate personal information
          we hold about you. • Request erasure of your personal information. You
          may have the right to request that personal information held about you
          be deleted. • Request restriction of processing your personal
          information. You may have the right to prevent or restrict processing
          of your personal information. • Request transfer of your personal
          information. You may have the right to request transfer of your
          personal information directly to a third party where this is
          technically feasible. Also, where you believe that we have not
          complied with our obligation under this privacy policy or applicable
          data protection law, you have the right to make a complaint to a Data
          Protection Authority in the European Economic Area or the United
          Kingdom. You can exercise any of these rights by emailing us at
          info@unglobalcompact.org.
        </Text>
      </div>
      <div className="mb-3">
        <Title level={5}>11) Changes to this privacy policy</Title>
        <Text>
          We reserve the right to update or modify this privacy policy from time
          to time, with any such changes to be effective prospectively. Please
          visit this web page periodically so that you will be apprised of any
          such changes. Your continued use of any of our Sites after any
          modification to this privacy policy will constitute your acceptance of
          such modification.
        </Text>
      </div>
      <div className="mb-3">
        <Title level={5}>12) Contact us</Title>
        <Text>
          If you have any questions or concerns regarding this privacy notice,
          please contact us via email at: info@unglobalcompact.org.
        </Text>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
