import { useEffect, useMemo, useState } from "react";

import { App, Col, Collapse, Divider, Row, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import createDOMPurify from "dompurify";
import PropTypes from "prop-types";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";

import {
  useDeleteNarrativeMutation,
  useGetCountriesQuery,
  useGetCurrentUserQuery,
  useGetLanguagesQuery,
  useLazyGetMembershipsQuery,
  useLazyGetNarrativeByIdQuery,
  useLazyIncrementNarrativeViewsQuery,
} from "../../redux/service.js";
import ObjectDetails from "../objects/ObjectDetails.jsx";
import {
  OrganizationRoles,
  SharingRights,
} from "../../utilities/constants.jsx";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import Tag from "../../components/Tag.jsx";
import { Helmet } from "react-helmet-async";
import _ from "lodash";

const { Title, Text } = Typography;

const MetaTags = ({ narrative }) => (
  <Helmet>
    <title>{`PRME Commons - ${narrative.title}`}</title>
    <meta name="og:title" content={`PRME Commons - ${narrative.title}`} />
    <meta name="og:url" content={window.location.href} />
    <meta name="og:article" content="article" />
    <meta name="og:description" content={_.truncate(narrative.impactPurpose)} />
  </Helmet>
);

MetaTags.propTypes = {
  narrative: PropTypes.object.isRequired,
};

const ChapterBox = ({ chapter }) => {
  const DOMPurify = createDOMPurify(window);

  return (
    <div>
      <Divider />
      <Title
        className="mb-2"
        level={5}
        style={{ color: "rgba(30, 50, 80, 1)" }}
      >
        {chapter.title}
      </Title>
      {(chapter?.attachedObjects || []).map((object) => (
        <Collapse
          key={object.id}
          style={{
            width: "90%",
          }}
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? <DownOutlined /> : <UpOutlined />
          }
          className="mt-3 mb-4"
          items={[
            {
              key: "1",
              label: (
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      src="/assets/object-repository.svg"
                      className="me-3"
                      style={{ width: "25px" }}
                    />
                  </div>
                  <div>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "600",
                        color: "rgba(96, 100, 108, 1)",
                      }}
                    >
                      {object.title}
                    </Title>
                  </div>
                </div>
              ),
              children: <ObjectDetails initialId={object.id} />,
            },
          ]}
        />
      ))}
      <Text className="mt-5">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(chapter.text, {
              USE_PROFILES: { html: true },
            }),
          }}
        />
      </Text>
    </div>
  );
};

ChapterBox.propTypes = {
  chapter: PropTypes.object.isRequired,
};

const SingleNarrativeView = ({ initialData }) => {
  const { narrativeId } = useParams();
  const DOMPurify = createDOMPurify(window);
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const { data: currentUser } = useGetCurrentUserQuery();
  const [getNarrativeById, response] = useLazyGetNarrativeByIdQuery();
  const [narrativeData, setNarrativeData] = useState(initialData);
  const [getMemberships, membershipsResult] = useLazyGetMembershipsQuery();
  const [incrementNarrativeViews] = useLazyIncrementNarrativeViewsQuery();
  const [deleteNarrativeById] = useDeleteNarrativeMutation();
  const navigate = useNavigate();
  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();
  const { data: languages, isSuccess: isLanguagesSuccess } =
    useGetLanguagesQuery();

  useEffect(() => {
    if (!initialData) {
      getNarrativeById(narrativeId);
    }
  }, [initialData]);

  useEffect(() => {
    if (response.isSuccess) {
      getMemberships({
        organization: response.data.organization,
        pagination: "off",
      });
      setNarrativeData(response.data);
      setTimeout(() => {
        incrementNarrativeViews(response.data.id);
      }, 15000);
    }
  }, [response]);

  const getApplicableDateRange = () => {
    if (initialData) {
      if (narrativeData?.applicableDateRange) {
        return (
          narrativeData.applicableDateRange[0].format("DD MMM YYYY") +
          " - " +
          narrativeData.applicableDateRange[1].format("DD MMM YYYY")
        );
      } else {
        return "";
      }
    } else {
      if (narrativeData.startDate && narrativeData.endDate) {
        return (
          DateTime.fromISO(narrativeData.startDate).toFormat("dd MMM yyyy") +
          " - " +
          DateTime.fromISO(narrativeData.endDate).toFormat("dd MMM yyyy")
        );
      } else {
        return "";
      }
    }
  };

  const deleteNarrative = () => {
    deleteNarrativeById(narrativeId)
      .unwrap()
      .then(() => {
        navigate("/narratives");
        notification.success({ message: t("narrativeDeleted") });
      });
  };

  const canDeleteNarrative = useMemo(() => {
    return (
      response.isSuccess &&
      currentUser &&
      ([
        OrganizationRoles.ADMIN.value,
        OrganizationRoles.SUPERVISOR.value,
      ].includes(currentUser?.organizationRole) ||
        currentUser.id === response.data.createdBy.id)
    );
  }, [currentUser, response.isSuccess]);

  const canEditNarrative = useMemo(() => {
    return (
      response.isSuccess &&
      currentUser &&
      (canDeleteNarrative ||
        response.data.contributors.includes(currentUser.id))
    );
  }, [currentUser, response.isSuccess]);

  return (
    narrativeData && (
      <Row className="mt-4">
        <MetaTags narrative={narrativeData} />
        <Col xl={18} lg={18} md={18} sm={18} className="pe-5">
          <Title
            level={3}
            className="mb-3"
            style={{ fontWeight: "bold", color: "rgba(30, 50, 80, 1)" }}
          >
            {narrativeData.title}
          </Title>
          <div className="mb-3">
            <Title
              className="mb-2"
              level={4}
              style={{ color: "rgba(74, 105, 156, 1)" }}
            >
              {t("form.labels.impactPurpose")}
            </Title>
            <Text style={{ fontSize: "16px" }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(narrativeData.impactPurpose, {
                    USE_PROFILES: { html: true },
                  }),
                }}
              />
            </Text>
          </div>
          <div className="mb-3">
            <Title
              className="mb-2"
              level={4}
              style={{ color: "rgba(74, 105, 156, 1)" }}
            >
              {t("form.labels.impactStatement")}
            </Title>
            <Text style={{ fontSize: "16px" }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(narrativeData.impactStatement, {
                    USE_PROFILES: { html: true },
                  }),
                }}
              />
            </Text>
          </div>
          {narrativeData.chapters.map((chapter) => (
            <ChapterBox key={chapter.id} chapter={chapter} />
          ))}
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={6}
          style={{ backgroundColor: "rgba(237, 237, 237, 1)" }}
          className="px-4 py-5"
        >
          {narrativeId && (
            <div className="align-center mb-3">
              {canEditNarrative && (
                <PrimaryButton
                  onClick={() => navigate(`/narratives/form/${narrativeId}`)}
                >
                  {t("buttons.edit")}
                </PrimaryButton>
              )}
              {canDeleteNarrative && (
                <PrimaryButton onClick={deleteNarrative} className="ms-3">
                  {t("buttons.delete")}
                </PrimaryButton>
              )}
            </div>
          )}
          <div>
            <Title level={5}>{t("form.labels.datePublished")}</Title>
            <Text>
              {narrativeData.publishedDate &&
                DateTime.fromISO(narrativeData.publishedDate).toFormat(
                  "dd MMM yyyy",
                )}
            </Text>
          </div>
          <div className="mt-3">
            <Title level={5}>{t("form.labels.applicableDateRange")}</Title>
            <Text>{getApplicableDateRange()}</Text>
          </div>
          <div className="mt-3">
            <Title level={5}>{t("form.labels.owner")}</Title>
            <Text>{narrativeData?.createdBy?.fullName}</Text>
          </div>
          <div className="mt-3">
            <Title level={5}>{t("form.labels.contributors")}</Title>
            {membershipsResult.isSuccess && (
              <Text>
                {narrativeData.contributors
                  .map(
                    (contributor) =>
                      membershipsResult.data.find(
                        (member) => member.user.id === contributor,
                      )?.user.fullName,
                  )
                  .join(", ")}
              </Text>
            )}
          </div>
          <div className="mt-3">
            <Title level={5}>{t("form.labels.sharingRights")}</Title>
            <Text>{SharingRights.getLabel(narrativeData.sharingRights)}</Text>
          </div>
          <div className="mt-3">
            <Title level={5}>{t("form.labels.language")}</Title>
            <Text>
              {isLanguagesSuccess &&
                languages.filter((i) => i.value === narrativeData.language)[0]
                  ?.label}
            </Text>
          </div>
          <div className="mt-3">
            <Title level={5}>{t("form.labels.country")}</Title>
            <Text>
              {isCountriesSuccess &&
                countries.find((i) => i.value === narrativeData.country)?.label}
            </Text>
          </div>
          <div className="mt-3">
            <Title level={5}>{t("form.labels.subjects")}</Title>
            <Row>
              {narrativeData.subjects.map((i) => (
                <Col className="me-2 mt-2" key={i}>
                  <Tag key={i.id} label={i.name} />
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
    )
  );
};

SingleNarrativeView.propTypes = {
  initialData: PropTypes.object,
};

SingleNarrativeView.defaultProps = {
  initialData: null,
};

export default SingleNarrativeView;
