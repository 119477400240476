import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { App, Col, Row, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { useLoginMutation } from "../../redux/service.js";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import { setIsAuthenticated } from "../../redux/users/reducer.js";
import Input from "../../components/form/Input.jsx";
import { handleErrors } from "../../utilities/index.js";
import { LocalStorageName } from "../../utilities/constants.jsx";
import FormField from "../../components/form/FormField.jsx";

const { Title } = Typography;

const loginSchema = (t) =>
  yup
    .object()
    .shape({
      email: yup
        .string()
        .typeError(t("form.validation.emailNotValid"))
        .email(t("form.validation.enterValidEmail"))
        .required(t("form.validation.enterValidEmail")),
      password: yup
        .string()
        .typeError(t("form.validation.passwordNotValid"))
        .required(t("form.validation.passwordRequired")),
    })
    .required();

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notification } = App.useApp();
  const { token } = theme.useToken();
  const [login, { isLoading }] = useLoginMutation();

  const { t } = useTranslation();

  const { control, handleSubmit, setError } = useForm({
    resolver: yupResolver(loginSchema(t)),
    mode: "onChange",
  });

  const onSubmit = (values) => {
    login(values)
      .unwrap()
      .then((data) => {
        dispatch(
          setIsAuthenticated({ isAuthenticated: true, user: data.user, isFirstLogin: data.isFirstLogin }),
        );
        window.localStorage.setItem(
          LocalStorageName,
          JSON.stringify({
            accessExpiration: data.accessExpiration,
            refreshExpiration: data.refreshExpiration,
          }),
        );
        navigate("/");
      })
      .catch((errors) => {
        handleErrors(errors, setError, notification);
        if (errors.data.errors[0]?.code === "email_verification") {
          navigate("/email-verification");
        }
      });
  };

  return (
    <div className="m-auto form-wrapper" style={{ width: "60%" }}>
      <div className="text-center mb-4 mt-5" style={{ minWidth: "245px" }}>
        <Title
          level={3}
          style={{
            color: token.colorPrimaryText,
            fontSize: "clamp(18px, 2vw, 25px)",
          }}
        >
          {t("titles.signIn")}
        </Title>
      </div>
      <div className="text-center mt-3 mb-4 w-75 m-auto">
        <form onSubmit={handleSubmit(onSubmit)} className="text-start">
          <div className="mb-3">
            <FormField
              label={t("form.labels.email")}
              field={<Input name="email" control={control} />}
            />
          </div>
          <div>
            <Title level={5} className="mb-2">
              {t("form.labels.password")}
            </Title>
            <Input htmlType="password" name="password" control={control} />
            <Row justify="end" style={{ marginTop: "0.5rem" }}>
              <Col className="ms-auto">
                <Link to="/reset-password" style={{ color: token.linkColor }}>
                  {t("form.labels.forgotPassword")}
                </Link>
              </Col>
            </Row>
          </div>
          <div className="mt-3 text-center">
            <PrimaryButton
              className="mb-3"
              disabled={isLoading}
              htmlType="submit"
            >
              {t("buttons.signIn")}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
