import {useEffect, useMemo, useRef, useState} from "react";

import PropTypes from "prop-types";
import { App, Divider, Modal, Spin, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { clientApi } from "../../../utilities/api.js";
import Input from "../../../components/form/Input.jsx";
import PrimaryButton from "../../../components/PrimaryButton.jsx";

const { Title, Text } = Typography;

const reportDownloadSchema = (t) =>
  yup
    .object()
    .shape({
      email: yup
        .string()
        .typeError(t("form.validation.emailNotValid"))
        .email(t("form.validation.enterValidEmail"))
        .required(t("form.validation.enterValidEmail")),
    })
    .required();

const DownloadSurveyModal = ({ open, onCancel, startDownload, isDownloadInProgress }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const [isGenerationStarted, setIsGenerationStarted] = useState(false);
  const form = useForm({
    resolver: yupResolver(reportDownloadSchema(t)),
    mode: "onChange",
    defaultValues: {
      email: null,
    },
  });

  useEffect(() => {
      return () => {
          setIsGenerationStarted(false);
      }
  }, []);

  const handleEmailDownload = async () => {
    const isValid = await form.trigger();

    if (isValid) {
      const data = form.getValues();
      startDownload(data.email);
      form.reset();
    }
  };

  const handleDirectDownload = async () => {
    setIsGenerationStarted(true);
    await startDownload()
    setIsGenerationStarted(false);
  };

  const handleCancel = () => {
    form.reset();
    onCancel();
  };

  const modalContent = useMemo(() => {
    if (isGenerationStarted || isDownloadInProgress) {
      return (
        <div>
          <Text>{t("reportDownloadInProgressDescription")}</Text>
          <div className="mt-4" style={{ textAlign: "center" }}>
            <Spin size="large" />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <Title level={5}>{t("emailPdfReport")}</Title>
          <Text style={{ display: "block" }}>
            {t("emailPdfReportDescription")}
          </Text>
          <Input
            className="mt-3"
            placeholder={t("form.placeholders.enterYourEmail")}
            name="email"
            control={form.control}
          />
          <PrimaryButton onClick={handleEmailDownload} className="mt-3 mb-3">
            {t("send")}
          </PrimaryButton>
          <Text
            style={{
              fontSize: "12px",
              color: token.colorTextSecondary,
              display: "block",
              lineHeight: "1.4",
            }}
          >
            {t("reportDownloadEmailDisclaimer")}
          </Text>
        </div>
        <Divider className="my-4">{t("or")}</Divider>
        <div style={{ textAlign: "center" }}>
          <Title level={5}>{t("downloadNow")}</Title>
          <Text style={{ display: "block" }}>
            {t("downloadNowDescription")}
          </Text>
          <PrimaryButton onClick={handleDirectDownload} className="mt-2 mb-3">
            {t("buttons.download")}
          </PrimaryButton>
          <Text
            style={{
              fontSize: "12px",
              color: token.colorTextSecondary,
              display: "block",
              lineHeight: "1.4",
            }}
          >
            {t("reportDirectDownloadDisclaimer")}
          </Text>
        </div>
      </div>
    );
  }, [isGenerationStarted, isDownloadInProgress]);

  return (
    <Modal
      open={open}
      title={t("getReportInPdfChoices")}
      footer={[]}
      onCancel={handleCancel}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      {modalContent}
    </Modal>
  );
};

DownloadSurveyModal.propTypes = {
  startDownload: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDownloadInProgress: PropTypes.bool.isRequired,
};

const DownloadStatuses = {
    START: "pdf_generation_started",
    IN_PROGRESS: "pdf_generation_in_progress",
    FINISHED: "pdf_generated"
}

const DownloadSurvey = ({ children, survey, filters }) => {
  const { notification } = App.useApp();
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
    const intervalRef = useRef(null);

    useEffect(() => {
        return cancelInterval;
    }, []);

    const cancelInterval = () => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }

  const handleDownloadSurvey = async (email = null) => {
      const response = await clientApi({
        url: `/surveys/${survey.id}/download/`,
        method: "POST",
        data: { email, ...filters },
      })
      if ([DownloadStatuses.START, DownloadStatuses.IN_PROGRESS].includes(response.data.code)) {
        if (email) {
             notification.success({
              message: t(response.data.code === DownloadStatuses.START ? "reportPdfStarted" : "reportPdfGenerating", { email }),
            });
        } else {
            if (intervalRef.current === null) {
                intervalRef.current = setInterval(() => getStatusOfPdfForDirectDownload(), 3000);
            }
            setIsDownloadInProgress(true);
        }
      } else if (response.data.code === DownloadStatuses.FINISHED) {
          if (email) {
              notification.success({
              message: t("pdfEmailSent"),
            });
          } else {
              downloadPdf(response.data.link);
          }
      }

      if (email || response.data.code === DownloadStatuses.FINISHED) {
        setOpenModal(false);
      }
    };

  const downloadPdf = (pdfLink) => {
       const link = document.createElement("a");
         link.href = pdfLink;
         link.target = "_blank";
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
    }

  const getStatusOfPdfForDirectDownload = () => {
       clientApi({
        url: `/surveys/${survey.id}/download/`,
        method: "POST",
        data: { email: null, ...filters },
      })
         .then((response) => {
             if (response.data.code === DownloadStatuses.FINISHED) {
                 downloadPdf(response.data.link);
                 cancelInterval();
                 setIsDownloadInProgress(false);
                 setOpenModal(false);
             }
         })

  }

  return (
    <div>
      <DownloadSurveyModal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        startDownload={handleDownloadSurvey}
        isDownloadInProgress={isDownloadInProgress}
      />
      <div onClick={() => setOpenModal(true)} style={{ cursor: "pointer" }}>
        {children}
      </div>
    </div>
  );
};

DownloadSurvey.propTypes = {
  children: PropTypes.object.isRequired,
  survey: PropTypes.object.isRequired,
  filters: PropTypes.object,
};

DownloadSurvey.defaultProps = {
  filters: {},
};

export default DownloadSurvey;
