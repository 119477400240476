import { useEffect } from "react";

import PropTypes from "prop-types";

import Table from "../../../../components/Table.jsx";
import { useLazyPaginateFilterSurveyViewsQuery } from "../../../../redux/service.js";

const SurveyViewsTable = ({ columns, rowSelection, filters }) => {
  const [paginateAndFilterSurveyViews, surveyViewsResponse] =
    useLazyPaginateFilterSurveyViewsQuery();

  useEffect(() => {
    handleTableChange({ current: 1, pageSize: 10 }, filters);
  }, [filters]);

  const handleTableChange = (pagination, tableFilters) => {
    paginateAndFilterSurveyViews({
      page: pagination.current,
      pageSize: pagination.pageSize,
      ...tableFilters,
      ...filters,
    });
  };

  return (
    <Table
      data={surveyViewsResponse.data?.results || []}
      totalCount={surveyViewsResponse.data?.count || 0}
      columns={columns}
      isLoading={
        surveyViewsResponse.isUninitialized || surveyViewsResponse.isFetching
      }
      rowSelection={rowSelection}
      handleTableChange={handleTableChange}
      rowKey={(record) => record.uuid}
    />
  );
};

SurveyViewsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rowSelection: PropTypes.object,
  filters: PropTypes.object,
};

SurveyViewsTable.defaultProps = {
  rowSelection: null,
  filters: {},
};

export default SurveyViewsTable;
