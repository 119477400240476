import { useState } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import FormField from "../../../components/form/FormField.jsx";
import Input from "../../../components/form/Input.jsx";
import NarrativesSelectModal from "../../narratives/components/NarrativesSelectModal.jsx";
import ObjectSelectModal from "../../objects/components/ObjectSelectModal.jsx";
import { useLazyGetUsersQuery } from "../../../redux/service.js";
import SurveySelectModal from "../../survey/components/survey-views/SurveySelectModal.jsx";
import { SharingRights } from "../../../utilities/constants.jsx";

const { Title, Text } = Typography;

const ContentField = ({ form, children, label, inputRows }) => {
  const { t } = useTranslation();

  const [getUsers] = useLazyGetUsersQuery();
  const [narrativesModalOpen, setNarrativesModalOpen] = useState(false);
  const [objectsModalOpen, setObjectsModalOpen] = useState(false);
  const [surveySelectModalOpen, setSurveySelectModalOpen] = useState(false);

  const attachedObjects = form.watch("objects") || [];
  const attachedNarratives = form.watch("narratives") || [];
  const attachedSurveyViews = form.watch("surveyViews") || [];

  const removeNarrative = (narrativeId) => {
    form.setValue(
      "narratives",
      attachedNarratives.filter((i) => i.id !== narrativeId),
    );
  };

  const removeObject = (objectId) => {
    form.setValue(
      "objects",
      attachedObjects.filter((i) => i.id !== objectId),
    );
  };

  const removeSurveyView = (surveyViewID) => {
    form.setValue(
      "surveyViews",
      attachedSurveyViews.filter((i) => i.uuid !== surveyViewID),
    );
  };

  return (
    <div>
      <FormField
        label={label}
        required
        field={
          <>
            <Input
              name="content"
              rows={inputRows}
              type="richtexteditor"
              modules={{
                toolbar: [
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image", "code-block"],
                  ["blockquote"],
                  ["clean"],
                ],
                mention: {
                  mentionDenotationChars: ["@", "#"],
                  source: async function (searchTerm, renderList) {
                    const response = await getUsers({
                      fullName: searchTerm,
                      pagination: searchTerm.length === 0 ? "on" : "off",
                    });

                    let values = [];
                    if (response.isSuccess) {
                      values = (
                        searchTerm.length === 0
                          ? response.data.results
                          : response.data
                      ).map((i) => ({ id: i.id, value: i.fullName }));
                    }

                    if (searchTerm.length === 0) {
                      renderList(values, searchTerm);
                    } else {
                      const matches = [];
                      for (let i = 0; i < values.length; i++)
                        if (
                          ~values[i].value
                            .toLowerCase()
                            .indexOf(searchTerm.toLowerCase())
                        )
                          matches.push(values[i]);
                      renderList(matches, searchTerm);
                    }
                  },
                },
              }}
              placeholder={t("form.placeholders.content")}
              control={form.control}
            />
            {attachedObjects.length ||
            attachedNarratives.length ||
            attachedSurveyViews.length ? (
              <div className="mt-3">
                <div className="mb-3">
                  {attachedObjects.map((obj) => (
                    <div
                      key={obj.id}
                      className="py-2 px-3 mb-2"
                      style={{
                        backgroundColor: "rgba(242, 242, 245, 1)",
                        display: "flex",
                      }}
                    >
                      <CloseOutlined onClick={() => removeObject(obj.id)} />
                      <Title
                        level={5}
                        className="ms-3"
                        style={{ color: "rgba(74, 105, 156, 1)" }}
                      >
                        {obj.title}
                      </Title>
                    </div>
                  ))}
                </div>
                <div>
                  {attachedNarratives.map((narrative) => (
                    <div
                      key={narrative.id}
                      className="py-2 px-3 mb-2"
                      style={{
                        backgroundColor: "rgba(230, 237, 254, 1)",
                        display: "flex",
                      }}
                    >
                      <CloseOutlined
                        onClick={() => removeNarrative(narrative.id)}
                      />
                      <Title
                        level={5}
                        className="ms-3"
                        style={{ color: "rgba(74, 105, 156, 1)" }}
                      >
                        {narrative.title}
                      </Title>
                    </div>
                  ))}
                </div>
                <div>
                  {attachedSurveyViews.map((surveyView) => (
                    <div
                      key={surveyView.uuid}
                      className="py-2 px-3 mb-2"
                      style={{
                        backgroundColor: "rgba(230, 237, 254, 1)",
                        display: "flex",
                      }}
                    >
                      <CloseOutlined
                        onClick={() => removeSurveyView(surveyView.uuid)}
                      />
                      <Title
                        level={5}
                        className="ms-3"
                        style={{ color: "rgba(74, 105, 156, 1)" }}
                      >
                        {surveyView.title}
                      </Title>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            <Row className="mt-3">
              <Col span={12}>
                <div style={{ display: "flex" }} className="mt-2 mb-3">
                  <Text
                    style={{
                      color: "rgba(74, 105, 156, 1)",
                      cursor: "pointer",
                    }}
                    onClick={() => setNarrativesModalOpen(true)}
                  >
                    + {t("addNarratives")}
                  </Text>
                  <Text
                    style={{
                      color: "rgba(74, 105, 156, 1)",
                      cursor: "pointer",
                    }}
                    className="ms-3"
                    onClick={() => setObjectsModalOpen(true)}
                  >
                    + {t("addObjects")}
                  </Text>
                  <Text
                    style={{
                      color: "rgba(74, 105, 156, 1)",
                      cursor: "pointer",
                    }}
                    className="ms-3"
                    onClick={() => setSurveySelectModalOpen(true)}
                  >
                    + {t("addReportView")}
                  </Text>
                </div>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                {children}
              </Col>
            </Row>
          </>
        }
      />
      <NarrativesSelectModal
        onCancel={() => setNarrativesModalOpen(false)}
        open={narrativesModalOpen}
        multiple
        initialSelectedNarratives={attachedNarratives}
        onSelect={(narratives) => {
          form.setValue("narratives", narratives);
          setNarrativesModalOpen(false);
        }}
      />
      <ObjectSelectModal
        onCancel={() => setObjectsModalOpen(false)}
        open={objectsModalOpen}
        multiple
        initialSelectedObjects={attachedObjects}
        onSelect={(objects) => {
          form.setValue("objects", objects);
          setObjectsModalOpen(false);
        }}
      />
      <SurveySelectModal
        onCancel={() => setSurveySelectModalOpen(false)}
        open={surveySelectModalOpen}
        initialSelectedSurveys={attachedSurveyViews}
        multiple
        onSelect={(surveyViews) => {
          form.setValue("surveyViews", surveyViews);
          setSurveySelectModalOpen(false);
        }}
        filters={{
          sharing_rights: [
            SharingRights.PUBLIC.value,
            SharingRights.PRME_COMMUNITY.value,
          ],
        }}
      />
    </div>
  );
};

ContentField.propTypes = {
  form: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  label: PropTypes.string,
  inputRows: PropTypes.number,
};

ContentField.defaultProps = {
  label: null,
  inputRows: null,
};

export default ContentField;
